import { FunctionComponent } from 'react';
import BackButton from '../components/BackButton';
import { useHistory } from 'react-router-dom';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/generalExpense.css';

const GeneralExpense: FunctionComponent = () => {
  const router = useHistory();
  const buttons: Array<{ name: string; href: string; icon: string }> = [
    {
      name: 'Traslado',
      href: '',
      icon: ''
    },
    {
      name: 'Traslado internacionales',
      href: '',
      icon: ''
    },
    {
      name: 'Computadoras',
      href: '',
      icon: ''
    },
    {
      name: 'Telefonía',
      href: '/reporteria/gastos-generales/gastos-telefonia',
      icon: ''
    },
    {
      name: 'Rendicion de gastos',
      href: '/reporteria/gastos-generales/rendiciones-gastos',
      icon: ''
    }
  ];
  return (
    <div className='general-expense-page-container'>
      <BackButton route='/reporteria' />
      <div className='general-expense-page-body'>
        <h2 className='general-expense-page-title'>Gastos Generales</h2>
        <div className='general-expense-page-buttons-container'>
          {buttons.map((btn, idx) => (
            <div
              className='general-expense-page-button'
              key={idx}
              onClick={() => router.push(btn.href)}>
              <GenericIcons icon={btn.icon} /> <p>{btn.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GeneralExpense;
