import { ExpenseReport } from '../types';
import { ExtendedColumnDef, WebEntity } from './types';

const ExpenseReportEntity: WebEntity<ExpenseReport> = {
  name: 'expenseReport',
  endpoint: 'expenses/rendiciones',
  referenceColumn: 'category',
  tableColumns: []
};

export const expenseReportEntityColumns: ExtendedColumnDef<ExpenseReport>[] = [
  {
    header: 'Categoría',
    accessorKey: 'categoria',
    columnType: 'text',
    size: 250
  },
  {
    header: 'Zona',
    accessorKey: 'zona',
    columnType: 'text',
    size: 200
  },
  {
    header: 'Centro de beneficio',
    accessorKey: 'centroCoste',
    columnType: 'text',
    size: 230
  },
  {
    header: 'Cuenta de mayor',
    accessorKey: 'cuenta',
    columnType: 'text',
    size: 230
  },
  {
    header: 'Descripcion Cuenta',
    accessorKey: 'descripcionCuenta',
    columnType: 'text',
    size: 300
  },
  {
    header: 'Proveedor',
    accessorKey: 'proveedor',
    columnType: 'text',
    size: 200
  },
  {
    header: 'N° documento',
    accessorKey: 'numeroDocumento',
    columnType: 'text',
    size: 200
  }
];

export default ExpenseReportEntity;
