import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import BackButton from '../components/BackButton';
import { ExpenseReportCRUD } from '../components/GeneratedComponents';
import { FormControlLabel, Stack, styled, Switch, Tooltip } from '@mui/material';
import { getUncatchEndpointData } from '../utils/request';
import { ExpenseReport as ExpenseReportType, SimpleObject } from '../types';
import { ConnectedComponent } from 'react-redux';
import { ExtendedColumnDef, WebEntity } from '../entities/types';
import ExpenseReportEntity, { expenseReportEntityColumns } from '../entities/ExpenseReportEntity';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { ConfigProvider, DatePicker } from 'antd';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import locale from 'antd/locale/es_ES';
import dayjs, { Dayjs } from 'dayjs';
import '../css/expenseReport.css';
import 'moment/locale/es';
import 'dayjs/locale/es';

dayjs.locale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthsShort: [
    'Ene.',
    'Feb.',
    'Mar.',
    'Abr.',
    'May.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dic.'
  ]
});

const MaterialUISwitch = styled(Switch)(() => ({
  width: 70,
  height: 36,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        color: '#fff',
        fontWeight: 900,
        content: "'CC'",
        width: '100%',
        height: '100%',
        left: '70%',
        top: '70%',
        transform: 'translate(-50%,-50%)'
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#64a70b',
    width: 34,
    height: 34,
    '&::before': {
      color: '#fff',
      fontWeight: 900,
      content: "'Zona'",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: '55%',
      top: '70%',
      transform: 'translate(-50%,-50%)'
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2
  }
}));

export const ExpenseReport: FunctionComponent = () => {
  const [selectedMonths, setSelectedMonths] = useState<Array<Dayjs> | null>([dayjs(new Date())]);
  const [zonaIsDependent, setZonaIsDependent] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [ccList, setCcList] = useState<SelectProps['options']>([]);
  const [zonaList, setZonaList] = useState<SelectProps['options']>([]);
  const [categoriaList, setCategoriaList] = useState<SelectProps['options']>([]);
  const [selectedCC, setSelectedCC] = useState<string[]>([]);
  const [selectedZona, setSelectedZona] = useState<string[]>([]);
  const [selectedCategoria, setSelectedCategoria] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'expenses/rendiciones/categoria'
      });
      if (res && res.length > 0) {
        const data: SelectProps['options'] = res.map((row: SimpleObject, idx: number) => ({
          key: `categoria_${idx}`,
          value: row.categoriesId,
          label: row.categoriesName
        }));
        setCategoriaList(data);
      }
    })();

    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchZonas = async () => {
      setLoading(true);
      if (zonaIsDependent) {
        setZonaList([]);
        const res = await getUncatchEndpointData({ endpoint: 'zone/zoneByCcAndUserType' });

        if (res && res.length > 0) {
          const data: SelectProps['options'] = res.map((row: SimpleObject, idx: number) => ({
            value: row.zoneId,
            label: row.zoneDescrp
          }));
          setZonaList(data);
        }
      } else if (selectedCC && selectedCC.length > 0) {
        setZonaList([]);
        const res = await getUncatchEndpointData({
          endpoint: 'zone/zoneByCcAndUserType',
          query: { selectedCC }
        });

        if (res && res.length > 0) {
          const data: SelectProps['options'] = res.map((row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            label: row.zoneDescrp
          }));
          setZonaList(data);
        }
      } else {
        setZonaList([]);
        setSelectedZona([]);
      }
      setLoading(false);
    };

    fetchZonas();
  }, [zonaIsDependent, selectedCC]);

  useEffect(() => {
    const fetchCC = async () => {
      setLoading(true);
      if (!zonaIsDependent) {
        setCcList([]);
        const res = await getUncatchEndpointData({ endpoint: 'costCenter/byZoneAndUserType' });

        if (res && res.length > 0) {
          const data: SelectProps['options'] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            label: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList(data);
        }
      } else if (selectedZona && selectedZona.length > 0) {
        setCcList([]);
        const res = await getUncatchEndpointData({
          endpoint: 'costCenter/byZoneAndUserType',
          query: { selectedZona }
        });

        if (res && res.length > 0) {
          const data: SelectProps['options'] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            label: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList(data);
        }
      } else {
        setCcList([]);
        setSelectedCC([]);
      }
      setLoading(false);
    };

    fetchCC();
  }, [zonaIsDependent, selectedZona]);

  // Clean selected values when dependency changes
  useEffect(() => {
    setSelectedCC([]);
    setSelectedZona([]);
    setCcList([]);
    setZonaList([]);
  }, [zonaIsDependent]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    const columns = expenseReportEntityColumns;
    const aggrgatedColumns: ExtendedColumnDef<ExpenseReportType>[] = !selectedMonths
      ? []
      : selectedMonths.map((m) => {
          const header = `${spanishMonthNames[m.month()]} ${m.year()}`;
          const accessorKey = `total_${m.month() + 1}_${m.year()}`;
          return {
            header,
            accessorKey,
            columnType: 'numeric',
            Cell: ({ row }) => (
              <div
                style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '10px' }}>
                {row.original[accessorKey] ? formatStringNumber(row.original[accessorKey]) : 0}
              </div>
            ),
            aggregationFn: 'sum',
            AggregatedCell: ({ cell }) => {
              const sum = cell.getValue();
              return (
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Stack sx={{ fontWeight: 'bold' }}>
                    {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
                  </Stack>
                </div>
              );
            },
            Footer: ({ table }: { table: any }) => {
              const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
              const total = rows.reduce(
                (acc: number, obj: ExpenseReportType) =>
                  obj[accessorKey] ? acc + Number(obj[accessorKey]) : acc,
                0
              );
              return (
                <div
                  style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
                  <Stack sx={{ fontSize: 15, color: 'green' }}>
                    {formatStringNumber(total.toString(), 0, 0)}
                  </Stack>
                </div>
              );
            }
          };
        });
    const tableColumns: ExtendedColumnDef<ExpenseReportType>[] = [
      ...columns,
      ...aggrgatedColumns,
      {
        header: 'Total General',
        accessorKey: 'total',
        columnType: 'numeric',
        accessorFn: (row) => row.total,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '10px' }}>
            {row.original.total ? formatStringNumber(row.original.total) : 0}
          </div>
        ),
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => {
          const sum = cell.getValue();
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Stack sx={{ fontWeight: 'bold' }}>
                {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
              </Stack>
            </div>
          );
        },
        Footer: ({ table }: { table: any }) => {
          const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
          const total = rows.reduce(
            (acc: number, obj: ExpenseReportType) => (obj.total ? acc + Number(obj.total) : acc),
            0
          );
          return (
            <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
              <Stack sx={{ fontSize: 15, color: 'green' }}>
                {formatStringNumber(total.toString(), 0, 0)}
              </Stack>
            </div>
          );
        }
      }
    ];

    const entity: WebEntity<ExpenseReportType> = { ...ExpenseReportEntity, tableColumns };

    const cc = selectedCC;
    const zones = selectedZona;
    const categories = selectedCategoria;
    const filterDates = selectedMonths?.map((m) => m.format('DD-MM-YYYY'));

    return ExpenseReportCRUD(entity, {
      cc,
      zones,
      categories,
      filterDates
    });
  }, [selectedCC, selectedZona, selectedCategoria, selectedMonths]);

  return (
    <div className='expense-report-page-container'>
      <BackButton route='/reporteria/gastos-generales' />

      <div className='expense-report-page-filters-container'>
        <Select
          size='large'
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Zona'
          defaultValue={selectedZona}
          onChange={(values) => setSelectedZona(values)}
          options={zonaList}
          loading={loading}
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
        />

        <Tooltip title='¿Que filtro es dependiente del otro?' followCursor>
          <FormControlLabel
            control={
              <MaterialUISwitch
                checked={zonaIsDependent}
                value={zonaIsDependent}
                onChange={(_, checked) => setZonaIsDependent(checked)}
              />
            }
            label='¿Dependiente?'
            labelPlacement='top'
            slotProps={{
              typography: { textAlign: 'center', fontWeight: 900, marginTop: -2 }
            }}
          />
        </Tooltip>

        <Select
          size='large'
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='CC'
          defaultValue={selectedCC}
          onChange={(values) => setSelectedCC(values)}
          options={ccList}
          loading={loading}
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
        />

        <Select
          size='large'
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Categoria'
          defaultValue={selectedCategoria}
          onChange={(values) => setSelectedCategoria(values)}
          options={categoriaList}
          loading={loading}
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
        />

        <ConfigProvider locale={locale}>
          <DatePicker
            size='large'
            multiple
            onChange={(date) => setSelectedMonths(date)}
            picker='month'
            maxTagCount='responsive'
            value={selectedMonths}
            format={{
              format: 'MMMM YYYY',
              type: 'mask'
            }}
          />
        </ConfigProvider>
      </div>
      <CRUD />
    </div>
  );
};
