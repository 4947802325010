import { PhoneAccounts } from '../types';
import { FieldTypes, WebEntity } from './types';

const PhoneAccountEntity: WebEntity<PhoneAccounts> = {
  name: 'phoneAccounts',
  endpoint: 'phoneAccounts',
  referenceColumn: 'phoneAccountId',
  customButtonNames: {
    addButtonName: 'Agregar Cuenta'
  },
  customDataActions: {
    editModalTitle: 'Editar Cuenta'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: false
    },
    {
      header: 'Nombre Cuenta',
      accessorKey: 'phoneAccountName',
      columnType: 'text'
    }
  ],
  fields: [
    {
      name: 'Nombre Cuenta',
      selector: 'phoneAccountName',
      type: FieldTypes.Text,
      required: true
    }
  ],
  editableFields: [
    {
      name: 'Nombre Cuenta',
      selector: 'phoneAccountName',
      type: FieldTypes.Text
    }
  ]
};

export default PhoneAccountEntity;
