import {
  FunctionComponent,
  ReactNode,
  useState,
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo
} from 'react';
import { connect } from 'react-redux';
import 'dayjs/locale/es';
import {
  confirmEstimatedClosing,
  confirmEstimatedClosingPending,
  confirmEstimatedClosingRejected,
  confirmProvision,
  approveOrRejectProvision,
  confirmRefusedProvision,
  confirmReclassification,
  approveOrRejectReclassification,
  confirmRefusedReclassification,
  multipleApprovalClosingEstimate,
  dateAndGlobalZoneFilterEntity,
  refreshHfm,
  deleteProvision,
  deleteReclassification,
  dateAndSelectFilterEntity,
  multipleApprovalClosingEstimatePending,
  ifacFilter,
  refreshVolumen,
  simulateIFAC,
  refreshPolynomial,
  paymentAuthorization,
  resumeShipmentCarriageDateFilter
} from '../actions/middleActions';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { MRT_RowData } from 'material-react-table';
import { toast } from 'react-toastify';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateView } from '@mui/x-date-pickers';
import { WebEntity } from '../entities/types';
import { errorPopAlert } from './PopAlert';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ButtonPropsVariantOverrides,
  ButtonPropsColorOverrides,
  Modal,
  Box,
  Typography,
  Chip,
  Autocomplete,
  TextField,
  Alert
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { AuthState, EntitiesState } from '../reducers/types';
import {
  SimpleObject,
  StatusBudgetNames,
  UserTypeValues,
  GlobalZoneValues,
  IfacType,
  EstimatedClosing,
  PaymentType
} from '../types';
import 'moment/locale/es';
import { AppState } from '../store';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import { getUncatchEndpointData } from '../utils/request';
import { dateFormatFn } from '../utils/utils';
import ModalComponent from './ModalComponent';
import { FileUploader } from 'react-drag-drop-files';

moment.updateLocale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthsShort: [
    'Ene.',
    'Feb.',
    'Mar.',
    'Abr.',
    'May.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dic.'
  ]
});

type ButtonParams = {
  action: (params?: any, webEntity?: WebEntity<SimpleObject>) => Promise<any>;
};

type Props = {
  action: (params?: any) => Promise<any>;
  onClick: (buttonParams: ButtonParams) => Promise<void>;
  children: ReactNode;
  disabled?: boolean;
  style?: CSSProperties;
  buttonVariant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  buttonColor?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
};

const SecondaryButton: FunctionComponent<Props> = ({
  action,
  onClick,
  children,
  disabled = false,
  style = {},
  buttonVariant,
  buttonColor
}) => {
  const handleOnClick = async () => {
    await onClick({ action });
  };

  return (
    <Button
      onClick={handleOnClick}
      disabled={disabled}
      style={style}
      variant={buttonVariant}
      color={buttonColor}>
      {children}
    </Button>
  );
};

const connectedSecondaryButton = (action: any) => connect(null, { action })(SecondaryButton);

export const DateFilterWithAction: FunctionComponent<{
  dateFiltered: moment.Moment;
  setDateFiltered: Dispatch<SetStateAction<moment.Moment>>;
  action: any;
  additionalParams?: SimpleObject;
}> = ({ dateFiltered, setDateFiltered, action, additionalParams }) => {
  const ConnectedBtn = connectedSecondaryButton(action);
  const [isFiltered, setIsFiltered] = useState<SimpleObject | null>(null);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dateFiltered.format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
      setSelectedDate(null);
      setIsFiltered(null);
    } else {
      setSelectedDate(dateFiltered);
      setIsFiltered({ filtered: { date: dateFiltered.format('YYYY-MM-DD') } });
    }
  }, [dateFiltered]);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ date, ...additionalParams });
        setLoading(false);
        setIsFiltered(res);
        if (selectedDate) {
          setDateFiltered(selectedDate);
        }
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Filtrado! ${date ? `Fecha: ${date}` : ''} `,
        error: 'Ocurrió un error al realizar el filtro'
      }
    );
  };

  const onClickRemoveFilters = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    toast.promise(
      async () => {
        const currentDate = moment(new Date());
        setLoading(true);
        const res = await action({ date: currentDate.format('YYYY-MM-DD'), ...additionalParams });
        setLoading(false);
        setIsFiltered(null);
        if (selectedDate) {
          setDateFiltered(currentDate);
        }
        setSelectedDate(null);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Se quitó el filtro!`,
        error: 'Ocurrió un error al quitar el filtro'
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
      {isFiltered && (
        <ConnectedBtn
          onClick={onClickRemoveFilters}
          disabled={!isFiltered || loading}
          style={{ padding: '10px' }}>
          <FilterAltOffIcon color='action' fontSize='large' />
        </ConnectedBtn>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
        <DatePicker
          views={['year', 'month']}
          openTo='year'
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
        />
      </LocalizationProvider>
      <ConnectedBtn
        onClick={onClick}
        disabled={!selectedDate || loading}
        buttonVariant='contained'
        buttonColor='success'
        style={{ padding: '10px' }}>
        <SearchIcon fontSize='large' />
      </ConnectedBtn>
    </div>
  );
};

const DateAndGlobalZoneFilters: FunctionComponent<{
  auth: AuthState;
  webEntity: WebEntity<any>;
  viewsDate: DateView[];
  omitZoneFilter?: boolean;
  additionalParams?: SimpleObject;
  dateFiltered?: moment.Moment;
  setDateFiltered?: Dispatch<SetStateAction<moment.Moment>>;
}> = ({
  auth,
  webEntity,
  viewsDate,
  omitZoneFilter,
  additionalParams,
  dateFiltered,
  setDateFiltered
}) => {
  const ConnectedBtn = connectedSecondaryButton(dateAndGlobalZoneFilterEntity);
  const [isFiltered, setIsFiltered] = useState<SimpleObject | null>(null);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [selectedZone, setSelectedZone] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dateFiltered) {
      if (dateFiltered.format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
        setSelectedDate(null);
        setIsFiltered(null);
      } else {
        setSelectedDate(dateFiltered);
        setIsFiltered({ filtered: { date: dateFiltered.format('YYYY-MM-DD') } });
      }
    }
  }, [dateFiltered]);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;

    const dateFormattedMsj = selectedDate
      ? viewsDate.includes('month')
        ? selectedDate.format('MM-YYYY')
        : selectedDate.format('YYYY')
      : '';

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ date, zoneGlobal: selectedZone, additionalParams }, webEntity);
        setLoading(false);
        setIsFiltered(res);
        if (setDateFiltered && selectedDate) {
          setDateFiltered(selectedDate);
        }
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Filtrado! ${dateFormattedMsj ? `Fecha: ${dateFormattedMsj}` : ''} ${
          dateFormattedMsj && selectedZone ? ', ' : ''
        } ${selectedZone ? `Zona: ${selectedZone}` : ''}`,
        error: 'Ocurrió un error al realizar el filtro'
      }
    );
  };

  const onClickRemoveFilters = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    toast.promise(
      async () => {
        const currentDate = moment(new Date());
        setLoading(true);
        const res = await action(
          { date: currentDate.format('YYYY-MM-DD'), additionalParams },
          webEntity
        );
        setLoading(false);
        setIsFiltered(null);
        if (setDateFiltered && selectedDate) {
          setDateFiltered(currentDate);
        }
        setSelectedDate(null);
        setSelectedZone(undefined);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Se quitó el filtro!`,
        error: 'Ocurrió un error al quitar el filtro'
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
      {isFiltered && (
        <ConnectedBtn
          onClick={onClickRemoveFilters}
          disabled={!isFiltered || loading}
          style={{ padding: '10px' }}>
          <FilterAltOffIcon color='action' fontSize='large' />
        </ConnectedBtn>
      )}

      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
        <DatePicker
          views={viewsDate}
          openTo='year'
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
        />
      </LocalizationProvider>

      {!omitZoneFilter && auth.user?.userTypeName.includes(UserTypeValues.admin) && (
        <div style={{ display: 'flex', gap: 10 }}>
          <FormControl sx={{ width: 200 }}>
            <InputLabel id='demo-simple-select-label'>Zona Global</InputLabel>
            <Select
              value={selectedZone}
              onChange={(e: SelectChangeEvent) => setSelectedZone(e.target.value)}
              label={'Zona Global'}
              labelId='demo-simple-select-label'
              id='demo-simple-select'>
              <MenuItem value={GlobalZoneValues.all}>Todos</MenuItem>
              <MenuItem value={GlobalZoneValues.NorteGrande}>Norte Grande</MenuItem>
              <MenuItem value={GlobalZoneValues.NorteChico}>Norte Chico</MenuItem>
              <MenuItem value={GlobalZoneValues.Centro}>Centro</MenuItem>
              <MenuItem value={GlobalZoneValues.CentroSur}>Centro Sur</MenuItem>
              <MenuItem value={GlobalZoneValues.Sur}>Sur</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}

      <ConnectedBtn
        onClick={onClick}
        disabled={(!selectedDate && !selectedZone) || loading}
        buttonVariant='contained'
        buttonColor='success'
        style={{ padding: '10px' }}>
        <SearchIcon fontSize='large' />
      </ConnectedBtn>
    </div>
  );
};

export const DateAndGlobalZoneFiltersConnected = connect(({ auth }: AppState) => ({ auth }))(
  DateAndGlobalZoneFilters
);

export const DateAndSelectFilter: FunctionComponent<{
  webEntity: WebEntity<any>;
  viewsDate: DateView[];
  dateFiltered?: moment.Moment;
  setDateFiltered?: Dispatch<SetStateAction<moment.Moment>>;
  selectLabel: string;
  selectList: Array<{
    id: string;
    name: string;
  }>;
  setSelectFiltered?: Dispatch<SetStateAction<string>>;
}> = ({
  webEntity,
  viewsDate,
  dateFiltered,
  setDateFiltered,
  selectLabel,
  selectList,
  setSelectFiltered
}) => {
  const ConnectedBtn = connectedSecondaryButton(dateAndSelectFilterEntity);
  const [isFiltered, setIsFiltered] = useState<SimpleObject | null>(null);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [selected, setSelected] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dateFiltered) {
      if (dateFiltered.format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
        setSelectedDate(null);
        setIsFiltered(null);
      } else {
        setSelectedDate(dateFiltered);
        setIsFiltered({ filtered: { date: dateFiltered.format('YYYY-MM-DD') } });
      }
    }
  }, [dateFiltered]);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;
    const dateFormattedMsj = selectedDate
      ? viewsDate.includes('month')
        ? selectedDate.format('MM-YYYY')
        : selectedDate.format('YYYY')
      : '';

    const selectName = selected ? selectList.find((obj) => obj.id === selected)?.name : null;

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ date, selected }, webEntity);
        setLoading(false);
        setIsFiltered(res);
        if (setDateFiltered && selectedDate) {
          setDateFiltered(selectedDate);
        }
        if (setSelectFiltered && selected) {
          setSelectFiltered(selected);
        }
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Filtrado! ${dateFormattedMsj ? `Fecha: ${dateFormattedMsj}` : ''} ${
          dateFormattedMsj && selectName ? ', ' : ''
        } ${selectName ? `Seleccionado: ${selectName}` : ''}`,
        error: 'Ocurrió un error al realizar el filtro'
      }
    );
  };

  const onClickRemoveFilters = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    toast.promise(
      async () => {
        const currentDate = moment(new Date());
        setLoading(true);
        const res = await action({ date: currentDate.format('YYYY-MM-DD') }, webEntity);
        setLoading(false);
        if (setDateFiltered && selectedDate) {
          setDateFiltered(currentDate);
        }
        if (setSelectFiltered && selected) {
          setSelectFiltered('');
        }
        setIsFiltered(null);
        setSelectedDate(null);
        setSelected('');
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Se quitó el filtro!`,
        error: 'Ocurrió un error al quitar el filtro'
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
      {isFiltered && (
        <ConnectedBtn
          onClick={onClickRemoveFilters}
          disabled={!isFiltered || loading}
          style={{ padding: '10px' }}>
          <FilterAltOffIcon color='action' fontSize='large' />
        </ConnectedBtn>
      )}

      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
        <DatePicker
          views={viewsDate}
          openTo='year'
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
        />
      </LocalizationProvider>

      <div style={{ display: 'flex', gap: 10 }}>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id='select-label'>{selectLabel}</InputLabel>
          <Select
            value={selected}
            onChange={(e: SelectChangeEvent) => setSelected(e.target.value)}
            label={selectLabel}
            labelId='select-label'
            id='select'>
            {selectList.map((row, idx) => (
              <MenuItem value={row.id || ''} key={idx}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <ConnectedBtn
        onClick={onClick}
        disabled={(!selectedDate && !selected) || loading}
        buttonVariant='contained'
        buttonColor='success'
        style={{ padding: '10px' }}>
        <SearchIcon fontSize='large' />
      </ConnectedBtn>
    </div>
  );
};

export const ResumeShipmentCarriageFilter: FunctionComponent<{
  dateFiltered: moment.Moment;
  setDateFiltered: Dispatch<SetStateAction<moment.Moment>>;
  paymentTypeFiltered: PaymentType;
  setPaymentTypeFiltered: Dispatch<SetStateAction<PaymentType>>;
}> = ({ dateFiltered, setDateFiltered, paymentTypeFiltered, setPaymentTypeFiltered }) => {
  const ConnectedBtn = connectedSecondaryButton(resumeShipmentCarriageDateFilter);
  const [isFiltered, setIsFiltered] = useState<SimpleObject | null>(null);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>(PaymentType.all);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDate(dateFiltered);
    setIsFiltered({
      filtered: { date: dateFiltered.format('YYYY-MM-DD'), paymentType: paymentTypeFiltered }
    });
    setSelectedPaymentType(paymentTypeFiltered);
  }, [dateFiltered, paymentTypeFiltered]);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;
    const dateFormattedMsj = selectedDate ? selectedDate.format('MM-YYYY') : '';

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ date, paymentType: selectedPaymentType });
        setLoading(false);
        setIsFiltered(res);
        if (selectedDate) {
          setDateFiltered(selectedDate);
        }
        setPaymentTypeFiltered(selectedPaymentType);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Filtrado! ${dateFormattedMsj ? `Fecha: ${dateFormattedMsj}` : ''}`,
        error: 'Ocurrió un error al realizar el filtro'
      }
    );
  };

  const onClickRemoveFilters = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    toast.promise(
      async () => {
        const currentDate = moment(new Date());
        setLoading(true);
        const res = await action({
          date: currentDate.format('YYYY-MM-DD'),
          paymentType: PaymentType.all
        });
        setLoading(false);
        if (selectedDate) {
          setDateFiltered(currentDate);
        }

        setIsFiltered(null);
        setSelectedDate(null);
        setSelectedPaymentType(PaymentType.all);
        setPaymentTypeFiltered(PaymentType.all);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Se quitó el filtro!`,
        error: 'Ocurrió un error al quitar el filtro'
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
      {isFiltered && (
        <ConnectedBtn
          onClick={onClickRemoveFilters}
          disabled={!isFiltered || loading}
          style={{ padding: '10px' }}>
          <FilterAltOffIcon color='action' fontSize='large' />
        </ConnectedBtn>
      )}

      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
        <DatePicker
          views={['year', 'month']}
          openTo='year'
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
        />
      </LocalizationProvider>

      <div style={{ display: 'flex', gap: 10 }}>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id='select-label'>Tarifa</InputLabel>
          {
            <Select
              value={selectedPaymentType}
              onChange={(e: SelectChangeEvent) =>
                setSelectedPaymentType(e.target.value as PaymentType)
              }
              label='Tarifa'
              labelId='select-label'
              id='select'>
              <MenuItem value={PaymentType.all}>{PaymentType.all}</MenuItem>
              <MenuItem value={PaymentType.onlyZero}>{PaymentType.onlyZero}</MenuItem>
              <MenuItem value={PaymentType.excludeZero}>{PaymentType.excludeZero}</MenuItem>
            </Select>
          }
        </FormControl>
      </div>

      <ConnectedBtn
        onClick={onClick}
        disabled={!selectedDate || loading}
        buttonVariant='contained'
        buttonColor='success'
        style={{ padding: '10px' }}>
        <SearchIcon fontSize='large' />
      </ConnectedBtn>
    </div>
  );
};

const IfacFilters: FunctionComponent<{ entities: EntitiesState; ifacType: IfacType }> = ({
  entities,
  ifacType
}) => {
  const ConnectedBtn = connectedSecondaryButton(ifacFilter);
  const [isFiltered, setIsFiltered] = useState<SimpleObject | null>(null);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<Array<string>>([]);
  const [companyList, setCompanyList] = useState<Array<string>>([]);
  const [selectedRoute, setSelectedRoute] = useState<Array<string>>([]);
  const [routeList, setRouteList] = useState<Array<string>>([]);
  const [selectedItemGroup, setSelectedItemGroup] = useState<Array<string>>([]);
  const [itemGroupList, setItemGroupList] = useState<Array<string>>([]);
  const [selectedCarrierDescription, setSelectedCarrierDescription] = useState<Array<string>>([]);
  const [carrierDescriptionList, setCarrierDescriptionList] = useState<Array<string>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'ifac/distinctCompanyName'
      });
      if (res && res.length > 0) {
        const data = res.map((row: SimpleObject) => row.companyName);
        setCompanyList(data);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'ifac/distinctRoute'
      });
      if (res && res.length > 0) {
        const data = res.map((row: SimpleObject) => row.route);
        setRouteList(data);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'ifac/distinctItemGroup'
      });
      if (res && res.length > 0) {
        const data = res.map((row: SimpleObject) => row.itemGroup);
        setItemGroupList(data);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'ifac/distinctCarrierDescription'
      });
      if (res && res.length > 0) {
        const data = res.map((row: SimpleObject) => row.carrierDescription);
        setCarrierDescriptionList(data);
      }
    })();
  }, [entities.ifac.list]);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;
        setLoading(true);
        const res = await action({
          ifacType,
          companyName: selectedCompany,
          date,
          route: selectedRoute,
          itemGroup: selectedItemGroup,
          carrierDescription: selectedCarrierDescription
        });
        setLoading(false);
        setIsFiltered(res);
        handleCloseModal();
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Filtrado!`,
        error: 'Ocurrió un error al realizar el filtro'
      }
    );
  };

  const onClickRemoveFilters = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ ifacType });
        setLoading(false);
        setIsFiltered(null);
        setSelectedDate(null);
        setSelectedCompany([]);
        setSelectedRoute([]);
        setSelectedItemGroup([]);
        setSelectedCarrierDescription([]);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Se quitó el filtro!`,
        error: 'Ocurrió un error al quitar el filtro'
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      {isFiltered && (
        <ConnectedBtn
          onClick={onClickRemoveFilters}
          disabled={!isFiltered || loading}
          style={{ padding: '10px' }}>
          <FilterAltOffIcon color='action' fontSize='large' />
        </ConnectedBtn>
      )}

      <Button
        onClick={handleOpenModal}
        variant='contained'
        color='success'
        style={{ padding: '10px' }}>
        <FilterAltIcon />
        Filtros
      </Button>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            width: 'auto',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto'
          }}>
          <div
            style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
            onClick={handleCloseModal}>
            <CloseIcon />
          </div>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Filtros
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-company'>Descripcion Sociedad</InputLabel>
                <Select
                  value={selectedCompany}
                  multiple={true}
                  onChange={(e) => setSelectedCompany(e.target.value as string[])}
                  label='Descripcion Sociedad'
                  labelId='select-label-company'
                  id='select-company'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} style={{ margin: 2 }} />
                      ))}
                    </div>
                  )}>
                  {companyList.map((value, idx) => (
                    <MenuItem value={value || ''} key={idx}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ display: 'flex', gap: 10 }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-route'>Descripcion Ruta</InputLabel>
                <Select
                  value={selectedRoute}
                  multiple={true}
                  onChange={(e) => setSelectedRoute(e.target.value as string[])}
                  label='Descripcion Ruta'
                  labelId='select-label-route'
                  id='select-route'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} style={{ margin: 2 }} />
                      ))}
                    </div>
                  )}>
                  {routeList.map((value, idx) => (
                    <MenuItem value={value || ''} key={idx}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ display: 'flex', gap: 10 }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-item-group'>Grupo Articulos</InputLabel>
                <Select
                  value={selectedItemGroup}
                  multiple={true}
                  onChange={(e) => setSelectedItemGroup(e.target.value as string[])}
                  label='Grupo Articulos'
                  labelId='select-label-item-group'
                  id='select-item-group'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} style={{ margin: 2 }} />
                      ))}
                    </div>
                  )}>
                  {itemGroupList.map((value, idx) => (
                    <MenuItem value={value || ''} key={idx}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ display: 'flex', gap: 10 }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-carrier-description'>
                  Decripcion Transportista
                </InputLabel>
                <Select
                  value={selectedCarrierDescription}
                  multiple={true}
                  onChange={(e) => setSelectedCarrierDescription(e.target.value as string[])}
                  label='Decripcion Transportista'
                  labelId='select-label-carrier-description'
                  id='select-carrier-description'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} style={{ margin: 2 }} />
                      ))}
                    </div>
                  )}>
                  {carrierDescriptionList.map((value, idx) => (
                    <MenuItem value={value || ''} key={idx}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                views={['year', 'month']}
                openTo='year'
                value={selectedDate}
                label='Fecha Doc. Material Recepción'
                onChange={(date) => setSelectedDate(date)}
              />
            </LocalizationProvider>

            <ConnectedBtn
              onClick={onClick}
              buttonVariant='contained'
              buttonColor='success'
              disabled={
                (!selectedCompany &&
                  !selectedRoute &&
                  !selectedItemGroup &&
                  !selectedCarrierDescription &&
                  !selectedDate) ||
                loading
              }
              style={{ padding: '10px' }}>
              <SearchIcon fontSize='large' /> Buscar
            </ConnectedBtn>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export const IfacFiltersConnected = connect(({ entities }: AppState) => ({ entities }))(
  IfacFilters
);

export const SimulateIFAC: FunctionComponent<{
  dateFiltered: moment.Moment;
  setDateFiltered: Dispatch<SetStateAction<moment.Moment>>;
  existData: boolean;
  minDate: moment.Moment;
  maxDate: moment.Moment;
}> = ({ dateFiltered, setDateFiltered, existData, minDate, maxDate }) => {
  const ConnectedBtn = connectedSecondaryButton(simulateIFAC);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDate(dateFiltered);
  }, [dateFiltered]);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;
        setLoading(true);
        const res = await action({
          date
        });
        if (selectedDate) {
          setDateFiltered(selectedDate);
        }
        setLoading(false);
        handleCloseModal();
        return res;
      },
      {
        pending: 'Cargando...',
        success: `IFAC Simulado!`,
        error: 'Ocurrió un error al simular IFAC'
      }
    );
  };

  return (
    <div>
      <Button
        onClick={handleOpenModal}
        variant='contained'
        color='warning'
        disabled={!existData}
        style={{ height: '100%' }}>
        <OnlinePredictionIcon />
        Simular IFAC
      </Button>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            width: 'auto',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto'
          }}>
          <div
            style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
            onClick={handleCloseModal}>
            <CloseIcon />
          </div>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Simular
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                views={['day']}
                openTo='day'
                value={selectedDate}
                label='Dia'
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </LocalizationProvider>

            <ConnectedBtn
              onClick={onClick}
              buttonVariant='contained'
              buttonColor='success'
              disabled={!selectedDate || loading || !existData}
              style={{ padding: '10px' }}>
              Simular
            </ConnectedBtn>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

/* ---------------- ESTIMATED CLOSED ----------------------------- */

const transformFilesToString = async (
  files: File[] | null
): Promise<{ name: string; type: string; content: string }[]> => {
  if (!files) return [];

  const filePromises = Array.from(files).map((file) => {
    return new Promise<{ name: string; type: string; content: string }>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve({
          name: file.name,
          type: file.type,
          content: reader.result as string
        });
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  });

  return await Promise.all(filePromises);
};

export const EstimatedClosingAction: FunctionComponent<{ row: EstimatedClosing }> = ({ row }) => {
  const ConnectedBtn = connectedSecondaryButton(confirmEstimatedClosing);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [comment, setComment] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const offBudget = useMemo(
    () =>
      (!isNaN(Number(row.presupuestoAnualTotal)) &&
        !isNaN(Number(row.estimatedTotal)) &&
        Number(row.presupuestoAnualTotal) === 0 &&
        Number(row.estimatedTotal) !== 0) ||
      row.porcentajeDiferencia * 100 > 10 ||
      row.porcentajeDiferencia * 100 < -15,
    [row]
  );

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (offBudget && !(selectedCategory && comment)) {
      Swal.fire({
        icon: 'warning',
        title: 'Existe diferencia importante con el presupuesto',
        text: 'Debes añadir un archivo y una categoría para continuar'
      });
      return;
    }

    const processedFiles = await transformFilesToString(file ? [file] : null);

    const data = {
      _id: row._id,
      offBudget,
      category: selectedCategory,
      comment,
      file: file ? processedFiles[0] : undefined
    };

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ data, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: 'La acción se completo correctamente',
        error: 'Error, no se pudo completar la acción'
      }
    );
  };

  const options = [
    'REFACTURACION',
    'ARRIENDOS',
    'TRASLADOS',
    'MANTENCIONES',
    'AVANCE PROYECTOS',
    'SOLICITUD PERSONAL ADICIONAL',
    'JORNADA ADICIONAL',
    'INSTALACIONES',
    'SERVICIOS LIMPIEZA',
    'RENDICIONES',
    'APOYOS'
  ];

  const handleChangeFiles = (newFile: File | null) => {
    if (!newFile) {
      setError('');
      return;
    }

    if (newFile.size > 5 * 1024 * 1024) {
      setError(`El archivo ${newFile.name} excede el tamaño máximo de 5 MB.`);
      return;
    }

    setFile(newFile);
    setError('');
  };

  return offBudget ? (
    <ModalComponent
      modalTitle='Justificar estimado'
      btnIcon={<SaveAsIcon />}
      btnLabel='Justificar'
      btnColor='error'
      btnVariant='contained'
      actionBtn={
        <ConnectedBtn
          buttonVariant='contained'
          onClick={onClick}
          disabled={loading || !selectedCategory || !comment}>
          Justificar
        </ConnectedBtn>
      }
      body={
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option}
            value={selectedCategory}
            onChange={(_, value) => setSelectedCategory(value)}
            isOptionEqualToValue={(option, value) => option === value}
            noOptionsText='No se encontraron opciones'
            renderInput={(params) => (
              <TextField
                {...params}
                label='Categoria'
                variant='outlined'
                color='primary'
                required
              />
            )}
          />

          <div>
            <TextField
              label='Comentario'
              variant='outlined'
              value={comment}
              type='text'
              onChange={(e) => setComment((e.target.value || '').toUpperCase())}
              inputProps={{ maxLength: 150 }}
              fullWidth
              required
            />
            <Typography variant='body2' color='textSecondary' align='left'>
              {comment.length}/150 caracteres
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '5px'
            }}>
            <FileUploader
              classes='file-reader-input'
              handleChange={handleChangeFiles}
              name='file'
              label='Cargue o arrastre el archivo aquí'
              hoverTitle='Soltar aquí'
              fileOrFiles={file}
              disabled={loading}
              multiple={false}
              required
            />
            {error && <Alert severity='warning'>{error}</Alert>}
            {file && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <h3 style={{ margin: 0 }}>Archivo cargado:</h3>
                <p style={{ margin: 0, display: 'flex', gap: '5px' }}>
                  <span
                    style={{ margin: 0, color: 'red', cursor: 'pointer' }}
                    onClick={() => setFile(null)}>
                    X
                  </span>{' '}
                  {file.name}
                </p>
              </div>
            )}
          </div>
        </div>
      }
      customStyles={{ modalContainer: { width: '50%' } }}
    />
  ) : (
    <ConnectedBtn onClick={onClick} disabled={loading}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  );
};

export const MultipleApprovalClosingEstimateButton: FunctionComponent<{
  rowsSelected: {
    id: string;
    offBudget: boolean;
  }[];
}> = ({ rowsSelected }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(multipleApprovalClosingEstimate);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const ids = rowsSelected.map((r) => r.id);

    const offBudget = rowsSelected.some((r) => r.offBudget);

    if (offBudget) {
      toast.warning('No se puede confirmar un estimado de cierre que sobrepasa el presupuesto');
      Swal.fire({
        icon: 'warning',
        title: 'No se puede confirmar un estimado de cierre que sobrepasa el presupuesto'
      });
      return;
    }

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ ids, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: `Se aprobaron correctamente ${rowsSelected.length} estimados cierre`,
        error: 'Error, no se pudo completar la acción'
      }
    );
  };

  return rowsSelected.length > 0 ? (
    <ConnectedBtn
      onClick={onClick}
      disabled={rowsSelected.length === 0 || loading}
      buttonVariant='contained'
      buttonColor='success'>
      <CheckCircleIcon />
      Aprobar
    </ConnectedBtn>
  ) : null;
};

export const EditRefusedEstimatedClosed = (data: MRT_RowData) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(confirmEstimatedClosingRejected);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (params && data) {
      toast.promise(
        async () => {
          setLoading(true);
          const res = await action({ data, params });
          setLoading(false);
          if (res.status === 200) {
            return res;
          }
          throw new Error(res.response.data);
        },
        {
          pending: 'Cargando....',
          success: 'La acción se completo correctamente',
          error: 'Error, no se pudo completar la acción'
        }
      );
    }
  };

  return (
    <ConnectedBtn onClick={onClick} disabled={loading}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  );
};

export const EditStatusEstimatedClosed: FunctionComponent<{
  option: string | undefined;
  estimatedCloseId: string | undefined;
  textExplication?: string;
}> = ({ option, estimatedCloseId, textExplication }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(confirmEstimatedClosingPending);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (option === StatusBudgetNames.rejected && !textExplication) {
      errorPopAlert('Se debe justificar el Rechazo del Estimado de cierre');
      return;
    }

    if (params && option && estimatedCloseId) {
      toast.promise(
        async () => {
          setLoading(true);
          const res = await action({ option, estimatedCloseId, textExplication, params });
          setLoading(false);
          if (res.status === 200) {
            return res;
          }
          throw new Error(res.response.data);
        },
        {
          pending: 'Cargando....',
          success: 'La acción se completo correctamente',
          error: 'Error, no se pudo completar la acción'
        }
      );
    }
  };

  return option && estimatedCloseId ? (
    <ConnectedBtn onClick={onClick} disabled={loading}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  ) : null;
};

export const MultipleApprovalClosingEstimatePendingButton: FunctionComponent<{
  idsSelected: Array<string>;
}> = ({ idsSelected }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(multipleApprovalClosingEstimatePending);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ ids: idsSelected, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: `Se aprobaron correctamente ${idsSelected.length} estimados cierre pendientes`,
        error: 'Error, no se pudo completar la acción'
      }
    );
  };

  return idsSelected.length > 0 ? (
    <ConnectedBtn
      onClick={onClick}
      disabled={idsSelected.length === 0 || loading}
      buttonVariant='contained'
      buttonColor='success'>
      <CheckCircleIcon />
      Aprobar
    </ConnectedBtn>
  ) : null;
};

/* ------------------ PROVISION ----------------------------------- */

export const ConfirmProvisionButton = (budgetId: string, amountMd: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(confirmProvision);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ budgetId, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: `Se Confirmó la Provision`,
        error: 'Ocurrió un error al intentar confirmar la Provision'
      }
    );
  };

  return Number(amountMd) > 0 ? (
    <ConnectedBtn onClick={onClick} disabled={loading || !(Number(amountMd) > 0)}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  ) : null;
};

export const DeleteProvisionButton = (budgetId: string, amountMd: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(deleteProvision);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    Swal.fire({
      icon: 'question',
      title: '¿Seguro que deseas eliminar la Provision?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({ entityIds: [budgetId], params });
            setLoading(false);
            if (res.status === 200) {
              return res;
            }
            throw new Error(res.response.data);
          },
          {
            pending: 'Cargando....',
            success: `Se eliminó correctamente la Provision`,
            error: 'Ocurrió un error al intentar eliminar la Provision'
          }
        );
      }
    });
  };

  return Number(amountMd) > 0 ? (
    <ConnectedBtn
      onClick={onClick}
      buttonVariant='contained'
      buttonColor='error'
      disabled={loading || !(Number(amountMd) > 0)}>
      Eliminar
    </ConnectedBtn>
  ) : null;
};

export const ApproveOrRejectProvisionButton: FunctionComponent<{
  data: MRT_RowData;
  actions: string;
  textExplication?: string;
}> = ({ data, actions, textExplication }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(approveOrRejectProvision);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (actions === StatusBudgetNames.rejected && !textExplication) {
      errorPopAlert('Se debe justificar el Rechazo de la Provision (comentario)');
      return;
    }

    toast.promise(
      async () => {
        data.action = actions;
        data.comment = textExplication;
        setLoading(true);
        const res = await action({ data, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: `Se ${
          actions === StatusBudgetNames.approved ? 'aprobó' : 'rechazó'
        } correctamente la Provision`,
        error: `Ocurrio un error al ${
          actions === StatusBudgetNames.approved
            ? 'aprobar'
            : actions === StatusBudgetNames.rejected
            ? 'rechazar'
            : 'realizar la acción en'
        } la Provision`
      }
    );
  };

  return (
    <ConnectedBtn onClick={onClick} disabled={loading}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  );
};

export const ConfirmRefusedProvisionButton = (budgetId: string, amountMd: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(confirmRefusedProvision);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ budgetId, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: `Se confirmó correctamente la Provision Rechazada`,
        error: 'No se pudo confirmar la Provision Rechazada'
      }
    );
  };

  return Number(amountMd) > 0 ? (
    <ConnectedBtn onClick={onClick} disabled={loading || !(Number(amountMd) > 0)}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  ) : null;
};

/* ------------------ RECLASSIFICATION ----------------------------------- */

export const ConfirmReclassificationButton = (rows: Array<MRT_RowData>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(confirmReclassification);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ rows, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: `Se Confirmó la Reclasificación`,
        error: 'Ocurrió un error al intentar confirmar la Reclasificación'
      }
    );
  };

  return (
    <ConnectedBtn onClick={onClick} disabled={loading}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  );
};

export const DeleteReclassificationButton = (rows: Array<MRT_RowData>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(deleteReclassification);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    Swal.fire({
      icon: 'question',
      title: '¿Seguro que deseas eliminar la Reclasificación?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({ entityIds: [rows[0]._id], params });
            setLoading(false);
            if (res.status === 200) {
              return res;
            }
            throw new Error(res.response.data);
          },
          {
            pending: 'Cargando....',
            success: `Se eliminó correctamente la Reclasificación`,
            error: 'Ocurrió un error al intentar eliminar la Reclasificación'
          }
        );
      }
    });
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      buttonVariant='contained'
      buttonColor='error'
      disabled={loading}>
      Eliminar
    </ConnectedBtn>
  );
};

export const ApproveOrRejectReclassificationButton: FunctionComponent<{
  rows: Array<MRT_RowData>;
  selectedOption: string;
  textExplication?: string;
}> = ({ rows, selectedOption, textExplication }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(approveOrRejectReclassification);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (selectedOption === 'RECHAZADO' && !textExplication) {
      errorPopAlert('Se debe colocar algun comentario por la cual se esta rechazando la provision');
      return;
    }

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ rows, selectedOption, textExplication, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: `Se ${
          selectedOption === StatusBudgetNames.approved ? 'aprobó' : 'rechazó'
        } correctamente la Reclasificacion`,
        error: `Ocurrio un error al ${
          selectedOption === StatusBudgetNames.approved
            ? 'aprobar'
            : selectedOption === StatusBudgetNames.rejected
            ? 'rechazar'
            : 'realizar la acción en'
        } la Reclasificacion`
      }
    );
  };

  return (
    <ConnectedBtn onClick={onClick} disabled={loading}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  );
};

export const ConfirmRefusedReclassificationButton = (rows: Array<MRT_RowData>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(confirmRefusedReclassification);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ rows, params });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: `Se confirmó correctamente la Reclasificacion Rechazada`,
        error: 'No se pudo confirmar la Reclasificacion Rechazada'
      }
    );
  };

  return (
    <ConnectedBtn onClick={onClick} disabled={loading}>
      <CheckCircleIcon color={loading ? 'disabled' : 'success'} />
    </ConnectedBtn>
  );
};

/* ------------------ RECLASSIFICATION ----------------------------------- */

export const RefreshHfm: FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(refreshHfm);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        try {
          setLoading(true);
          const res = await action();
          setLoading(false);
          if (res && res.status === 200) {
            return res;
          }
          throw Error;
        } catch (error) {
          throw error;
        }
      },
      {
        pending: 'Cargando....',
        success: `HFM se refrescó correctamente:`,
        error: 'Error, no se puede refrescar HFM'
      }
    );
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      buttonVariant='contained'
      buttonColor='primary'
      disabled={loading}>
      <RefreshIcon />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshVolumen: FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(refreshVolumen);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        try {
          setLoading(true);
          const res = await action();
          setLoading(false);
          if (res && res.status === 200) {
            return res;
          }
          throw Error;
        } catch (error) {
          throw error;
        }
      },
      {
        pending: 'Cargando....',
        success: `Volumen se refrescó correctamente:`,
        error: 'Error, no se puede refrescar Volumen'
      }
    );
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      buttonVariant='contained'
      buttonColor='primary'
      disabled={loading}>
      <RefreshIcon />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshPolynomial: FunctionComponent<{
  dateFiltered: moment.Moment;
  setDateFiltered: Dispatch<SetStateAction<moment.Moment>>;
}> = ({ dateFiltered, setDateFiltered }) => {
  const ConnectedBtn = connectedSecondaryButton(refreshPolynomial);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dateFiltered) {
      if (dateFiltered.format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
        setSelectedDate(null);
      } else {
        setSelectedDate(dateFiltered);
      }
    }
  }, [dateFiltered]);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;

    const dateFormattedMsj = selectedDate ? selectedDate.format('MM-YYYY') : '';

    toast.promise(
      async () => {
        try {
          setLoading(true);
          const res = await action(date);
          setLoading(false);
          if (setDateFiltered && selectedDate) {
            setDateFiltered(selectedDate);
          }
          if (res && res.status === 200) {
            return res;
          }
          throw Error;
        } catch (error) {
          throw error;
        }
      },
      {
        pending: 'Cargando...',
        success: `Se refrescó correctamente Polinomio en la fecha: ${dateFormattedMsj}`,
        error: 'Ocurrió un error al refrescar el Polinomio'
      }
    );
  };

  return (
    <div>
      <Button
        onClick={handleOpenModal}
        variant='contained'
        color='primary'
        style={{ height: '100%' }}>
        <RefreshIcon />
        Refrescar
      </Button>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            width: 'auto',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto'
          }}>
          <div
            style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
            onClick={handleCloseModal}>
            <CloseIcon />
          </div>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Refrescar
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                views={['year', 'month']}
                openTo='year'
                value={selectedDate}
                label='Mes'
                onChange={(date) => setSelectedDate(date)}
              />
            </LocalizationProvider>

            <ConnectedBtn
              onClick={onClick}
              buttonVariant='contained'
              buttonColor='primary'
              disabled={!selectedDate || loading}>
              <RefreshIcon />
              Refrescar
            </ConnectedBtn>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export const PaymentAuthorizationButton: FunctionComponent<{
  shipmentAuditingCarriageId: string;
  authorizedForPay: boolean;
  endDateCarriageReport: string;
}> = ({ shipmentAuditingCarriageId, authorizedForPay, endDateCarriageReport }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(paymentAuthorization);

  const date = !isNaN(new Date(dateFormatFn(endDateCarriageReport, 'MM-DD-YYYY', true)).getDate())
    ? new Date(dateFormatFn(endDateCarriageReport, 'MM-DD-YYYY', true))
    : undefined;

  const monthDate =
    date && date.getDate() >= 27
      ? moment(date).clone().date(1).add(1, 'months').format('YYYY-MM-DD')
      : moment(date).clone().date(1).format('YYYY-MM-DD');

  const onClick = (authorization: boolean) => async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({
          shipmentAuditingCarriageId,
          authorization,
          date: monthDate
        });
        setLoading(false);
        if (res.status === 200) {
          return res;
        }
        throw new Error(res.response.data);
      },
      {
        pending: 'Cargando....',
        success: 'La acción se completo correctamente',
        error: 'Error, no se pudo completar la acción'
      }
    );
  };

  return date ? (
    authorizedForPay ? (
      <ConnectedBtn
        onClick={onClick(false)}
        disabled={loading}
        buttonVariant='contained'
        buttonColor='error'>
        Rechazar
      </ConnectedBtn>
    ) : (
      <ConnectedBtn
        onClick={onClick(true)}
        disabled={loading}
        buttonVariant='contained'
        buttonColor='success'>
        Autorizar
      </ConnectedBtn>
    )
  ) : null;
};
