import { Box } from '@mui/material';
import { ShipmentZT08Carriage } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const ShipmentZT08CarriageEntity: WebEntity<ShipmentZT08Carriage> = {
  name: 'shipmentZT08CarriageQ1',
  endpoint: 'shipmentCarriage/shipmentZT08Carriage',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'Clase de transporte',
      accessorKey: 'shipmentClass',
      columnType: 'text',
      size: 180
    },
    {
      header: 'Tipo Envase/Carga',
      accessorKey: 'packingType',
      columnType: 'text',
      size: 180
    },
    {
      header: 'Nombre agente servicios transporte',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 280
    },
    {
      header: 'Cantidad de Viajes',
      accessorKey: 'travelQuantity',
      columnType: 'numeric',
      size: 180,
      Cell: ({ row }) => (
        <Box sx={{ ...rightAlignStyle }}>
          {formatStringNumber(row.original?.travelQuantity, 0, 2)}
        </Box>
      ),
      aggregationFn: 'sum',
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.travelQuantity ? acc + Number(obj.travelQuantity) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'Total PxQ',
      accessorKey: 'totalPxq',
      columnType: 'numeric',
      size: 180,
      Cell: ({ row }) => (
        <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row.original?.totalPxq, 0, 2)}</Box>
      ),
      aggregationFn: 'sum',
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.totalPxq ? acc + Number(obj.totalPxq) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Box>
        );
      }
    }
  ]
};

export default ShipmentZT08CarriageEntity;
