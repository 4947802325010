import { PhoneUEN } from '../types';
import { WebEntity } from './types';

const PhoneUENEntity: WebEntity<PhoneUEN> = {
  name: 'phoneUEN',
  endpoint: 'phoneUEN',
  referenceColumn: 'phoneUENId',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: false
    },
    {
      header: 'Nombre UEN',
      accessorKey: 'phoneUenName',
      columnType: 'text'
    }
  ]
};

export default PhoneUENEntity;
