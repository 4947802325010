import { FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { connect, ConnectedComponent } from 'react-redux';
import { Select } from 'antd';
import type { SelectProps } from 'antd';
import { AppState } from '../store';
import { AuthState, EntitiesState } from '../reducers/types';
import { Rowing, SignalCellularAltOutlined } from '@mui/icons-material';
import { FormControlLabel, Stack, styled, Switch, Tooltip } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { SimpleObject, TelephoneExpenses as TelephoneExpensesType, UserTypeValues } from '../types';
import { getUncatchEndpointData } from '../utils/request';
import BackButton from '../components/BackButton';
import {
  PhoneAccountCRUD,
  TelephoneExpensesCRUD,
  PhonePlanCRUD,
  PhoneFamilyPlanCRUD,
  PhoneActionCRUD,
  PhoneUENCRUD,
  PhoneTrafficCRUD,
  PhoneEquipmentCRUD
} from '../components/GeneratedComponents';
import GenericTopTableCGFCCU from '../components/GenericTopTableCGFCCU';
import { formatStringNumber } from '../utils/utils';
import GenericModal from '../components/generics/GenericModal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import FileReaderCSV from '../components/FileReader';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ConfigProvider, DatePicker as AntDatePicker } from 'antd';
import locale from 'antd/locale/es_ES';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import TelephoneExpensesEntity, {
  TELEPHONE_EXPENSES_COLUMNS,
  columns as telephoneExpensesEntityColumns
} from '../entities/TelephoneExpensesEntity';
import { massiveLoadTelephoneExpenses } from '../actions/middleActions';
import '../css/telephonePage.css';
import { WebEntity } from '../entities/types';

moment.updateLocale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthsShort: [
    'Ene.',
    'Feb.',
    'Mar.',
    'Abr.',
    'May.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dic.'
  ]
});

dayjs.locale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthsShort: [
    'Ene.',
    'Feb.',
    'Mar.',
    'Abr.',
    'May.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dic.'
  ]
});

const MaterialUISwitch = styled(Switch)(() => ({
  width: 70,
  height: 36,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        color: '#fff',
        fontWeight: 900,
        content: "'CC'",
        width: '100%',
        height: '100%',
        left: '70%',
        top: '70%',
        transform: 'translate(-50%,-50%)'
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#64a70b',
    width: 34,
    height: 34,
    '&::before': {
      color: '#fff',
      fontWeight: 900,
      content: "'Zona'",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: '55%',
      top: '70%',
      transform: 'translate(-50%,-50%)'
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2
  }
}));

const TelephoneExpensesComponent: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadTelephoneExpenses: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    query: SimpleObject
  ) => any;
}> = ({ auth, entities, massiveLoadTelephoneExpenses }) => {
  const [value, setValue] = useState('1');
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <BackButton route='/reporteria/gastos-generales' />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Telefonía' value='1' />
            <Tab label='Cuentas' value='2' />
            <Tab label='Plan' value='3' />
            <Tab label='Plan Familia' value='4' />
            <Tab label='Acción' value='5' />
            <Tab label='UEN' value='6' />
            <Tab label='Tráfico' value='7' />
            <Tab label='Equipos' value='8' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <TelephoneExpenses
            auth={auth}
            entities={entities}
            massiveLoadTelephoneExpenses={massiveLoadTelephoneExpenses}
          />
        </TabPanel>
        <TabPanel value='2'>
          <PhoneAccountCRUD />
        </TabPanel>
        <TabPanel value='3'>
          <PhonePlanCRUD />
        </TabPanel>
        <TabPanel value='4'>
          <PhoneFamilyPlanCRUD />
        </TabPanel>
        <TabPanel value='5'>
          <PhoneActionCRUD />
        </TabPanel>
        <TabPanel value='6'>
          <PhoneUENCRUD />
        </TabPanel>
        <TabPanel value='7'>
          <PhoneTrafficCRUD />
        </TabPanel>
        <TabPanel value='8'>
          <PhoneEquipmentCRUD />
        </TabPanel>
      </TabContext>
    </div>
  );
};

const TelephoneExpensesPage = connect(({ auth, entities }: AppState) => ({ auth, entities }), {
  massiveLoadTelephoneExpenses
})(TelephoneExpensesComponent);

export default TelephoneExpensesPage;

const TelephoneExpenses: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadTelephoneExpenses: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    query: SimpleObject
  ) => any;
}> = ({ auth, entities, massiveLoadTelephoneExpenses }) => {
  const today = new Date();
  const [loading, setLoading] = useState<boolean>(false);
  const [arrowName, setArrowName] = useState<string>('');
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [zonaIsDependent, setZonaIsDependent] = useState<boolean>(true);

  const [columnasList, setColumnasList] = useState<SelectProps['options']>([]);
  const [ccList, setCcList] = useState<SelectProps['options']>([]);
  const [zonaList, setZonaList] = useState<SelectProps['options']>([]);
  const [userList, setUserList] = useState<SelectProps['options']>([]);
  const [phoneNumberList, setPhoneNumberList] = useState<SelectProps['options']>([]);

  const [selectedColumnas, setSelectedColumnas] = useState<string[]>([]);
  const [selectedCC, setSelectedCC] = useState<string[]>([]);
  const [selectedZona, setSelectedZona] = useState<string[]>([]);
  const [selectedMonths, setSelectedMonths] = useState<Dayjs[] | null>([dayjs(today)]);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(moment(today));
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string[]>([]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.telephoneExpenses.list.length);
  }, [auth, entities]);

  useEffect(() => {
    setLoading(true);

    const colums = telephoneExpensesEntityColumns.map((obj, idx) => ({
      key: `col_${idx}`,
      value: obj.accessorKey as string,
      label: obj.header
    }));
    setColumnasList(colums);
    setSelectedColumnas([
      'zoneDescrp',
      'phoneAccountName',
      'phoneFamilyPlanName',
      'phonePlanName',
      'costCenterSap',
      'staffFullName',
      'phoneNumber',
      'dateCancellationCcu',
      'quantity',
      'netMonthlyExpense'
    ]);

    (async () => {
      const zonasRes = await getUncatchEndpointData({ endpoint: 'zone' });
      if (zonasRes && zonasRes.length > 0) {
        const zonasData: SelectProps['options'] = zonasRes.map(
          (row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            label: row.zoneDescrp
          })
        );
        setZonaList(zonasData);
      }

      const usersRes = await getUncatchEndpointData({ endpoint: 'telephoneExpenses/staff' });
      if (usersRes && usersRes.length > 0) {
        const usersData: SelectProps['options'] = usersRes.map(
          (row: SimpleObject, idx: number) => ({
            key: `user_${idx}`,
            value: row._id,
            label: `${row.nationalId} - ${row.staffFullName}`
          })
        );
        setUserList(usersData);
      }

      const phonesRes = await getUncatchEndpointData({ endpoint: 'telephoneExpenses/mobile' });
      if (phonesRes && phonesRes.length > 0) {
        const phonesData: SelectProps['options'] = phonesRes.map(
          (row: SimpleObject, idx: number) => ({
            key: `phone_${idx}`,
            value: row._id,
            label: row.phoneNumber
          })
        );
        setPhoneNumberList(phonesData);
      }
    })();

    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchZonas = async () => {
      setLoading(true);
      if (zonaIsDependent) {
        const res = await getUncatchEndpointData({ endpoint: 'zone/zoneByCcAndUserType' });
        if (res && res.length > 0) {
          const data: SelectProps['options'] = res.map((row: SimpleObject) => ({
            value: row.zoneId,
            label: row.zoneDescrp
          }));
          setZonaList(data);
        }
      } else if (selectedCC && selectedCC.length > 0) {
        const res = await getUncatchEndpointData({
          endpoint: 'zone/zoneByCcAndUserType',
          query: { selectedCC }
        });
        if (res && res.length > 0) {
          const data: SelectProps['options'] = res.map((row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            label: row.zoneDescrp
          }));
          setZonaList(data);
        }
      } else {
        setZonaList([]);
        setSelectedZona([]);
      }
      setLoading(false);
    };

    fetchZonas();
  }, [zonaIsDependent, selectedCC]);

  useEffect(() => {
    const fetchCC = async () => {
      setLoading(true);
      if (!zonaIsDependent) {
        const res = await getUncatchEndpointData({ endpoint: 'costCenter/byZoneAndUserType' });
        if (res && res.length > 0) {
          const data: SelectProps['options'] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            label: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList(data);
        }
      } else if (selectedZona && selectedZona.length > 0) {
        const res = await getUncatchEndpointData({
          endpoint: 'costCenter/byZoneAndUserType',
          query: { selectedZona }
        });
        if (res && res.length > 0) {
          const data: SelectProps['options'] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            label: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList(data);
        }
      } else {
        setCcList([]);
        setSelectedCC([]);
      }
      setLoading(false);
    };

    fetchCC();
  }, [zonaIsDependent, selectedZona]);

  useEffect(() => {
    setSelectedCC([]);
    setSelectedZona([]);
    setCcList([]);
    setZonaList([]);
  }, [zonaIsDependent]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const usersRes = await getUncatchEndpointData({
        endpoint: 'telephoneExpenses/staff',
        query: {
          zonas: selectedZona,
          costCenters: selectedCC,
          date: selectedMonths?.map((value) => value.format('DD-MM-YYYY'))
        }
      });
      if (usersRes && usersRes.length > 0) {
        const usersData: SelectProps['options'] = usersRes.map(
          (row: SimpleObject, idx: number) => ({
            key: `user_${idx}`,
            value: row._id,
            label: `${row.nationalId} - ${row.staffFullName}`
          })
        );
        setUserList(usersData);
      }

      const phonesRes = await getUncatchEndpointData({
        endpoint: 'telephoneExpenses/mobile',
        query: {
          zonas: selectedZona,
          costCenters: selectedCC,
          users: selectedUser,
          date: selectedMonths?.map((value) => value.format('DD-MM-YYYY'))
        }
      });
      if (phonesRes && phonesRes.length > 0) {
        const phonesData: SelectProps['options'] = phonesRes.map(
          (row: SimpleObject, idx: number) => ({
            key: `phone_${idx}`,
            value: row._id,
            label: row.phoneNumber
          })
        );
        setPhoneNumberList(phonesData);
      }
      setLoading(false);
    })();
  }, [entities.telephoneExpenses.list]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns = telephoneExpensesEntityColumns.filter((c) =>
      selectedColumnas.find((s) => s === c.accessorKey)
    );

    const groupingColumns = tableColumns.filter((c) => c.groupingColumn).map((c) => c.accessorKey);

    const entity: WebEntity<TelephoneExpensesType> = { ...TelephoneExpensesEntity, tableColumns };

    return TelephoneExpensesCRUD(entity, groupingColumns, {
      columns: selectedColumnas,
      zonas: selectedZona,
      costCenters: selectedCC,
      users: selectedUser,
      phoneNumbers: selectedPhoneNumber,
      date: selectedMonths?.map((value) => value.format('DD-MM-YYYY'))
    });
  }, [
    selectedColumnas,
    selectedZona,
    selectedCC,
    selectedUser,
    selectedPhoneNumber,
    selectedMonths
  ]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadTelephoneExpenses(data, selectedDate, {
      columns: selectedColumnas,
      zonas: selectedZona,
      costCenters: selectedCC,
      users: selectedUser,
      phoneNumbers: selectedPhoneNumber,
      date: [selectedDate.format('DD-MM-YYYY')]
    });

    if (res) {
      setSelectedMonths([dayjs(selectedDate.format('MM-DD-YYYY'))]);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  return (
    <div className='telephone-page-telephone-expenses-container'>
      <div className='telephone-page-telephone-filters-container-dynamic-columns'>
        <Select
          size='large'
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Columnas'
          value={selectedColumnas}
          onChange={(values) => setSelectedColumnas(values)}
          options={columnasList}
          loading={loading}
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
          showSearch
          optionFilterProp='label'
        />
      </div>
      <div className='telephone-page-telephone-filters-container'>
        <Select
          size='large'
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Zona'
          value={selectedZona}
          onChange={(values) => setSelectedZona(values)}
          options={zonaList}
          loading={loading}
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
          showSearch
          optionFilterProp='label'
        />

        <Tooltip title='¿Que filtro es dependiente del otro?' followCursor>
          <FormControlLabel
            control={
              <MaterialUISwitch
                checked={zonaIsDependent}
                value={zonaIsDependent}
                onChange={(_, checked) => setZonaIsDependent(checked)}
              />
            }
            label='¿Dependiente?'
            labelPlacement='top'
            slotProps={{
              typography: { textAlign: 'center', fontWeight: 900, marginTop: -2 }
            }}
          />
        </Tooltip>

        <Select
          size='large'
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='CC'
          value={selectedCC}
          onChange={(values) => setSelectedCC(values)}
          options={ccList}
          loading={loading}
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
        />

        <Select
          size='large'
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Usuario'
          value={selectedUser}
          onChange={(values) => setSelectedUser(values)}
          options={userList}
          loading={loading}
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
        />

        <Select
          size='large'
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Móvil'
          value={selectedPhoneNumber}
          onChange={(values) => setSelectedPhoneNumber(values)}
          options={phoneNumberList}
          loading={loading}
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
        />

        <ConfigProvider locale={locale}>
          <AntDatePicker
            size='large'
            multiple
            onChange={(dates) => {
              if (dates) {
                const dayjsDates = dates.map((date) => dayjs(date));
                setSelectedMonths(dayjsDates);
              } else {
                setSelectedMonths(null);
              }
            }}
            picker='month'
            maxTagCount='responsive'
            value={selectedMonths}
            format='MMMM YYYY'
            style={{ width: '100%' }}
          />
        </ConfigProvider>
      </div>

      <div className='telephone-page-telephone-expenses-top-table-container'>
        <GenericModal
          title={'CARGA MASIVA GASTOS TELEFONIA'}
          buttonName={'CARGA MASIVA GASTOS TELEFONIA'}
          buttonIcon={<FileUploadIcon />}
          buttonVariant='contained'
          buttonColor='success'
          openHook={() => setCloseModal(false)}
          closeModal={closeModal}>
          <div className='telephone-page-telephone-expenses-massive-load-filters-modal-container'>
            <div>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                <DatePicker
                  label='Mes'
                  views={['year', 'month']}
                  openTo='year'
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  maxDate={moment(today)}
                />
              </LocalizationProvider>
            </div>

            <FileReaderCSV columns={TELEPHONE_EXPENSES_COLUMNS} callback={uploadFile} />
          </div>
        </GenericModal>

        <GenericTopTableCGFCCU
          titleTable='Gastos Telefonía'
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
      </div>
      <CRUD />
    </div>
  );
};
