import * as E from '../entities';
import { WebEntity, WebEntityName } from '../entities/types';
import { AppDispatch, AppGetState } from '../store';
import { getEntitiesAction } from './entitiesActions';
import {
  BudgetCarriage,
  BudgetTypeCarriageNames,
  Categories,
  Expenses,
  FortnightNumber,
  IfacType,
  PaymentType,
  SimpleObject,
  User
} from '../types';
import axios from 'axios';
import { tokenConfig } from '../utils/header';
import { handleError } from '../components/PopAlert';
import Swal from 'sweetalert2';

// REFRESHERS
const refreshEntity =
  (entity: WebEntity<any>, serverSidePagination?: boolean, query?: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      entity.name as WebEntityName,
      entity.endpoint,
      query ? { query } : {}
    )(serverSidePagination ? 1 : undefined)(dispatch, getState);

export const massiveLoadPresupuestoVolumen =
  (data: SimpleObject[], selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/volumen/presupuesto/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.PresupuestoVolumenEntity, undefined, {
        filters: { date: selectedDate.format('YYYY-MM-DD') }
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.PresupuestoVolumenEntity.name, dispatch });
    }
  };

export const mergeUsers =
  (UsersData: Array<User>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/users/massiveLoad`, UsersData, tokenConfig(getState));
      await refreshEntity(E.UserEntity)(dispatch, getState);
      if (res && res.data) {
        const diffUser = res.data.diffUser;
        const newUser = res.data.newUser;
        if (diffUser.length > 0 || newUser.length > 0) {
          const diffT = diffUser.map(
            (d: SimpleObject) =>
              `<p>* Nombre: ${d.name} - Nombre Usuario: ${d.userUsername} - Email: ${d.userEmail} - Clave: ${d.userPassword}</p>`
          );
          const newT = newUser.map(
            (d: SimpleObject) =>
              `<p>* Nombre: ${d.name} - Nombre Usuario: ${d.userUsername} - Email: ${d.userEmail} - Clave: ${d.userPassword}</p>`
          );
          Swal.fire({
            icon: 'success',
            title: `¡Se completó la carga! Nuevos: ${newUser.length}. Actualizados: ${diffUser.length}!`,
            html: `${newT.length > 0 ? '<h3>Nuevos</h3>' : ''}
            ${newT.join('\n')}

            ${diffT.length > 0 ? '<h3>Actualizados</h3>' : ''}
            ${diffT.join('\n')}`,
            showCloseButton: true
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: `¡Se completó la carga, pero los datos insertados son iguales al mantenedor!`,
            showCloseButton: true
          });
        }
      }
      return res;
    } catch (error) {
      handleError({ error, entityName: E.UserEntity.name, dispatch });
    }
  };

export const massiveLoadExpenses =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/expenses/massiveload`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.ExpensesEntity, undefined, {
        filters: { date: selectedDate.format('YYYY-MM-DD') }
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ExpensesEntity.name, dispatch });
    }
  };

export const mergeAreaManager =
  (areaManagerData: Array<Categories>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/costcenter/centerAssignment/merge`,
        areaManagerData,
        tokenConfig(getState)
      );
      await refreshEntity(E.AreaManagerEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.DictionaryCcEntity.name, dispatch });
    }
  };

export const mergeBudget =
  (data: Array<SimpleObject>, params: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const { moduleType } = params;
      const res = await axios.post(
        `/api/budget/merge`,
        { data, moduleType },
        tokenConfig(getState)
      );
      await refreshEntity(E.BudgetEntity, false, { moduleType })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.BudgetEntity.name, dispatch });
    }
  };

// =============== ESTIMATED CLOSING - ESTIMADO CIERRE ===============

export const mergeEstimatedClosing =
  (data: Array<Expenses>, params: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/expenses/estimatedClosing/massiveLoad`,
        { data, ...params },
        tokenConfig(getState)
      );
      await refreshEntity(E.EstimatedClosingEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.EstimatedClosingEntity.name, dispatch });
    }
  };

export const confirmEstimatedClosing =
  ({ data, params }: { data: Array<Expenses>; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/expenses/estimatedClosing/update`,
        { data },
        tokenConfig(getState)
      );
      await refreshEntity(E.EstimatedClosingEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.EstimatedClosingEntity.name, dispatch });
    }
  };

export const confirmEstimatedClosingPending =
  ({
    option,
    estimatedCloseId,
    textExplication,
    params
  }: {
    option: string;
    estimatedCloseId: string;
    textExplication: string;
    params: SimpleObject;
  }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/expenses/EstimatedClosed/UpdatePendingEstimatedClosed`,
        { option, estimatedCloseId, textExplication },
        tokenConfig(getState)
      );
      await refreshEntity(E.EstimatedClosingPendingEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.EstimatedClosingPendingEntity.name, dispatch });
      return error;
    }
  };

export const confirmEstimatedClosingRejected =
  ({ data, params }: { data: Array<Expenses>; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/expenses/EstimatedClosed/updateRefusedEstimatedClose`,
        { data },
        tokenConfig(getState)
      );
      await refreshEntity(E.EstimatedClosingRejectedEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.EstimatedClosingRejectedEntity.name, dispatch });
    }
  };

export const dateAndGlobalZoneFilterEntity =
  (
    {
      date,
      zoneGlobal,
      additionalParams
    }: { date: string; zoneGlobal: string; additionalParams: SimpleObject },
    webEntity: WebEntity<SimpleObject>
  ) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(webEntity, undefined, {
        filters: { date, zoneGlobal },
        ...additionalParams
      })(dispatch, getState);
      return { filtered: { date, zoneGlobal } };
    } catch (error) {
      handleError({ error, entityName: webEntity.name, dispatch });
    }
  };

export const dateAndSelectFilterEntity =
  (params: SimpleObject, webEntity: WebEntity<SimpleObject>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(webEntity, undefined, {
        filters: { ...params }
      })(dispatch, getState);
      return { filtered: { ...params } };
    } catch (error) {
      handleError({ error, entityName: webEntity.name, dispatch });
    }
  };

export const multipleApprovalClosingEstimate =
  ({ ids, params }: { ids: Array<string>; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/expenses/closingEstimateRejected/multipleApprovals`,
        { ids },
        tokenConfig(getState)
      );

      await refreshEntity(E.EstimatedClosingEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.EstimatedClosingEntity.name, dispatch });
    }
  };

export const multipleApprovalClosingEstimatePending =
  ({ ids, params }: { ids: Array<string>; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/expenses/closingEstimatePending/multipleApprovals`,
        { ids },
        tokenConfig(getState)
      );

      await refreshEntity(E.EstimatedClosingPendingEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.EstimatedClosingPendingEntity.name, dispatch });
    }
  };

// ============================ PROVISION ============================

export const massiveLoadProvision =
  (data: SimpleObject[], params: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/provision/massiveLoad`,
        { data, ...params },
        tokenConfig(getState)
      );
      await refreshEntity(E.ProvisionEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ProvisionEntity.name, dispatch });
    }
  };

export const confirmProvision =
  ({ budgetId, params }: { budgetId: string; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(`/api/provision/confirm`, { budgetId }, tokenConfig(getState));
      await refreshEntity(E.ProvisionEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ProvisionEntity.name, dispatch });
    }
  };

export const deleteProvision =
  ({ entityIds, params }: { entityIds: Array<string>; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.patch(
        `/api/provision`,
        { action: 'delete', entityIds },
        tokenConfig(getState)
      );
      await refreshEntity(E.ProvisionEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ProvisionEntity.name, dispatch });
    }
  };

export const approveOrRejectProvision =
  ({ data, params }: { data: SimpleObject; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/provision/approveOrRejectProvision`,
        { data, actions: data.action, textExplication: data.comment },
        tokenConfig(getState)
      );
      await refreshEntity(E.PendingProvisionEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.PendingProvisionEntity.name, dispatch });
    }
  };

export const confirmRefusedProvision =
  ({ budgetId, params }: { budgetId: string; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/provision/confirmRefusedProvision`,
        { budgetId },
        tokenConfig(getState)
      );
      await refreshEntity(E.RefusedProvisionEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.RefusedProvisionEntity.name, dispatch });
    }
  };

// ================ reclassification - Reclasifiacion ================

export const massiveLoadReclassification =
  (data: SimpleObject[], params: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/reclassification/massiveLoad`,
        { data, ...params },
        tokenConfig(getState)
      );
      await refreshEntity(E.ReclassificationEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ReclassificationEntity.name, dispatch });
    }
  };

export const confirmReclassification =
  ({ rows, params }: { rows: Array<SimpleObject>; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(`/api/reclassification/confirm`, { rows }, tokenConfig(getState));
      await refreshEntity(E.ReclassificationEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ReclassificationEntity.name, dispatch });
    }
  };

export const deleteReclassification =
  ({ entityIds, params }: { entityIds: Array<string>; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.patch(
        `/api/reclassification`,
        { action: 'delete', entityIds },
        tokenConfig(getState)
      );
      await refreshEntity(E.ReclassificationEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ReclassificationEntity.name, dispatch });
    }
  };

export const approveOrRejectReclassification =
  ({
    rows,
    selectedOption,
    textExplication,
    params
  }: {
    rows: Array<SimpleObject>;
    selectedOption: string;
    textExplication?: string;
    params: SimpleObject;
  }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/reclassification/approveOrRejectReclassification`,
        { rows, selectedOption, textExplication },
        tokenConfig(getState)
      );
      await refreshEntity(E.PendingReclassificationEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.PendingReclassificationEntity.name, dispatch });
    }
  };

export const confirmRefusedReclassification =
  ({ rows, params }: { rows: Array<SimpleObject>; params: SimpleObject }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/reclassification/confirmRefusedReclassification`,
        { rows },
        tokenConfig(getState)
      );
      await refreshEntity(E.RefusedReclassificationEntity, undefined, params)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.RefusedReclassificationEntity.name, dispatch });
    }
  };

export const refreshHfm = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  try {
    const res = await axios.post(`/api/hfm/refresh`, {}, tokenConfig(getState));
    await refreshEntity(E.HFMEntity)(dispatch, getState);
    return res;
  } catch (error) {
    handleError({ error, entityName: E.HFMEntity.name, dispatch });
  }
};

export const refreshVolumen = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  try {
    const res = await axios.post(`/api/volumen/refresh`, {}, tokenConfig(getState));
    await refreshEntity(E.VolumenEntity)(dispatch, getState);
    return res;
  } catch (error) {
    handleError({ error, entityName: E.VolumenEntity.name, dispatch });
  }
};

export const massiveLoadGloss =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/gloss/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.GlossEntity, undefined, {
        filters: { date: selectedDate.format('YYYY-MM-DD') }
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.GlossEntity.name, dispatch });
    }
  };

export const massiveLoadTariff =
  (data: Array<SimpleObject>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/tariff/massiveLoad`, { data }, tokenConfig(getState));
      await refreshEntity(E.TariffEntity)(dispatch, getState);
      if (res && res.data) {
        const diffTariff = res.data.diffTariff;
        const newTariff = res.data.newTariff;
        if (diffTariff.length > 0 || newTariff.length > 0) {
          const diffT = diffTariff.map(
            (d: SimpleObject) =>
              `<p>* EETT: ${d.carrierName} - Zona: ${d.zonePortingDescription} - Tipo EETT: ${d.carrierTypeName} - Tarifa: ${d.tariff} - Retencion por contigencia TCCU: ${d.contingency} - Retencion por contigencia EETT: ${d.contingencyEett} - Desde: ${d.dateValidFrom} - Hasta: ${d.dateValidTo}</p>`
          );
          const newT = newTariff.map(
            (d: SimpleObject) =>
              `<p>* EETT: ${d.carrierName} - Zona: ${d.zonePortingDescription} - Tipo EETT: ${d.carrierTypeName} - Tarifa: ${d.tariff} - Retencion por contigencia TCCU: ${d.contingency} - Retencion por contigencia EETT: ${d.contingencyEett} - Desde: ${d.dateValidFrom} - Hasta: ${d.dateValidTo}</p>`
          );
          Swal.fire({
            icon: 'success',
            title: `¡Se completó la carga! Nuevos: ${newTariff.length}. Actualizados: ${diffTariff.length}!`,
            html: `${newT.length > 0 ? '<h3>Nuevos</h3>' : ''}
            ${newT.join('\n')}

            ${diffT.length > 0 ? '<h3>Actualizados</h3>' : ''}
            ${diffT.join('\n')}`,
            showCloseButton: true
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: `¡Se completó la carga, pero los datos insertados son iguales al mantenedor!`,
            showCloseButton: true
          });
        }
      }
      return res;
    } catch (error) {
      handleError({ error, entityName: E.TariffEntity.name, dispatch });
    }
  };

export const massiveLoadFreightCharges =
  (data: Array<SimpleObject>, selectedDate: moment.Moment, selectedFortnight: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/freightCharges/massiveLoad`,
        { data, selectedDate, selectedFortnight },
        tokenConfig(getState)
      );
      await refreshEntity(E.FreightChargesQ1Entity, undefined, {
        fortnight: FortnightNumber.FirstFortnight,
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      await refreshEntity(E.FreightChargesQ2Entity, undefined, {
        fortnight: FortnightNumber.SecondFortnight,
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.FreightChargesQ1Entity.name, dispatch });
    }
  };

export const foreightChargesDateFilter =
  ({ date }: { date: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.FreightChargesQ1Entity, undefined, {
        date,
        fortnight: FortnightNumber.FirstFortnight
      })(dispatch, getState);
      await refreshEntity(E.FreightChargesQ2Entity, undefined, {
        date,
        fortnight: FortnightNumber.SecondFortnight
      })(dispatch, getState);
      return { filtered: { date } };
    } catch (error) {
      handleError({ error, entityName: E.FreightChargesQ1Entity.name, dispatch });
    }
  };

export const massiveLoadEquivalentBox =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/equivalentBox/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.EquivalentBoxEntity, undefined, {
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.EquivalentBoxEntity.name, dispatch });
    }
  };

export const massiveLoadCarrier =
  (data: Array<SimpleObject>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/carrier/massiveLoad`, { data }, tokenConfig(getState));
      await refreshEntity(E.CarrierEntity)(dispatch, getState);
      if (res && res.data) {
        const diffCarrier = res.data.diffCarrier;
        const newCarrier = res.data.newCarrier;
        if (diffCarrier.length > 0 || newCarrier.length > 0) {
          const diffT = diffCarrier.map(
            (d: SimpleObject) =>
              `<p>* EETT: ${d.carrierName} - Rut: ${d.carrierRut} - BP: ${d.bp} - Tipo EETT: ${d.managementTypeName}</p>`
          );
          const newT = newCarrier.map(
            (d: SimpleObject) =>
              `<p>* EETT: ${d.carrierName} - Rut: ${d.carrierRut} - BP: ${d.bp} - Tipo EETT: ${d.managementTypeName}</p>`
          );
          Swal.fire({
            icon: 'success',
            title: `¡Se completó la carga! Nuevos: ${newCarrier.length}. Actualizados: ${diffCarrier.length}!`,
            html: `${newT.length > 0 ? '<h3>Nuevos</h3>' : ''}
            ${newT.join('\n')}

            ${diffT.length > 0 ? '<h3>Actualizados</h3>' : ''}
            ${diffT.join('\n')}`,
            showCloseButton: true
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: `¡Se completó la carga, pero los datos insertados son iguales al mantenedor!`,
            showCloseButton: true
          });
        }
      }
      return res;
    } catch (error) {
      handleError({ error, entityName: E.CarrierEntity.name, dispatch });
    }
  };

export const massiveLoadIfac =
  (data: Array<SimpleObject>, selectedDate: moment.Moment, ifacType: IfacType) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/ifac/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.IfacEntity, undefined, { ifacType })(dispatch, getState);
      if (res && res.data && res.data.successMessage) {
        Swal.fire({
          icon: 'success',
          title: `¡Se completó la carga!`,
          text: res.data.successMessage,
          showCloseButton: true
        });
      }
      return res;
    } catch (error) {
      handleError({ error, entityName: E.IfacEntity.name, dispatch });
    }
  };

export const ifacFilter =
  ({
    ifacType,
    companyName,
    date,
    route,
    itemGroup,
    carrierDescription
  }: {
    ifacType: string;
    companyName?: string;
    date?: string;
    route?: string;
    itemGroup?: string;
    carrierDescription?: string;
  }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.IfacEntity, undefined, {
        ifacType,
        filters: { companyName, date, route, itemGroup, carrierDescription }
      })(dispatch, getState);
      return { filtered: { companyName, date, route, itemGroup, carrierDescription } };
    } catch (error) {
      handleError({ error, entityName: E.IfacEntity.name, dispatch });
    }
  };

export const simulateIFAC =
  ({ date }: { date: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/ifac/simulateIFAC`, { date }, tokenConfig(getState));
      await refreshEntity(E.IfacSimulatedEntity, undefined, {
        date
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.IfacSimulatedEntity.name, dispatch });
    }
  };

export const massiveLoadMajorPorting =
  (data: Array<SimpleObject>, query: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/majorPorting/massiveLoad`,
        { data },
        tokenConfig(getState)
      );
      await refreshEntity(E.MajorPortingEntity, undefined, query)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.MajorPortingEntity.name, dispatch });
    }
  };

export const massiveLoadForeignExchangeEstimated =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/foreignExchange/estimated/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.ForeignExchangeEstimatedEntity, undefined, {
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ForeignExchangeEstimatedEntity.name, dispatch });
    }
  };

export const massiveLoadPermanentTruck =
  (data: Array<SimpleObject>, selectedDate: moment.Moment, selectedFortnight: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/permanentTruck/massiveLoad`,
        { data, selectedDate, selectedFortnight },
        tokenConfig(getState)
      );
      await refreshEntity(E.PermanentTruckQ1Entity, undefined, {
        fortnight: FortnightNumber.FirstFortnight,
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      await refreshEntity(E.PermanentTruckQ2Entity, undefined, {
        fortnight: FortnightNumber.SecondFortnight,
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.PermanentTruckQ1Entity.name, dispatch });
    }
  };

export const permanentTruckDateFilter =
  ({ date }: { date: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.PermanentTruckQ1Entity, undefined, {
        date,
        fortnight: FortnightNumber.FirstFortnight
      })(dispatch, getState);
      await refreshEntity(E.PermanentTruckQ2Entity, undefined, {
        date,
        fortnight: FortnightNumber.SecondFortnight
      })(dispatch, getState);
      return { filtered: { date } };
    } catch (error) {
      handleError({ error, entityName: E.PermanentTruckQ1Entity.name, dispatch });
    }
  };

export const massiveLoadCarrierZoneGloss =
  (data: Array<SimpleObject>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/carrierZoneGloss/massiveLoad`,
        { data },
        tokenConfig(getState)
      );
      await refreshEntity(E.CarrierZoneGlossEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.CarrierZoneGlossEntity.name, dispatch });
    }
  };

export const massiveLoadContractorTariff =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/contractorTariff/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.ContractorTariffEntity, undefined, {
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ContractorTariffEntity.name, dispatch });
    }
  };

export const massiveLoadFreightExtra =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/freightExtra/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.FreightExtraEntity, undefined, {
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.FreightExtraEntity.name, dispatch });
    }
  };

export const massiveLoadFreightExtraGlSap =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/freightExtra/glSap/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.FreightExtraGlSapEntity, undefined, {
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.FreightExtraGlSapEntity.name, dispatch });
    }
  };

export const massiveLoadCarrierRouteCarriage =
  (data: Array<SimpleObject>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/carrierRouteCarriage/massiveLoad`,
        { data },
        tokenConfig(getState)
      );
      await refreshEntity(E.CarrierRouteCarriageEntity)(dispatch, getState);
      if (res && res.data) {
        const padWithZero = (value: number) => {
          return value < 10 ? `0${value}` : value;
        };
        const diffCarrierRouteCarriage = res.data.diffCarrierRouteCarriage;
        const newCarrierRouteCarriage = res.data.newCarrierRouteCarriage;
        if (diffCarrierRouteCarriage.length > 0 || newCarrierRouteCarriage.length > 0) {
          const diffC = diffCarrierRouteCarriage.map((d: SimpleObject) => {
            const hours = d.time?.hours ? padWithZero(Number(d.time.hours)) : '00';
            const minutes = d.time?.minutes ? padWithZero(Number(d.time.minutes)) : '00';
            const seconds = d.time?.seconds ? padWithZero(Number(d.time.seconds)) : '00';
            const time = `${hours}:${minutes}:${seconds}`;
            return `<p>* Ruta: ${d.route} - EETT: ${d.carrierName} - Tiempo: ${time} - Ruta p.transporte: ${d.timeRouteIdCcu} - Tipo de Contrato: ${d.carrierTypeCarriageName} - Tarifa: ${d.tariff} - Desde: ${d.dateValidFrom} - Hasta: ${d.dateValidTo}</p>`;
          });
          const newC = newCarrierRouteCarriage.map((d: SimpleObject) => {
            const hours = d.time?.hours ? padWithZero(Number(d.time.hours)) : '00';
            const minutes = d.time?.minutes ? padWithZero(Number(d.time.minutes)) : '00';
            const seconds = d.time?.seconds ? padWithZero(Number(d.time.seconds)) : '00';
            const time = `${hours}:${minutes}:${seconds}`;
            return `<p>* Ruta: ${d.route} - EETT: ${d.carrierName} - Tiempo: ${time} - Ruta p.transporte: ${d.timeRouteIdCcu} - Tipo de Contrato: ${d.carrierTypeCarriageName} - Tarifa: ${d.tariff} - Desde: ${d.dateValidFrom} - Hasta: ${d.dateValidTo}</p>`;
          });
          Swal.fire({
            icon: 'success',
            title: `¡Se completó la carga! Nuevos: ${newCarrierRouteCarriage.length}. Actualizados: ${diffCarrierRouteCarriage.length}!`,
            html: `${newC.length > 0 ? '<h3>Nuevos</h3>' : ''}
            ${newC.join('\n')}

            ${diffC.length > 0 ? '<h3>Actualizados</h3>' : ''}
            ${diffC.join('\n')}`,
            showCloseButton: true
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: `¡Se completó la carga, pero los datos insertados son iguales al mantenedor!`,
            showCloseButton: true
          });
        }
      }
      return res;
    } catch (error) {
      handleError({ error, entityName: E.CarrierRouteCarriageEntity.name, dispatch });
    }
  };

export const massiveLoadExtraCarriage =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/extraCarriage/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.ExtraCarriageEntity, undefined, {
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ExtraCarriageEntity.name, dispatch });
    }
  };

export const massiveLoadShipmentCarriage =
  (data: Array<SimpleObject>, selectedDate: moment.Moment, selectedFortnight: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/shipmentCarriage/massiveLoad`,
        { data, selectedDate, selectedFortnight },
        tokenConfig(getState)
      );
      await refreshEntity(E.ShipmentCarriageQ1Entity, undefined, {
        fortnight: FortnightNumber.FirstFortnight,
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      await refreshEntity(E.ShipmentCarriageQ2Entity, undefined, {
        fortnight: FortnightNumber.SecondFortnight,
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ShipmentCarriageQ1Entity.name, dispatch });
    }
  };

export const massiveLoadShipmentCarriageEmptyFields =
  (data: Array<SimpleObject>, selectedDate: moment.Moment, selectedFortnight: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/shipmentCarriage/emptyFields/massiveload`,
        { data, selectedDate, selectedFortnight },
        tokenConfig(getState)
      );
      await refreshEntity(E.shipmentCarriageEmptyFieldsQ1Entity, undefined, {
        fortnight: FortnightNumber.FirstFortnight,
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      await refreshEntity(E.shipmentCarriageEmptyFieldsQ2Entity, undefined, {
        fortnight: FortnightNumber.SecondFortnight,
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.shipmentCarriageEmptyFieldsQ1Entity.name, dispatch });
    }
  };

export const shipmentCarriageDateFilter =
  ({ date }: { date: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.ShipmentCarriageQ1Entity, undefined, {
        date,
        fortnight: FortnightNumber.FirstFortnight
      })(dispatch, getState);
      await refreshEntity(E.ShipmentCarriageQ2Entity, undefined, {
        date,
        fortnight: FortnightNumber.SecondFortnight
      })(dispatch, getState);
      return { filtered: { date } };
    } catch (error) {
      handleError({ error, entityName: E.ShipmentCarriageQ1Entity.name, dispatch });
    }
  };

export const shipmentCarriagePXQDateFilter =
  ({ date, carrierTypeCarriage }: { date: string; carrierTypeCarriage: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.ShipmentCarriagePXQQ1Entity, undefined, {
        date,
        fortnight: FortnightNumber.FirstFortnight,
        carrierTypeCarriage
      })(dispatch, getState);
      await refreshEntity(E.ShipmentCarriagePXQQ2Entity, undefined, {
        date,
        fortnight: FortnightNumber.SecondFortnight,
        carrierTypeCarriage
      })(dispatch, getState);
      return { filtered: { date, carrierTypeCarriage } };
    } catch (error) {
      handleError({ error, entityName: E.ShipmentCarriagePXQQ1Entity.name, dispatch });
    }
  };

export const shipmentAuditingCarriageDateFilter =
  ({ date }: { date: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.ShipmentAuditingCarriageQ1Entity, undefined, {
        date,
        fortnight: FortnightNumber.FirstFortnight
      })(dispatch, getState);
      await refreshEntity(E.ShipmentAuditingCarriageQ2Entity, undefined, {
        date,
        fortnight: FortnightNumber.SecondFortnight
      })(dispatch, getState);
      return { filtered: { date } };
    } catch (error) {
      handleError({ error, entityName: E.ShipmentAuditingCarriageQ1Entity.name, dispatch });
    }
  };

export const resumeShipmentCarriageDateFilter =
  ({ date, paymentType }: { date: string; paymentType: PaymentType }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.ResumeShipmentCarriageQ1Entity, undefined, {
        date,
        fortnight: FortnightNumber.FirstFortnight,
        paymentType
      })(dispatch, getState);
      await refreshEntity(E.ResumeShipmentCarriageQ2Entity, undefined, {
        date,
        fortnight: FortnightNumber.SecondFortnight,
        paymentType
      })(dispatch, getState);
      return { filtered: { date, paymentType } };
    } catch (error) {
      handleError({ error, entityName: E.ResumeShipmentCarriageQ1Entity.name, dispatch });
    }
  };

export const shipmentZT08CarriageDateFilter =
  ({ date }: { date: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.ShipmentZT08CarriageQ1Entity, undefined, {
        date,
        fortnight: FortnightNumber.FirstFortnight
      })(dispatch, getState);
      await refreshEntity(E.ShipmentZT08CarriageQ2Entity, undefined, {
        date,
        fortnight: FortnightNumber.SecondFortnight
      })(dispatch, getState);
      return { filtered: { date } };
    } catch (error) {
      handleError({ error, entityName: E.ShipmentZT08CarriageQ1Entity.name, dispatch });
    }
  };

export const shipmentCarriageFrom4UsersDateFilter =
  ({ date }: { date: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await refreshEntity(E.ShipmentCarriageFrom4UsersQ1Entity, undefined, {
        date,
        fortnight: FortnightNumber.FirstFortnight
      })(dispatch, getState);
      await refreshEntity(E.ShipmentCarriageFrom4UsersQ2Entity, undefined, {
        date,
        fortnight: FortnightNumber.SecondFortnight
      })(dispatch, getState);
      return { filtered: { date } };
    } catch (error) {
      handleError({ error, entityName: E.ShipmentCarriageFrom4UsersQ1Entity.name, dispatch });
    }
  };

export const refreshPolynomial =
  (date: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/polynomial/refresh`, { date }, tokenConfig(getState));
      await refreshEntity(E.PolynomialEntity, undefined, {
        date
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.PolynomialEntity.name, dispatch });
    }
  };

export const massiveLoadProvisionCarriage =
  (data: Array<SimpleObject>, selectedDate: moment.Moment) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/provisionCarriage/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );
      await refreshEntity(E.ProvisionCarriageEntity, undefined, {
        date: selectedDate.format('YYYY-MM-DD')
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ProvisionCarriageEntity.name, dispatch });
    }
  };

export const massiveLoadBudgetCarriage =
  (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    entity: WebEntity<BudgetCarriage>,
    budgetTypeCarriage: BudgetTypeCarriageNames
  ) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/budgetCarriage/massiveLoad`,
        { data, selectedDate, budgetTypeCarriage },
        tokenConfig(getState)
      );
      await refreshEntity(entity, undefined, {
        date: selectedDate.format('YYYY-MM-DD'),
        budgetTypeCarriage
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: entity.name, dispatch });
    }
  };

export const paymentAuthorization =
  ({
    shipmentAuditingCarriageId,
    authorization,
    date
  }: {
    shipmentAuditingCarriageId: string;
    authorization: boolean;
    date: string;
  }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/shipmentCarriage/shipmentAuditingCarriage/authorization`,
        { shipmentAuditingCarriageId, authorization },
        tokenConfig(getState)
      );
      await refreshEntity(E.ShipmentAuditingCarriageQ1Entity, undefined, {
        fortnight: FortnightNumber.FirstFortnight,
        date
      })(dispatch, getState);
      await refreshEntity(E.ShipmentAuditingCarriageQ2Entity, undefined, {
        fortnight: FortnightNumber.SecondFortnight,
        date
      })(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ShipmentAuditingCarriageQ1Entity.name, dispatch });
      return error;
    }
  };

export const massiveLoadTelephoneExpenses =
  (data: Array<SimpleObject>, selectedDate: moment.Moment, query: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/telephoneExpenses/massiveLoad`,
        { data, selectedDate },
        tokenConfig(getState)
      );

      await refreshEntity(E.TelephoneExpensesEntity, undefined, query)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.TelephoneExpensesEntity.name, dispatch });
    }
  };
