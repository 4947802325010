import { ShipmentCarriageFrom4Users } from '../types';
import { dateFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

const ShipmentCarriageFrom4UsersEntity: WebEntity<ShipmentCarriageFrom4Users> = {
  name: 'shipmentCarriageFrom4UsersQ1',
  endpoint: 'shipmentCarriage/shipmentCarriageFrom4Users',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true,
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Clase de transporte',
      accessorKey: 'shipmentClassCarriageIdCcu',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Ruta p.transporte',
      accessorKey: 'timeRouteIdCcu',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Id. 1/OCA',
      accessorKey: 'oca',
      columnType: 'text',
      size: 180
    },
    {
      header: 'Creado Por',
      accessorKey: 'createdBy',
      columnType: 'text',
      size: 180
    },
    {
      header: 'Creado El',
      accessorKey: 'createdDateSource',
      columnType: 'text',
      size: 180,
      Cell: ({ row }) => dateFormatFn(row.original?.createdDateSource, 'DD/MM/YYYY HH:mm:ss', true)
    },
    {
      header: 'Patente/Matricula Vehículo',
      accessorKey: 'vehiclePatent',
      columnType: 'text',
      size: 280
    },
    {
      header: 'Patente/Matricula Rampla',
      accessorKey: 'trailerPatent',
      columnType: 'text',
      size: 280
    },
    {
      header: 'Fin act.transp',
      accessorKey: 'endDateCarriageReport',
      columnType: 'text',
      size: 200,
      Cell: ({ row }) => dateFormatFn(row.original?.createdDateSource, 'DD/MM/YYYY', true)
    },
    {
      header: 'Denom.ruta',
      accessorKey: 'route',
      columnType: 'text',
      size: 180
    },
    {
      header: 'Nombre agente servicios transp.',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 320
    },
    {
      header: 'Tipo Envase/Carga',
      accessorKey: 'chargePackingType',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Modificado Por',
      accessorKey: 'modifiedBy',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Flete',
      accessorKey: 'freight',
      columnType: 'text',
      size: 180,
      Cell: ({ row }) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {row.original?.freight ? 'A pago' : 'No pago'}
          {row.original?.freight ? <CheckIcon /> : <DoNotDisturbAltIcon />}
        </div>
      )
    }
  ],

  editableFields: [
    {
      name: 'Flete',
      selector: 'freight',
      type: FieldTypes.Boolean,
      required: true
    }
  ]
};

export default ShipmentCarriageFrom4UsersEntity;
