import { TariffCarriage } from '../types';
import { formatStringNumber, postgresIntervalFormat, spanishMonthNames } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const TariffCarriageEntity: WebEntity<TariffCarriage> = {
  name: 'tariffCarriage',
  endpoint: 'tariffCarriage',
  referenceColumn: '_id',
  customDataActions: {
    editModalTitle: 'Editar tarifa'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Ruta p.transporte',
      accessorKey: 'timeRouteIdCcu',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Ruta',
      accessorKey: 'route',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Tiempo',
      accessorKey: 'time',
      columnType: 'text',
      size: 150,
      valueToExport: (row) => postgresIntervalFormat(row?.time),
      Cell: ({ row }) => postgresIntervalFormat(row.original?.time)
    },
    {
      header: 'Tipo de Contrato',
      accessorKey: 'carrierTypeCarriageName',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Fecha',
      accessorKey: 'tariffDate',
      columnType: 'dateUTC',
      size: 230,
      accessorFn: (row) =>
        row.tariffDate
          ? `${spanishMonthNames[new Date(row.tariffDate).getUTCMonth()]} ${new Date(
              row.tariffDate
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Tarifa',
      accessorKey: 'tariff',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) =>
        new Date(row.original?.tariffDate).getUTCMonth() <= new Date().getMonth() + 1
          ? formatStringNumber(row?.original?.tariff, 0, 2)
          : ''
    },
    {
      header: 'Tarifa Extra',
      accessorKey: 'tariffExtra',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) =>
        new Date(row.original?.tariffDate).getUTCMonth() <= new Date().getMonth() + 1
          ? formatStringNumber(row?.original?.tariffExtra, 0, 2)
          : ''
    },
    {
      header: 'Tarifa Polinomio',
      accessorKey: 'tariffPolynomial',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) =>
        new Date(row.original?.tariffDate).getUTCMonth() <= new Date().getMonth() + 1
          ? formatStringNumber(row?.original?.tariffPolynomial, 0, 2)
          : ''
    },
    {
      header: 'Tarifa total',
      accessorKey: 'totalTariff',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) =>
        new Date(row.original?.tariffDate).getUTCMonth() <= new Date().getMonth() + 1
          ? formatStringNumber(row?.original?.totalTariff, 0, 2)
          : ''
    },
    {
      header: 'Validez',
      accessorKey: 'tariffValidityStatusName',
      columnType: 'text',
      size: 230,
      Cell: ({ row }) =>
        new Date(row.original?.tariffDate).getUTCMonth() <= new Date().getMonth() + 1
          ? row?.original?.tariffValidityStatusName
          : ''
    }
  ],

  editableFields: [
    {
      name: 'Tarifa Extra',
      selector: 'tariffExtra',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Validez',
      selector: 'tariffValidityStatusId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Validez',
        endpoint: 'tariffCarriage/tariffValidityStatusName',
        select: 'tariffValidityStatusId',
        show: 'tariffValidityStatusName'
      }
    }
  ]
};

export default TariffCarriageEntity;
