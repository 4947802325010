import { PhoneEquipments } from '../types';
import { WebEntity } from './types';

const PhoneEquipmentEntity: WebEntity<PhoneEquipments> = {
  name: 'phoneEquipments',
  endpoint: 'phoneEquipments',
  referenceColumn: 'phoneEquipmentId',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: false
    },
    {
      header: 'Nombre Equipos',
      accessorKey: 'phoneEquipmentName',
      columnType: 'text'
    }
  ]
};

export default PhoneEquipmentEntity;
