import { Fragment, FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { ConnectedComponent, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, MenuItem, Select, SelectChangeEvent, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { SignalCellularAltOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import Swal from 'sweetalert2';
import moment from 'moment';
import FileReaderCSV, { FileReaderColumnProps } from '../components/FileReader';
import GenericInfoTag from '../components/generics/GenericInfoTag';
import GenericModal from '../components/generics/GenericModal';
import {
  BudgetCarriage,
  BudgetTypeCarriageNames,
  FortnightNames,
  FortnightNumber,
  IfacType,
  modules,
  PaymentType,
  SimpleObject,
  UserTypeValues
} from '../types';
import { getUncatchEndpointData } from '../utils/request';
import { dateFormatFn, formatStringNumber, spanishMonthNames } from '../utils/utils';
import { AuthState, EntitiesState } from '../reducers/types';
import GenericTopTableCGFCCU from '../components/GenericTopTableCGFCCU';
import { CardAlertProps } from '../components/CardAlert';
import BackButton from '../components/BackButton';
import { ExtendedColumnDef, WebEntity } from '../entities/types';
import * as E from '../entities';
import { AppState } from '../store';
import {
  DateAndGlobalZoneFiltersConnected,
  DateAndSelectFilter,
  DateFilterWithAction,
  RefreshHfm,
  RefreshVolumen,
  ResumeShipmentCarriageFilter,
  SimulateIFAC
} from '../components/SecondaryButton';
import {
  UsuarioCRUD,
  MapCRUD,
  CostCenterCRUD,
  ZoneCRUD,
  SDBudgetCRUD,
  SDBudgetTypeCRUD,
  DictionaryCcCRUD,
  CategoriesCRUD,
  AreaManagerCRUD,
  ZoneGlobalCRUD,
  SubCostCenterCRUD,
  ExpensesCRUD,
  EstimatedClosingPendingCRUD,
  EstimatedClosingRejectedCRUD,
  EstimatedGlobalClosingCRUD,
  GeneralZoneReportCRUD,
  GeneralReportCRUD,
  ProvisionCRUD,
  ReclassificationCRUD,
  PendingProvisionCRUD,
  RefusedProvisionCRUD,
  PendingReclassificationCRUD,
  RefusedReclassificationCRUD,
  HFMCRUD,
  CarrierCRUD,
  CarrierTypeCRUD,
  GlossCRUD,
  ZonePortingCRUD,
  ConceptCRUD,
  SubConceptCRUD,
  TariffCRUD,
  VolumenCRUD,
  PorteoBudgetTypeCRUD,
  PorteoBudgetCRUD,
  EstimatedClosingCRUD,
  FreightChargesCRUD,
  EquivalentBoxCRUD,
  TimeRouteCRUD,
  IfacCRUD,
  ForeignExchangeCRUD,
  MajorPortingCRUD,
  ForeignExchangeEstimatedCRUD,
  ForeignExchangeResumeCRUD,
  PermanentTruckCRUD,
  CarrierZoneGlossCRUD,
  FreightGlossCRUD,
  ContractorTariffCRUD,
  FreightExtraResumeCRUD,
  FreightExtraCRUD,
  ManagementTypeCRUD,
  CarrierRouteCarriageCRUD,
  CarrierTypeCarriageCRUD,
  TariffCarriageCRUD,
  FreightExtraGlSapCRUD,
  IfacSimulatedCRUD,
  FreightTimeMasterCRUD,
  ExtraCarriageCRUD,
  ShipmentCarriageCRUD,
  ProvisionCarriageCRUD,
  BudgetCarriageCRUD,
  ApprovedReclassificationCRUD,
  ApprovedProvisionCRUD,
  ShipmentAuditingCarriageCRUD,
  ShipmentCarriagePXQCRUD,
  ResumeShipmentCarriageCRUD,
  ShipmentZT08CarriageCRUD,
  ShipmentCarriageEmptyFieldsCRUD,
  NotificationCRUD,
  NotificationTypeCRUD,
  PresupuestoVolumenCRUD,
  ZoneGlobalBudgetVolumeCRUD,
  ZoneBudgetVolumeCRUD,
  DistributionCenterBudgetVolumeCRUD,
  GroupBudgetVolumeCRUD,
  ShipmentCarriageFrom4UsersCRUD
} from '../components/GeneratedComponents';
import {
  mergeUsers,
  mergeBudget,
  mergeAreaManager,
  massiveLoadExpenses,
  mergeEstimatedClosing,
  massiveLoadProvision,
  massiveLoadReclassification,
  massiveLoadGloss,
  massiveLoadTariff,
  massiveLoadFreightCharges,
  massiveLoadEquivalentBox,
  massiveLoadCarrier,
  massiveLoadForeignExchangeEstimated,
  massiveLoadPermanentTruck,
  foreightChargesDateFilter,
  permanentTruckDateFilter,
  massiveLoadCarrierZoneGloss,
  massiveLoadContractorTariff,
  massiveLoadFreightExtra,
  massiveLoadFreightExtraGlSap,
  massiveLoadCarrierRouteCarriage,
  massiveLoadExtraCarriage,
  massiveLoadShipmentCarriage,
  massiveLoadShipmentCarriageEmptyFields,
  shipmentCarriageDateFilter,
  massiveLoadProvisionCarriage,
  massiveLoadBudgetCarriage,
  massiveLoadPresupuestoVolumen,
  shipmentAuditingCarriageDateFilter,
  shipmentZT08CarriageDateFilter,
  shipmentCarriagePXQDateFilter,
  shipmentCarriageFrom4UsersDateFilter
} from '../actions/middleActions';
import { PRESUPUESTO_VOLUMEN_COLUMNS } from '../entities/PresupuestoVolumenEntity';
import { USERS_COLUMNS } from '../entities/UserEntity';
import { BUDGET_COLUMNS } from '../entities/BudgetEntity';
//import { AREAMANAGER_COLUMNS } from '../entities/AreaManager';
import { EXPENSES_COLUMNS } from '../entities/ExpensesEntity';
import { ESTIMATED_CLOSING_COLUMNS } from '../entities/EstimatedClosingEntity';
import { PROVISION_COLUMNS } from '../entities/ProvisionEntity';
import { RECLASSIFICATION_COLUMNS } from '../entities/ReclassificationEntity';
import { GLOSS_COLUMNS } from '../entities/glossEntity';
import { TARIFF_COLUMNS } from '../entities/TariffEntity';
import { FREIGHT_CHARGES_COLUMNS } from '../entities/FreightChargesEntity';
import { EQUIVALENT_BOX_COLUMNS } from '../entities/EquivalentBoxEntity';
import { CARRIER_COLUMNS } from '../entities/Carrier';
import { FOREIGN_EXCHANGE_ESTIMATED_COLUMNS } from '../entities/ForeignExchangeEstimatedEntity';
import { PERMANENT_TRUCK_COLUMNS } from '../entities/PermanentTruckEntity';
import { CARRIER_ZONE_GLOSS_COLUMNS } from '../entities/CarrierZoneGlossEntity';
import { CONTRACTOR_TARIFF_COLUMNS } from '../entities/ContractorTariffEntity';
import { FREIGHT_EXTRA_COLUMNS } from '../entities/FreightExtraEntity';
import { CARRIER_ROUTE_CARRIAGE_COLUMNS } from '../entities/CarrierRouteCarriageEntity';
import { FREIGHT_EXTRA_GL_SAP_COLUMNS } from '../entities/FreightExtraGlSapEntity';
import { EXTRA_CARRIAGE_COLUMNS } from '../entities/ExtraCarriageEntity';
import { SHIPMENT_CARRIAGE_COLUMNS } from '../entities/ShipmentCarriageEntity';
import { SHIPMENT_CARRIAGE_EMPTY_FIELDS_COLUMNS } from '../entities/ShipmentCarriageEmptyFieldsEntity';
import { PROVISION_CARRIAGE_COLUMNS } from '../entities/ProvisionCarriageEntity';
import { BUDGET_CARRIAGE_COLUMNS } from '../entities/BudgetCarriageEntity';
import '../css/freightCharges.css';
import '../css/equivalentBox.css';
import '../css/tariff.css';
import '../css/expenses.css';
import '../css/gloss.css';
import '../css/permanentTruck.css';
import '../css/contractorTariff.css';
import '../css/freightExtra.css';
import '../css/ifacSimulated.css';
import '../css/freightTimeMaster.css';
import '../css/extraCarriage.css';
import '../css/shipmentCarriage.css';

moment.updateLocale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthsShort: [
    'Ene.',
    'Feb.',
    'Mar.',
    'Abr.',
    'May.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dic.'
  ]
});

export type SpecialMaintainerComponentProps = {
  fileReaderCallback: (data: Array<any>, params?: SimpleObject) => any;
  fileReaderColumns: FileReaderColumnProps;
  componentToRender: JSX.Element;
  fileReaderParams?: SimpleObject;
  buttonName?: string;
  title?: string;
  customElement?: JSX.Element;
};

export const SpecialMaintainersComponent: FunctionComponent<SpecialMaintainerComponentProps> = ({
  fileReaderCallback,
  fileReaderColumns,
  componentToRender,
  fileReaderParams,
  buttonName,
  title,
  customElement
}) => {
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    }

    const res = await fileReaderCallback(data, fileReaderParams);

    if (res) {
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 15
      }}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <GenericModal
            title={buttonName}
            buttonName={buttonName ? buttonName : 'PROCESAR ARCHIVO'}
            buttonIcon={<FileUploadIcon />}
            buttonVariant='contained'
            buttonColor='success'
            openHook={() => setCloseModal(false)}
            closeModal={closeModal}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {title && <GenericInfoTag alert={title} alertTitle='Importante!' />}
              <FileReaderCSV columns={fileReaderColumns} callback={uploadFile} />
            </div>
          </GenericModal>
          {customElement}
        </div>
      </div>
      <div
        style={{
          borderRadius: '5px',
          border: ' 2px solid var(--gray-5, #E0E0E0)',
          background: 'var(--blanco, #FFF)',
          boxShadow: '4px 5px 7px 0px #D5D6D7',
          padding: 20,
          width: '100%',
          marginTop: 15
        }}>
        {componentToRender}
      </div>
    </div>
  );
};

const generateConnectedSpecialMaintainersComponent = (fileReaderCallback: any) =>
  connect(({ auth }: AppState) => ({ auth }), {
    fileReaderCallback
  })(SpecialMaintainersComponent);

const UsersMaintainer = generateConnectedSpecialMaintainersComponent(mergeUsers);
const AreaManagerMaintainer = generateConnectedSpecialMaintainersComponent(mergeAreaManager);
const BudgetMaintainer = generateConnectedSpecialMaintainersComponent(mergeBudget);
const EstimatedClosingMaintainer =
  generateConnectedSpecialMaintainersComponent(mergeEstimatedClosing);
const ProvisionMaintainer = generateConnectedSpecialMaintainersComponent(massiveLoadProvision);
const ReclassificationMaintainer = generateConnectedSpecialMaintainersComponent(
  massiveLoadReclassification
);
/* PORTEO */
const CarrierMaintainer = generateConnectedSpecialMaintainersComponent(massiveLoadCarrier);
const CarrierZoneGlossMaintainer = generateConnectedSpecialMaintainersComponent(
  massiveLoadCarrierZoneGloss
);
/* ACARREO */
const CarrierRouteCarriageMaintainer = generateConnectedSpecialMaintainersComponent(
  massiveLoadCarrierRouteCarriage
);

const operationsInfo = (params: SimpleObject) => (
  <div className='operations-info'>
    <p>
      Zona Global: <span>{params.globalZone}</span>
    </p>
    {params.globalZone === 'Centro' && (params.zones || '').split(',').length === 1 ? (
      <p>
        Zona: <span>{params.zones}</span>
      </p>
    ) : params.globalZone === 'Centro' && (params.zones || '').split(',').length > 1 ? (
      <button
        onClick={() =>
          Swal.fire({
            icon: 'info',
            title: 'Zonas',
            html: `<p>${(params.zones || '').split(',').join(' - ')}</p>`
          })
        }>
        Mis Zonas
      </button>
    ) : (params.subCategories || '').split(',').length === 1 ? (
      <p>
        Sub Categoria: <span>{params.subCategories}</span>
      </p>
    ) : (
      <button
        onClick={() =>
          Swal.fire({
            icon: 'info',
            title: 'Sub categorias',
            html: `<p>${(params.subCategories || '').split(',').join(' - ')}</p>`
          })
        }>
        Mis Sub categorias
      </button>
    )}
  </div>
);

export const CategoriasCRUDMaintainer = () => {
  return <CategoriesCRUD />;
};

export const DictionaryCcCRUDMaintainer = () => {
  return <DictionaryCcCRUD />;
};

export const AreaManagerCRUDMaintainer = () => {
  return (
    <AreaManagerCRUD />
    /*
    TODO: Need fix massiveLoad
    <AreaManagerMaintainer
      {...{
        fileReaderColumns: AREAMANAGER_COLUMNS,
        componentToRender: <AreaManagerCRUD />,
        buttonName: 'CARGA MASIVA ASIGNACION DE ZONAS'
      }}></AreaManagerMaintainer>*/
  );
};

export const UsuarioCRUDMaintainer = () => {
  return (
    <UsersMaintainer
      {...{
        fileReaderColumns: USERS_COLUMNS,
        componentToRender: <UsuarioCRUD />,
        buttonName: 'CARGA MASIVA USUARIOS',

        title:
          'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
      }}></UsersMaintainer>
  );
};

export const MapCRUDMaintainer = () => {
  return <MapCRUD />;
};

export const NotificationMaintainer = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={handleChange}
          textColor='inherit'
          TabIndicatorProps={{
            style: {
              backgroundColor: '#64a70b'
            }
          }}>
          <Tab label='Notificaciones' value='1' />
          <Tab label='Tipo Notificaciones' value='2' />
        </TabList>
      </Box>
      <TabPanel value='1'>
        <GenericInfoTag alert='Los usuarios asignados serán copiados (CC) en cada notificación que se realice' />
        <NotificationCRUD />
      </TabPanel>
      <TabPanel value='2'>
        <NotificationTypeCRUD />
      </TabPanel>
    </TabContext>
  );
};

export const CostCenterCRUDMaintainer = () => {
  return <CostCenterCRUD />;
};

export const ZoneCRUDMaintainer = () => {
  return <ZoneCRUD />;
};

export const ZoneGlobalCRUDMaintainer = () => {
  return <ZoneGlobalCRUD />;
};

export const SDBudgetCRUDMaintainer = () => {
  return (
    <BudgetMaintainer
      {...{
        fileReaderColumns: BUDGET_COLUMNS,
        componentToRender: <SDBudgetCRUD />,
        buttonName: 'CARGA MASIVA PRESUPUESTO',
        fileReaderParams: { moduleType: modules.SD.abbreviation },
        title:
          'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
      }}></BudgetMaintainer>
  );
};

export const SDBudgetTypeCRUDMaintainer = () => {
  return <SDBudgetTypeCRUD />;
};

export const SubCostCentersCRUDMaintainer = () => {
  return <SubCostCenterCRUD />;
};

const ExpensesCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadExpenses: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
}> = ({ auth, entities, massiveLoadExpenses }) => {
  const today = useMemo(() => new Date(), []);
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.expenses.list.length);
  }, [auth, entities]);

  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();
  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ExpensesCRUD({
      filters: { date: dateFiltered.format('YYYY-MM-DD') }
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadExpenses(data, selectedDate);

    if (res) {
      setCloseModal(true);
      setDateFiltered(selectedDate);
    } else {
      throw Error;
    }
  };

  const getMinDate = useMemo(() => {
    if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(today.getDate())) {
      // return last month
      // getMonth() returns month -1
      return moment(new Date(`${today.getMonth()}-01-${today.getFullYear()}`));
    }
    // return current Month
    return moment(today);
  }, [today]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Gastos ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  return (
    <div className='expenses-container'>
      <BackButton route={backButtonPathName} />
      <GenericInfoTag
        alert={
          'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
        }
        alertTitle='Importante!'
      />
      <GenericModal
        title={'CARGA MASIVA GASTOS'}
        buttonName={'CARGA MASIVA GASTOS'}
        buttonIcon={<FileUploadIcon />}
        buttonVariant='contained'
        buttonColor='success'
        openHook={() => setCloseModal(false)}
        closeModal={closeModal}>
        <div className='expenses-massive-load-modal-container'>
          <GenericInfoTag
            alert={
              'Solo puedes cargar gastos en el mes actual. El mes anterior solo está disponible los primeros 10 dias del mes actual.'
            }
          />
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                label='Mes'
                views={['year', 'month']}
                openTo='year'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                minDate={getMinDate}
                maxDate={moment(today)}
              />
            </LocalizationProvider>
          </div>
          <FileReaderCSV columns={EXPENSES_COLUMNS} callback={uploadFile} />
        </div>
      </GenericModal>
      <div className='expeneses-crud-container'>
        <div>
          <GenericTopTableCGFCCU
            titleTable={title}
            arrowName={arrowName}
            cardAlerts={[
              {
                title: 'Datos Globales',
                icon: <SignalCellularAltOutlined fontSize='large' />,
                numberInfo: formatStringNumber(globalDataQuantity.toString())
              }
            ]}
          />
        </div>
        <div className='expenses-filter-container'>
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.ExpensesEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const ExpensesCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadExpenses
  }
)(ExpensesCRUDMaintainer);

export const EstimatedClosingCRUDMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    []
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return EstimatedClosingCRUD(params);
  }, []);

  const customElement = operationsInfo(params);

  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      <EstimatedClosingMaintainer
        {...{
          fileReaderColumns: ESTIMATED_CLOSING_COLUMNS,
          componentToRender: <CRUD />,
          fileReaderParams: params,
          buttonName: 'ESTIMADO CIERRE',
          customElement,
          title:
            'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
        }}></EstimatedClosingMaintainer>
    </div>
  );
};
export const EstimatedClosingPendingMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return EstimatedClosingPendingCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      {customElement}
      <CRUD />
    </div>
  );
};

export const EstimatedClosingRejectedMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return EstimatedClosingRejectedCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      {customElement}
      <CRUD />
    </div>
  );
};

export const EstimatedGlobalClosingMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return EstimatedGlobalClosingCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      {customElement}
      <CRUD />
    </div>
  );
};

export const GeneralZoneReportMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return GeneralZoneReportCRUD(params, backButtonPathName);
  }, []);

  const customElement = operationsInfo(params);

  return (
    <Fragment>
      {customElement}
      <CRUD />
    </Fragment>
  );
};

export const GeneralReportMaintainer = () => {
  return <GeneralReportCRUD />;
};

export const ProvisionCRUDMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ProvisionCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      <ProvisionMaintainer
        {...{
          fileReaderColumns: PROVISION_COLUMNS,
          componentToRender: <CRUD />,
          fileReaderParams: params,
          buttonName: 'PROVISIONES',
          customElement,
          title:
            'Para cada provision, se permite un homologo positivo (1 fila) y otro negativo (1 fila). Uno debajo del otro'
        }}></ProvisionMaintainer>
    </div>
  );
};

export const PendingProvisionCRUDMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return PendingProvisionCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      {customElement}
      <CRUD />
    </div>
  );
};

export const RefusedProvisionMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return RefusedProvisionCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      {customElement}
      <CRUD />
    </div>
  );
};

export const ApprovedProvisionMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      <ApprovedProvisionCRUD />
    </div>
  );
};

export const ReclassificationCRUDMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ReclassificationCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      <ReclassificationMaintainer
        {...{
          fileReaderColumns: RECLASSIFICATION_COLUMNS,
          componentToRender: <CRUD />,
          fileReaderParams: params,
          buttonName: 'CARGA MASIVA RECLASIFICACIÓN',
          customElement,
          title: `La columna 'Texto Posicion' es el identificador de reclasificaciones. Se asume que si tienen el mismo texto, pertenecen a la misma.`
        }}></ReclassificationMaintainer>
    </div>
  );
};

export const PendingReclassificationCRUDMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return PendingReclassificationCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      {customElement}
      <CRUD />
    </div>
  );
};

export const RefusedReclassificationCRUDMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return RefusedReclassificationCRUD(params);
  }, []);

  const customElement = operationsInfo(params);
  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      {customElement}
      <CRUD />
    </div>
  );
};

export const ApprovedReclassificationCRUDMaintainer = () => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  const backButtonPathName = useMemo(
    () =>
      `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`,
    [params]
  );

  return (
    <div style={{ padding: '10px' }}>
      <BackButton route={backButtonPathName} />
      <ApprovedReclassificationCRUD />
    </div>
  );
};

const HFMCRUDMaintainer: FunctionComponent<{ auth: AuthState; entities: EntitiesState }> = ({
  auth,
  entities
}) => {
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();
  const backButtonPathName = `/operaciones/${params.optionType}/${params.globalZone}/${params.level}/${params.subCategories}/${params.zones}`;

  const [uen, setUen] = useState<Array<string>>([]);
  useEffect(() => {
    const get = async () => {
      const resUen = await getUncatchEndpointData({ endpoint: 'hfm/distinctHfm' });
      if (resUen && resUen.length > 0) {
        setUen(resUen.map((row: SimpleObject) => row.uen));
      }
    };
    get();
  }, []);

  const customEntity: WebEntity<any> = useMemo(() => {
    return {
      ...E.HFMEntity,
      tableColumns: [
        ...E.HFMEntity.tableColumns,
        ...uen.map((u) => {
          const uenValue = u.replaceAll('.', '_');
          return {
            header: u,
            accessorKey: uenValue,
            id: uenValue,
            columnType: 'numeric',
            valueToExport: (row) => {
              return row[uenValue] !== '' ? Number(row[uenValue]) : '0';
            },
            accessorFn: (row: SimpleObject) =>
              row[uenValue] ? (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                  {formatStringNumber(row[uenValue])}
                </Box>
              ) : (
                ''
              ),
            AggregatedCell: ({ row }: { row: SimpleObject }) => {
              const sum = row.subRows?.reduce(
                (acc: number, subRow: any) =>
                  subRow.original[uenValue] ? acc + Number(subRow.original[uenValue]) : acc,
                0
              );
              return (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    color: `${sum > 0 ? 'success.main' : 'error.main'}`,
                    fontWeight: 'bold'
                  }}>
                  {sum !== 0 ? formatStringNumber(sum.toString()) : ''}
                </Box>
              );
            },
            Footer: ({ table }: { table: any }) => {
              const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
              const total = rows.reduce(
                (acc: number, obj: SimpleObject) =>
                  obj[uenValue] ? acc + Number(obj[uenValue]) : acc,
                0
              );
              return (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontSize: 15,
                    color: `${total > 0 ? 'green' : 'red'}`
                  }}>
                  {formatStringNumber(total.toString())}
                </Box>
              );
            }
          } as ExtendedColumnDef<any>;
        }),
        {
          header: 'Total General',
          accessorKey: 'totalGeneral',
          id: 'totalGeneral',
          columnType: 'numeric',
          valueToExport: (row) => {
            const value = uen.reduce((acc, u) => {
              const uenValue = u.replaceAll('.', '_');
              return row[uenValue] ? acc + Number(row[uenValue]) : acc;
            }, 0);
            return value.toString();
          },
          accessorFn: (row: SimpleObject) => {
            const value = uen.reduce((acc, u) => {
              const uenValue = u.replaceAll('.', '_');
              return row[uenValue] ? acc + Number(row[uenValue]) : acc;
            }, 0);
            return (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                {formatStringNumber(value.toString())}
              </Box>
            );
          },
          AggregatedCell: ({ row }: { row: SimpleObject }) => {
            const visibleColumns = row.getVisibleCells().map((v: SimpleObject) => v.column.id);

            const sum = row.subRows?.reduce((acc: number, subRow: any) => {
              const value = uen.reduce((accV, u) => {
                const uenValue = u.replaceAll('.', '_');
                if (visibleColumns.includes(uenValue)) {
                  return subRow.original[uenValue]
                    ? accV + Number(subRow.original[uenValue])
                    : accV;
                }
                return accV;
              }, 0);
              return acc + Number(value);
            }, 0);

            return (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: `${sum > 0 ? 'success.main' : 'error.main'}`,
                  fontWeight: 'bold'
                }}>
                {formatStringNumber(sum.toString())}
              </Box>
            );
          },
          Footer: ({ table }: { table: any }) => {
            const visibleColumns =
              table.getExpandedRowModel().rows.length > 0
                ? table
                    .getExpandedRowModel()
                    .rows[0].getVisibleCells()
                    .map((v: SimpleObject) => v.column.id)
                : [];
            const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
            const total = rows.reduce((acc: number, obj: SimpleObject) => {
              const value = uen.reduce((acc, u) => {
                const uenValue = u.replaceAll('.', '_');
                if (visibleColumns.includes(uenValue)) {
                  return obj[uenValue] ? acc + Number(obj[uenValue]) : acc;
                }
                return acc;
              }, 0);
              return acc + Number(value);
            }, 0);
            return (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: 15,
                  color: `${total > 0 ? 'green' : 'red'}`
                }}>
                {formatStringNumber(total.toString())}
              </Box>
            );
          }
        }
      ]
    };
  }, [uen]);

  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [ebitda, setEbitda] = useState<number>(0);
  const [cardAlerts, setCardAlerts] = useState<Array<CardAlertProps>>([]);

  useEffect(() => {
    setCardAlerts([
      {
        title: 'Datos Globales',
        icon: <SignalCellularAltOutlined fontSize='large' />,
        numberInfo: formatStringNumber(globalDataQuantity.toString())
      },
      {
        title: 'EBITDA',
        numberInfo: formatStringNumber(ebitda.toString())
      }
    ]);
  }, [globalDataQuantity, ebitda]);

  const omitCategoriesNames: Array<string> = useMemo(
    () => ['Depreciacion Adm Y Ventas', 'No Operacional', 'Impuestos'],
    []
  );

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.hfm.list.length);

    let sum = 0;
    entities.hfm.list.forEach((row: SimpleObject) => {
      if (!omitCategoriesNames.includes(row.categoriesName)) {
        uen.forEach((u) => {
          const uenValue = u.replaceAll('.', '_');
          sum = sum + (row[uenValue] ? Number(row[uenValue]) : 0);
        });
      }
    });
    setEbitda(sum);
  }, [auth, entities, uen, omitCategoriesNames]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return HFMCRUD(customEntity);
  }, [customEntity]);

  return (
    <div>
      <div style={{ padding: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackButton route={backButtonPathName} />
          <RefreshHfm />
        </div>
        <GenericTopTableCGFCCU
          titleTable='Administración HFM'
          arrowName={arrowName}
          cardAlerts={cardAlerts}
        />
      </div>
      <CRUD />
    </div>
  );
};

export const HFMCRUDMaintainerConnected = connect(({ auth, entities }: AppState) => ({
  auth,
  entities
}))(HFMCRUDMaintainer);

export const CarrierCRUDMaintainer = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={handleChange}
          textColor='inherit'
          TabIndicatorProps={{
            style: {
              backgroundColor: '#64a70b'
            }
          }}>
          <Tab label='Transportistas' value='1' />
          <Tab label='Tipo EETT' value='2' />
        </TabList>
      </Box>
      <TabPanel value='1'>
        <CarrierMaintainer
          {...{
            fileReaderColumns: CARRIER_COLUMNS,
            componentToRender: <CarrierCRUD />,
            buttonName: 'CARGA MASIVA TRANSPORTISTAS',
            title:
              'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
          }}></CarrierMaintainer>
      </TabPanel>
      <TabPanel value='2'>
        <ManagementTypeCRUD />
      </TabPanel>
    </TabContext>
  );
};

export const CarrierTypeCRUDMaintainer = () => {
  return <CarrierTypeCRUD />;
};

const GlossCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadGloss: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
}> = ({ auth, entities, massiveLoadGloss }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.gloss.list.length);
  }, [auth, entities]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return GlossCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadGloss(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  return (
    <div className='gloss-maintainer-container'>
      <GenericModal
        title={'CARGA MASIVA GLOSAS'}
        buttonName={'CARGA MASIVA GLOSAS'}
        buttonIcon={<FileUploadIcon />}
        buttonVariant='contained'
        buttonColor='success'
        openHook={() => setCloseModal(false)}
        closeModal={closeModal}>
        <div className='gloss-massive-load-filters-modal-container'>
          <GenericInfoTag
            alert={
              'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
            }
            alertTitle='Importante!'
          />

          <div>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                label='Mes'
                views={['year', 'month']}
                openTo='year'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                maxDate={moment(today)}
              />
            </LocalizationProvider>
          </div>

          <FileReaderCSV columns={GLOSS_COLUMNS} callback={uploadFile} />
        </div>
      </GenericModal>
      <div className='gloss-maintainer-crud-container'>
        <GenericTopTableCGFCCU
          titleTable='Administración Glosas'
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
        <div className='gloss-maintainer-crud-filters'>
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.GlossEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const GlossCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadGloss
  }
)(GlossCRUDMaintainer);

export const ZonePortingCRUDMaintainer = () => {
  return <ZonePortingCRUD />;
};

export const ConceptCRUDMaintainer = () => {
  return <ConceptCRUD />;
};

export const SubConceptCRUDMaintainer = () => {
  return <SubConceptCRUD />;
};

const TariffCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadTariff: (data: Array<SimpleObject>) => any;
}> = ({ auth, entities, massiveLoadTariff }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [closeModal, setCloseModal] = useState<boolean>(false);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.tariff.list.length);
  }, [auth, entities]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return TariffCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    }

    const res = await massiveLoadTariff(data);

    if (res) {
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Administración Tarifas ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  return (
    <div className='tariff-container'>
      <div>
        <GenericModal
          title={'CARGA MASIVA TARIFAS'}
          buttonName={'CARGA MASIVA TARIFAS'}
          buttonIcon={<FileUploadIcon />}
          buttonVariant='contained'
          buttonColor='success'
          openHook={() => setCloseModal(false)}
          closeModal={closeModal}>
          <div>
            <GenericInfoTag
              alert={
                'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
              }
              alertTitle='Importante!'
            />
            <FileReaderCSV columns={TARIFF_COLUMNS} callback={uploadFile} />
          </div>
        </GenericModal>
      </div>
      <div className='tariff-crud-container'>
        <GenericTopTableCGFCCU
          titleTable={title}
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
        <div className='tariff-filter-container'>
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.TariffEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const TariffCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadTariff
  }
)(TariffCRUDMaintainer);

const VolumenCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadPresupuestoVolumen: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
}> = ({ auth, entities, massiveLoadPresupuestoVolumen }) => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantityVolumen, setGlobalDataQuantityVolumen] = useState<number>(0);
  const [globalDataQuantityPresupuestoVolumen, setGlobalDataQuantityPresupuestoVolumen] =
    useState<number>(0);
  const [totalEntregado, setTotalEntregado] = useState<number>(0);
  const [totalFacturado, setTotalFacturado] = useState<number>(0);

  const today = new Date();
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
  }, [auth]);

  useEffect(() => {
    setGlobalDataQuantityPresupuestoVolumen(entities.presupuestoVolumen.list.length);
    setGlobalDataQuantityVolumen(entities.volumen.list.length);
    setTotalEntregado(
      entities.volumen.list.reduce(
        (acc, obj) => (obj.entregado ? acc + Number(obj.entregado) : acc),
        0
      )
    );
    setTotalFacturado(
      entities.volumen.list.reduce(
        (acc, obj) => (obj.facturado ? acc + Number(obj.facturado) : acc),
        0
      )
    );
  }, [entities]);

  const dateToLoad = useMemo(() => {
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    const day = today.getDate();

    if (day <= 5) {
      month -= 1;
      if (month < 0) {
        month = 11;
        year -= 1;
      }
    }
    return new Date(year, month, 1);
  }, []);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return PresupuestoVolumenCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadPresupuestoVolumen(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', gap: '10px' }}>
      <div>
        <BackButton route='/' />
      </div>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Volumen' value='1' />
            <Tab label='Presupuesto Volumen' value='2' />
            <Tab label='Zona Global Presupuesto Volumen' value='3' />
            <Tab label='Zona Presupuesto Volumen' value='4' />
            <Tab label='CD Presupuesto Volumen' value='5' />
            <Tab label='Grupo Presupuesto Volumen' value='6' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <GenericInfoTag
                alert={`Al refrescar se cargará el mes ${
                  spanishMonthNames[dateToLoad.getMonth()]
                } ${dateToLoad.getFullYear()}`}
                alertTitle='Importante!'
              />

              <RefreshVolumen />
            </div>
            <GenericTopTableCGFCCU
              titleTable='Administración de Volumen'
              arrowName={arrowName}
              cardAlerts={[
                {
                  title: 'Datos Globales',
                  icon: <SignalCellularAltOutlined fontSize='large' />,
                  numberInfo: formatStringNumber(globalDataQuantityVolumen.toString())
                },
                {
                  title: 'Total Entregado',
                  numberInfo: formatStringNumber(totalEntregado.toString(), 0, 2)
                },
                {
                  title: 'Total Facturado',
                  numberInfo: formatStringNumber(totalFacturado.toString(), 0, 2)
                }
              ]}
            />
            <VolumenCRUD />
          </div>
        </TabPanel>
        <TabPanel value='2'>
          <div className='gloss-maintainer-container'>
            <GenericModal
              title={'CARGA MASIVA PRESUPUESTO VOLUMEN'}
              buttonName={'CARGA MASIVA PRESUPUESTO VOLUMEN'}
              buttonIcon={<FileUploadIcon />}
              buttonVariant='contained'
              buttonColor='success'
              openHook={() => setCloseModal(false)}
              closeModal={closeModal}>
              <div className='gloss-massive-load-filters-modal-container'>
                <GenericInfoTag
                  alert={
                    'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
                  }
                  alertTitle='Importante!'
                />

                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                    <DatePicker
                      label='Año'
                      views={['year']}
                      openTo='year'
                      value={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      maxDate={moment(today)}
                    />
                  </LocalizationProvider>
                </div>

                <FileReaderCSV columns={PRESUPUESTO_VOLUMEN_COLUMNS} callback={uploadFile} />
              </div>
            </GenericModal>
            <div className='gloss-maintainer-crud-container'>
              <GenericTopTableCGFCCU
                titleTable='Presupuesto de Volumen'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantityPresupuestoVolumen.toString())
                  }
                ]}
              />
              <div className='gloss-maintainer-crud-filters'>
                <DateAndGlobalZoneFiltersConnected
                  webEntity={E.PresupuestoVolumenEntity}
                  viewsDate={['year']}
                  omitZoneFilter={true}
                  dateFiltered={dateFiltered}
                  setDateFiltered={setDateFiltered}
                />
              </div>
              <CRUD />
            </div>
          </div>
        </TabPanel>
        <TabPanel value='3'>
          <ZoneGlobalBudgetVolumeCRUD />
        </TabPanel>
        <TabPanel value='4'>
          <ZoneBudgetVolumeCRUD />
        </TabPanel>
        <TabPanel value='5'>
          <DistributionCenterBudgetVolumeCRUD />
        </TabPanel>
        <TabPanel value='6'>
          <GroupBudgetVolumeCRUD />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export const VolumenCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({
    auth,
    entities
  }),
  { massiveLoadPresupuestoVolumen }
)(VolumenCRUDMaintainer);

export const PorteoBudgetTypeCRUDMaintainer = () => {
  return <PorteoBudgetTypeCRUD />;
};

export const PorteoBudgetCRUDMaintainer = () => {
  return (
    <BudgetMaintainer
      {...{
        fileReaderColumns: BUDGET_COLUMNS,
        componentToRender: <PorteoBudgetCRUD />,
        buttonName: 'CARGA MASIVA PRESUPUESTO',
        fileReaderParams: { moduleType: modules.PORTEO.abbreviation },
        title:
          'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
      }}></BudgetMaintainer>
  );
};

const FreightChargesCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadFreightCharges: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    selectedFortnight: string
  ) => any;
}> = ({ auth, entities, massiveLoadFreightCharges }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [selectedFortnight, setSelectedFortnight] = useState<string>(
    FortnightNumber.FirstFortnight
  );

  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    q1: { globalData: number; pickUpPlant: number; entrepreneurs: number; bids: number };
    q2: { globalData: number; pickUpPlant: number; entrepreneurs: number; bids: number };
  }>({
    q1: { globalData: 0, pickUpPlant: 0, entrepreneurs: 0, bids: 0 },
    q2: { globalData: 0, pickUpPlant: 0, entrepreneurs: 0, bids: 0 }
  });
  const [cardAlerts, setCardAlerts] = useState<{
    q1: Array<CardAlertProps>;
    q2: Array<CardAlertProps>;
  }>({ q1: [], q2: [] });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return FreightChargesCRUD(E.FreightChargesQ1Entity, 'Primera Quincena', {
      fortnight: FortnightNumber.FirstFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return FreightChargesCRUD(E.FreightChargesQ2Entity, 'Segunda Quincena', {
      fortnight: FortnightNumber.SecondFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    } else if (!selectedFortnight) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar una Quincena`
      });
      throw Error;
    }

    const res = await massiveLoadFreightCharges(data, selectedDate, selectedFortnight);

    if (res) {
      setDateFiltered(selectedDate);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Gastos Porteo ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    const q1FreightChargesList = entities.freightChargesQ1.list;
    const q2FreightChargesList = entities.freightChargesQ2.list;

    const pickUpPlant = { q1: 0, q2: 0 };
    const entrepreneurs = { q1: 0, q2: 0 };
    const bids = { q1: 0, q2: 0 };

    q1FreightChargesList.forEach((row) => {
      if (row.carrierTypeName === 'RETIRO EN PLANTA') {
        pickUpPlant.q1 += Number(row.netPrice);
      } else if (row.carrierTypeName === 'LICITADO') {
        bids.q1 += Number(row.netPrice);
      } else if (row.carrierTypeName === 'EMPRENDEDORES') {
        entrepreneurs.q1 += Number(row.netPrice);
      }
    });

    q2FreightChargesList.forEach((row) => {
      if (row.carrierTypeName === 'RETIRO EN PLANTA') {
        pickUpPlant.q2 += Number(row.netPrice);
      } else if (row.carrierTypeName === 'LICITADO') {
        bids.q2 += Number(row.netPrice);
      } else if (row.carrierTypeName === 'EMPRENDEDORES') {
        entrepreneurs.q2 += Number(row.netPrice);
      }
    });

    const globalQuantity = { q1: q1FreightChargesList.length, q2: q2FreightChargesList.length };

    setGlobalDataQuantity({
      q1: {
        globalData: globalQuantity.q1,
        pickUpPlant: pickUpPlant.q1,
        entrepreneurs: entrepreneurs.q1,
        bids: bids.q1
      },
      q2: {
        globalData: globalQuantity.q2,
        pickUpPlant: pickUpPlant.q2,
        entrepreneurs: entrepreneurs.q2,
        bids: bids.q2
      }
    });
  }, [auth, entities]);

  useEffect(() => {
    const q1CardAlert: CardAlertProps[] = [
      {
        title: 'Datos Globales',
        icon: <SignalCellularAltOutlined fontSize='large' />,
        numberInfo: formatStringNumber(globalDataQuantity.q1.globalData.toString())
      },
      {
        title: 'Retiro en planta',
        variant: 'success',
        numberInfo: `$ ${formatStringNumber(globalDataQuantity.q1.pickUpPlant.toString())}`
      },
      {
        title: 'Emprendedores',
        variant: 'success',
        numberInfo: `$ ${formatStringNumber(globalDataQuantity.q1.entrepreneurs.toString())}`
      },
      {
        title: 'Licitados',
        variant: 'success',
        numberInfo: `$ ${formatStringNumber(globalDataQuantity.q1.bids.toString())}`
      }
    ];
    const q2CardAlert: CardAlertProps[] = [
      {
        title: 'Datos Globales',
        icon: <SignalCellularAltOutlined fontSize='large' />,
        numberInfo: formatStringNumber(globalDataQuantity.q2.globalData.toString())
      },
      {
        title: 'Retiro en planta',
        variant: 'success',
        numberInfo: `$ ${formatStringNumber(globalDataQuantity.q2.pickUpPlant.toString())}`
      },
      {
        title: 'Emprendedores',
        variant: 'success',
        numberInfo: `$ ${formatStringNumber(globalDataQuantity.q2.entrepreneurs.toString())}`
      },
      {
        title: 'Licitados',
        variant: 'success',
        numberInfo: `$ ${formatStringNumber(globalDataQuantity.q2.bids.toString())}`
      }
    ];
    setCardAlerts({ q1: q1CardAlert, q2: q2CardAlert });
  }, [globalDataQuantity]);

  const [minDate, setMinDate] = useState<moment.Moment | null>(selectedDate);
  const [maxDate, setMaxDate] = useState<moment.Moment | null>(selectedDate);
  const [fortnightDeadline, setFortnightDeadline] = useState<moment.Moment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getFortnightDeadline = async () => {
      try {
        setLoading(true);
        if (selectedDate) {
          if (selectedFortnight === FortnightNumber.FirstFortnight) {
            const dateMinDate = new Date(selectedDate.year(), selectedDate.month(), 1);
            setMinDate(moment(dateMinDate));
            const resFortnightDeadline = await getUncatchEndpointData({
              endpoint: 'freightCharges/fortnightDeadline',
              query: {
                date: selectedDate.format('YYYY-MM-DD'),
                fortnight: FortnightNumber.SecondFortnight
              }
            });
            if (resFortnightDeadline) {
              const dateFortnightDeadline = moment(
                new Date(dateFormatFn(resFortnightDeadline, 'MM-DD-YYYY', true))
              );
              const dateMaxDate = dateFortnightDeadline.clone().subtract(1, 'days');
              setMaxDate(dateMaxDate);
              setFortnightDeadline(dateFortnightDeadline);
              if (selectedDate > dateMaxDate) {
                setSelectedDate(dateMaxDate);
              }
            } else {
              setFortnightDeadline(null);
              const dateMinDate = moment(new Date(selectedDate.year(), selectedDate.month(), 1));
              const dateMaxDate = moment(
                new Date(selectedDate.year(), selectedDate.month() + 1, 0)
              );
              setMinDate(dateMinDate);
              setMaxDate(dateMaxDate);
            }
          } else {
            const dateMaxDate = new Date(selectedDate.year(), selectedDate.month() + 1, 0);
            setMaxDate(moment(dateMaxDate));
            const resFortnightDeadline = await getUncatchEndpointData({
              endpoint: 'freightCharges/fortnightDeadline',
              query: {
                date: selectedDate.format('YYYY-MM-DD'),
                fortnight: FortnightNumber.FirstFortnight
              }
            });
            if (resFortnightDeadline) {
              const dateFortnightDeadline = moment(
                new Date(dateFormatFn(resFortnightDeadline, 'MM-DD-YYYY', true))
              );
              const dateMinDate = dateFortnightDeadline.clone().add(1, 'days');
              setMinDate(dateMinDate);
              setFortnightDeadline(dateFortnightDeadline);
              if (selectedDate < dateMinDate) {
                setSelectedDate(dateMinDate);
              }
            } else {
              setFortnightDeadline(null);
              const dateMinDate = moment(new Date(selectedDate.year(), selectedDate.month(), 1));
              const dateMaxDate = moment(
                new Date(selectedDate.year(), selectedDate.month() + 1, 0)
              );
              setMinDate(dateMinDate);
              setMaxDate(dateMaxDate);
            }
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getFortnightDeadline();
  }, [selectedDate, selectedFortnight]);

  return (
    <div className='freight-charges-container'>
      <BackButton route='/porteo' />
      <div className='freight-charges-body'>
        <h1 className='freight-charges-title'>{title}</h1>

        <div className='freight-charges-actions'>
          <GenericModal
            title={'CARGA MASIVA GASTOS PORTEO'}
            buttonName={'CARGA MASIVA GASTOS PORTEO'}
            buttonIcon={<FileUploadIcon />}
            buttonVariant='contained'
            buttonColor='success'>
            <div className='freight-charges-massive-load-filters-modal-container'>
              <GenericInfoTag
                alert={
                  'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
                }
                alertTitle='Importante!'
              />
              <div className='freight-charges-massive-load-filters-container'>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                  <DatePicker
                    label='Mes'
                    views={['year', 'month']}
                    openTo='year'
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    loading={loading}
                  />
                </LocalizationProvider>
                <Select
                  id='freight-select'
                  value={selectedFortnight}
                  onChange={(e: SelectChangeEvent) => setSelectedFortnight(e.target.value)}
                  disabled={loading}>
                  <MenuItem value={FortnightNumber.FirstFortnight}>
                    {FortnightNames.firstFortnight}
                  </MenuItem>
                  <MenuItem value={FortnightNumber.SecondFortnight}>
                    {FortnightNames.secondFortnight}
                  </MenuItem>
                </Select>

                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                  <DatePicker
                    label='Corte Quincena'
                    views={['day']}
                    openTo='day'
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    minDate={minDate ? minDate : undefined}
                    maxDate={maxDate ? maxDate : undefined}
                    loading={loading}
                  />
                </LocalizationProvider>
              </div>

              {fortnightDeadline && selectedFortnight && !loading && (
                <p className='freight-charges-fortnight-deadline-msg'>
                  El corte de la{' '}
                  {selectedFortnight === FortnightNumber.FirstFortnight
                    ? FortnightNames.secondFortnight
                    : FortnightNames.firstFortnight}{' '}
                  fue {fortnightDeadline.format('DD-MM-YYYY')}
                </p>
              )}

              <FileReaderCSV
                columns={FREIGHT_CHARGES_COLUMNS}
                callback={uploadFile}
                disabled={!selectedDate || !selectedFortnight || loading}
              />
            </div>
          </GenericModal>
          <DateFilterWithAction
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            action={foreightChargesDateFilter}
          />
        </div>
        <div className='freight-charges-crud-container'>
          <div className='freight-charges-crud'>
            <div className='freight-charges-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={cardAlerts.q1}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='freight-charges-crud'>
            <div className='freight-charges-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={cardAlerts.q2}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const FreightChargesCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadFreightCharges
  }
)(FreightChargesCRUDMaintainer);

const FreightExtraCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadFreightExtra: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
  massiveLoadFreightExtraGlSap: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
}> = ({ auth, entities, massiveLoadFreightExtra, massiveLoadFreightExtraGlSap }) => {
  type ListType = {
    id: string;
    name: string;
  };
  type CarrierListType = {
    carrierId: string;
    carrierName: string;
  };
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    detail: number;
    resume: number;
    glSap: number;
  }>({
    detail: 0,
    resume: 0,
    glSap: 0
  });
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<Array<ListType>>([]);
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const update = async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'freightExtra/distinctCarriers'
      });
      if (res && res.length > 0) {
        res.unshift({ carrierId: 'all', carrierName: 'Todos' });

        const data: Array<ListType> = res.map((row: CarrierListType) => ({
          id: row.carrierId,
          name: row.carrierName
        }));
        setCarrierList(data);
      }
    };
    update();
  }, []);

  const CRUD1: ConnectedComponent<any, any> = useMemo(() => {
    return FreightExtraCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const CRUD2: ConnectedComponent<any, any> = useMemo(() => {
    return FreightExtraResumeCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const CRUD3: ConnectedComponent<any, any> = useMemo(() => {
    return FreightExtraGlSapCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadFreightExtra(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const uploadFileGlSap = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadFreightExtraGlSap(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const titleDetail = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();
    return `Extras Porteo ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  const titleResume = useMemo(() => {
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();
    return `Resumen Extras Porteo ${year}`;
  }, [dateFiltered]);

  const titleGlSap = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();
    return `GL SAP ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity({
      detail: entities.freightExtra.list.length,
      resume: entities.freightExtraResume.list.length,
      glSap: entities.freightExtraGlSap.list.length
    });
  }, [auth, entities]);

  return (
    <div className='freight-extra-container'>
      <div>
        <BackButton route='/porteo' />
      </div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Extras Porteo' value='1' />
            <Tab label='Resumen Extras Porteo' value='2' />
            <Tab label='GL' value='3' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <div className='freight-extra-crud-container'>
            <div>
              <GenericModal
                title={'CARGA MASIVA EXTRAS PORTEO'}
                buttonName={'CARGA MASIVA EXTRAS PORTEO'}
                buttonIcon={<FileUploadIcon />}
                buttonVariant='contained'
                buttonColor='success'
                openHook={() => setCloseModal(false)}
                closeModal={closeModal}>
                <div className='freight-extra-massive-load-filters-modal-container'>
                  <GenericInfoTag
                    alert={
                      'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
                    }
                    alertTitle='Importante!'
                  />

                  <div>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                      <DatePicker
                        label='Mes'
                        views={['year', 'month']}
                        openTo='year'
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        maxDate={moment(today)}
                      />
                    </LocalizationProvider>
                  </div>

                  <FileReaderCSV columns={FREIGHT_EXTRA_COLUMNS} callback={uploadFile} />
                </div>
              </GenericModal>
            </div>
            <GenericTopTableCGFCCU
              titleTable={titleDetail}
              arrowName={arrowName}
              cardAlerts={[
                {
                  title: 'Datos Globales',
                  icon: <SignalCellularAltOutlined fontSize='large' />,
                  numberInfo: formatStringNumber(globalDataQuantity.detail.toString())
                }
              ]}
            />
            <div className='freight-extra-filters'>
              <DateAndGlobalZoneFiltersConnected
                viewsDate={['year', 'month']}
                webEntity={E.FreightExtraEntity}
                dateFiltered={dateFiltered}
                setDateFiltered={setDateFiltered}
                omitZoneFilter={true}
              />
            </div>
            <CRUD1 />
          </div>
        </TabPanel>
        <TabPanel value='2'>
          <div className='freight-extra-crud-container'>
            <GenericTopTableCGFCCU
              titleTable={titleResume}
              arrowName={arrowName}
              cardAlerts={[
                {
                  title: 'Datos Globales',
                  icon: <SignalCellularAltOutlined fontSize='large' />,
                  numberInfo: formatStringNumber(globalDataQuantity.resume.toString())
                }
              ]}
            />
            <div className='freight-extra-filters'>
              <DateAndSelectFilter
                viewsDate={['year']}
                selectLabel='Transportista'
                webEntity={E.FreightExtraResumeEntity}
                dateFiltered={dateFiltered}
                setDateFiltered={setDateFiltered}
                selectList={carrierList}
              />
            </div>
            <CRUD2 />
          </div>
        </TabPanel>
        <TabPanel value='3'>
          <div className='freight-extra-crud-container'>
            <div>
              <GenericModal
                title={'CARGA MASIVA EXTRAS GL SAP'}
                buttonName={'CARGA MASIVA EXTRAS GL SAP'}
                buttonIcon={<FileUploadIcon />}
                buttonVariant='contained'
                buttonColor='success'
                openHook={() => setCloseModal(false)}
                closeModal={closeModal}>
                <div className='freight-extra-massive-load-filters-modal-container'>
                  <GenericInfoTag
                    alert={
                      'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
                    }
                    alertTitle='Importante!'
                  />

                  <div>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                      <DatePicker
                        label='Mes'
                        views={['year', 'month']}
                        openTo='year'
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        maxDate={moment(today)}
                      />
                    </LocalizationProvider>
                  </div>

                  <FileReaderCSV
                    columns={FREIGHT_EXTRA_GL_SAP_COLUMNS}
                    callback={uploadFileGlSap}
                  />
                </div>
              </GenericModal>
            </div>
            <GenericTopTableCGFCCU
              titleTable={titleGlSap}
              arrowName={arrowName}
              cardAlerts={[
                {
                  title: 'Datos Globales',
                  icon: <SignalCellularAltOutlined fontSize='large' />,
                  numberInfo: formatStringNumber(globalDataQuantity.glSap.toString())
                }
              ]}
            />
            <div className='freight-extra-filters'>
              <DateAndGlobalZoneFiltersConnected
                viewsDate={['year', 'month']}
                webEntity={E.FreightExtraGlSapEntity}
                dateFiltered={dateFiltered}
                setDateFiltered={setDateFiltered}
                omitZoneFilter={true}
              />
            </div>
            <CRUD3 />
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export const FreightExtraCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadFreightExtra,
    massiveLoadFreightExtraGlSap
  }
)(FreightExtraCRUDMaintainer);

const EquivalentBoxCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadEquivalentBox: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
}> = ({ auth, entities, massiveLoadEquivalentBox }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.equivalentBox.list.length);
  }, [auth, entities]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return EquivalentBoxCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadEquivalentBox(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Administración Cajas Equivalentes ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  return (
    <div className='equivalent-box-container'>
      <div>
        <GenericModal
          title={'CARGA MASIVA CAJAS EQUIVALENTES'}
          buttonName={'CARGA MASIVA CAJAS EQUIVALENTES'}
          buttonIcon={<FileUploadIcon />}
          buttonVariant='contained'
          buttonColor='success'
          openHook={() => setCloseModal(false)}
          closeModal={closeModal}>
          <div className='equivalent-box-massive-load-filters-modal-container'>
            <GenericInfoTag
              alert={
                'Los registros que esten entre Enero y Junio corresponden al año anterior en el que se carga'
              }
              alertTitle='Importante!'
            />

            <div>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                <DatePicker
                  label='Mes'
                  views={['year', 'month']}
                  openTo='year'
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  maxDate={moment(today)}
                />
              </LocalizationProvider>
            </div>

            <FileReaderCSV columns={EQUIVALENT_BOX_COLUMNS} callback={uploadFile} />
          </div>
        </GenericModal>
      </div>
      <div className='equivalent-box-crud-container'>
        <GenericTopTableCGFCCU
          titleTable={title}
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
        <div className='equivalent-box-filter-container'>
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.EquivalentBoxEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const EquivalentBoxCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadEquivalentBox
  }
)(EquivalentBoxCRUDMaintainer);

export const MajorPortingCRUDMaintainer: FunctionComponent<{
  title: string;
  query: SimpleObject;
}> = ({ title, query }) => {
  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return MajorPortingCRUD(title, query);
  }, [title, query]);

  return <CRUD />;
};

export const TimeRouteCRUDMaintainer = () => <TimeRouteCRUD />;

export const IfacCRUDMaintainer: FunctionComponent<{ title: string; ifacType: IfacType }> = ({
  title,
  ifacType
}) => {
  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return IfacCRUD(title, { ifacType }, ifacType);
  }, [title, ifacType]);

  return <CRUD />;
};

const IfacSimulatedCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [vmValue, setVmValue] = useState<number>(0);
  const [macroeconomicVariables, setMacroeconomicVariables] = useState<number>(0);
  const [uen, setUen] = useState<number>(0);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.ifacSimulated.list.length);
    setTotalAmount(
      entities.ifacSimulated.list.reduce(
        (acc, obj) => (!isNaN(Number(obj.total)) ? acc + Number(obj.total) : acc),
        0
      )
    );
  }, [auth, entities]);

  useEffect(() => {
    const update = async () => {
      const resUen = await getUncatchEndpointData({
        endpoint: 'extraCarriage/totalUEN',
        query: { date: dateFiltered.format('YYYY-MM-DD') }
      });
      if (resUen && resUen.length > 0) {
        setUen(Number(resUen[0].total));
      } else {
        setUen(0);
      }
      const resVM = await getUncatchEndpointData({
        endpoint: 'foreignExchange/getCarriageVM',
        query: { date: dateFiltered.format('YYYY-MM-DD') }
      });
      if (resVM && resVM.length > 0) {
        setVmValue(Number(parseFloat(resVM[0].vmValue).toFixed(3)));
      } else {
        setVmValue(0);
      }
    };
    update();
  }, [entities.ifacSimulated.list, dateFiltered]);

  useEffect(() => {
    setMacroeconomicVariables(totalAmount * vmValue);
  }, [totalAmount, vmValue]);

  const lastDayOfData = useMemo(() => {
    const data = entities.ifacSimulated.list;
    const realData = data.filter((row) => row.type === 'Real');
    if (realData.length === 0) {
      return undefined;
    }
    const mostFutureReal = realData.reduce((acc, obj) => {
      return new Date(dateFormatFn(obj.date, 'MM-DD-YYYY', true)) >
        new Date(dateFormatFn(acc.date, 'MM-DD-YYYY', true))
        ? obj
        : acc;
    });
    return moment(new Date(dateFormatFn(mostFutureReal.date, 'MM-DD-YYYY', true)));
  }, [entities.ifacSimulated.list]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return IfacSimulatedCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Simulación IFAC ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  return (
    <div className='ifac-simulated-container'>
      <div className='ifac-simulated-crud-container'>
        <GenericTopTableCGFCCU
          titleTable={title}
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            },
            {
              title: `Variables Macros ${formatStringNumber((vmValue * 100).toString(), 0, 2)}%`,
              numberInfo: formatStringNumber(macroeconomicVariables.toString(), 0, 0)
            },
            {
              title: 'Cobro UEN',
              numberInfo: formatStringNumber(uen.toString(), 0, 0)
            },
            {
              title: 'Total Ingreso Acarreo',
              numberInfo: formatStringNumber(
                (totalAmount + macroeconomicVariables + uen).toString(),
                0,
                0
              )
            }
          ]}
        />
        <div className='ifac-simulated-filter-container'>
          <SimulateIFAC
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            existData={entities.ifacSimulated.list.length > 0}
            minDate={
              lastDayOfData ? lastDayOfData.add(1, 'days') : dateFiltered.clone().startOf('month')
            }
            maxDate={dateFiltered.clone().endOf('month')}
          />

          <DateAndGlobalZoneFiltersConnected
            webEntity={E.IfacSimulatedEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const IfacSimulatedCRUDMaintainerConnected = connect(({ auth, entities }: AppState) => ({
  auth,
  entities
}))(IfacSimulatedCRUDMaintainer);

const FreightTimeMasterCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.freightTimeMaster.list.length);
  }, [auth, entities]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return FreightTimeMasterCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Calendario ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  return (
    <div className='freight-time-master-container'>
      <div className='freight-time-master-crud-container'>
        <GenericTopTableCGFCCU
          titleTable={title}
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
        <div className='freight-time-master-filter-container'>
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.FreightTimeMasterEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const FreightTimeMasterCRUDMaintainerConnected = connect(({ auth, entities }: AppState) => ({
  auth,
  entities
}))(FreightTimeMasterCRUDMaintainer);

export const ForeignExchangeCRUDMaintainer = () => <ForeignExchangeCRUD />;

const ForeignExchangeEstimatedCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadForeignExchangeEstimated: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment
  ) => any;
}> = ({ auth, entities, massiveLoadForeignExchangeEstimated }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.foreignExchangeEstimated.list.length);
  }, [auth, entities]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ForeignExchangeEstimatedCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Año`
      });
      throw Error;
    }

    const res = await massiveLoadForeignExchangeEstimated(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  return (
    <div className='foreign-exchange-estimated-container'>
      <GenericModal
        title={'CARGA MASIVA VARIABLES MACROS PRESUPUESTO'}
        buttonName={'CARGA MASIVA VARIABLES MACROS PRESUPUESTO'}
        buttonIcon={<FileUploadIcon />}
        buttonVariant='contained'
        buttonColor='success'
        openHook={() => setCloseModal(false)}
        closeModal={closeModal}>
        <div className='foreign-exchange-estimated-massive-load-filters-modal-container'>
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                label='Año'
                views={['year']}
                openTo='year'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                maxDate={moment(today)}
              />
            </LocalizationProvider>
          </div>

          <FileReaderCSV columns={FOREIGN_EXCHANGE_ESTIMATED_COLUMNS} callback={uploadFile} />
        </div>
      </GenericModal>
      <div className='foreign-exchange-estimated-crud-container'>
        <GenericTopTableCGFCCU
          titleTable='Presupuesto'
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
        <div className='foreign-exchange-estimated-filter-container'>
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.ForeignExchangeEstimatedEntity}
            viewsDate={['year']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const ForeignExchangeEstimatedCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadForeignExchangeEstimated
  }
)(ForeignExchangeEstimatedCRUDMaintainer);

export const ForeignExchangeResumeCRUDMaintainer: FunctionComponent<{
  additionalCardAlerts: CardAlertProps[];
}> = ({ additionalCardAlerts }) => {
  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ForeignExchangeResumeCRUD(additionalCardAlerts);
  }, [additionalCardAlerts]);

  return <CRUD />;
};

const PermanentTruckCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadPermanentTruck: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    selectedFortnight: string
  ) => any;
}> = ({ auth, entities, massiveLoadPermanentTruck }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [selectedFortnight, setSelectedFortnight] = useState<string>(
    FortnightNumber.FirstFortnight
  );
  const [arrowName, setArrowName] = useState<string>('');
  const [cardAlerts, setCardAlerts] = useState<{
    q1: Array<CardAlertProps>;
    q2: Array<CardAlertProps>;
  }>({ q1: [], q2: [] });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return PermanentTruckCRUD(E.PermanentTruckQ1Entity, 'Primera Quincena', {
      fortnight: FortnightNumber.FirstFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return PermanentTruckCRUD(E.PermanentTruckQ2Entity, 'Segunda Quincena', {
      fortnight: FortnightNumber.SecondFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    } else if (!selectedFortnight) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar una Quincena`
      });
      throw Error;
    }

    const res = await massiveLoadPermanentTruck(data, selectedDate, selectedFortnight);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Camiones Fijos ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
  }, [auth]);

  useEffect(() => {
    const q1CardAlert: CardAlertProps[] = [
      {
        title: 'Datos Globales',
        icon: <SignalCellularAltOutlined fontSize='large' />,
        numberInfo: formatStringNumber(entities.permanentTruckQ1.list.length.toString())
      }
    ];
    const q2CardAlert: CardAlertProps[] = [
      {
        title: 'Datos Globales',
        icon: <SignalCellularAltOutlined fontSize='large' />,
        numberInfo: formatStringNumber(entities.permanentTruckQ2.list.length.toString())
      }
    ];
    setCardAlerts({ q1: q1CardAlert, q2: q2CardAlert });
  }, [entities]);

  return (
    <div className='permanent-truck-container'>
      <div className='permanent-truck-body'>
        <h1 className='permanent-truck-title'>{title}</h1>

        <div className='permanent-truck-actions'>
          <GenericModal
            title={'CARGA MASIVA CAMIONES FIJOS'}
            buttonName={'CARGA MASIVA CAMIONES FIJOS'}
            buttonIcon={<FileUploadIcon />}
            buttonVariant='contained'
            buttonColor='success'
            openHook={() => setCloseModal(false)}
            closeModal={closeModal}>
            <div className='permanent-truck-massive-load-filters-modal-container'>
              <GenericInfoTag
                alert={
                  'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
                }
                alertTitle='Importante!'
              />

              <div className='permanent-truck-massive-load-filters-container'>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                  <DatePicker
                    label='Mes'
                    views={['year', 'month']}
                    openTo='year'
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    maxDate={moment(today)}
                  />
                </LocalizationProvider>
                <Select
                  id='permanent-truck-select'
                  value={selectedFortnight}
                  onChange={(e: SelectChangeEvent) => setSelectedFortnight(e.target.value)}>
                  <MenuItem value={FortnightNumber.FirstFortnight}>
                    {FortnightNames.firstFortnight}
                  </MenuItem>
                  <MenuItem value={FortnightNumber.SecondFortnight}>
                    {FortnightNames.secondFortnight}
                  </MenuItem>
                  <MenuItem value={FortnightNumber.FullMonth}>{FortnightNames.fullMonth}</MenuItem>
                </Select>
              </div>

              <FileReaderCSV columns={PERMANENT_TRUCK_COLUMNS} callback={uploadFile} />
            </div>
          </GenericModal>
          <DateFilterWithAction
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            action={permanentTruckDateFilter}
          />
        </div>
        <div className='permanent-truck-crud-container'>
          <div className='permanent-truck-crud'>
            <div className='permanent-truck-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={cardAlerts.q1}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='permanent-truck-crud'>
            <div className='permanent-truck-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={cardAlerts.q2}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PermanentTruckCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadPermanentTruck
  }
)(PermanentTruckCRUDMaintainer);

export const CarrierZoneGlossCRUDMaintainer: FunctionComponent = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={handleChange}
          textColor='inherit'
          TabIndicatorProps={{
            style: {
              backgroundColor: '#64a70b'
            }
          }}>
          <Tab label='Glosa x Retención' value='1' />
          <Tab label='Glosas Porteo' value='2' />
        </TabList>
      </Box>
      <TabPanel value='1'>
        <CarrierZoneGlossMaintainer
          {...{
            fileReaderColumns: CARRIER_ZONE_GLOSS_COLUMNS,
            componentToRender: <CarrierZoneGlossCRUD />,
            buttonName: 'CARGA MASIVA GLOSA X RETENCION',
            title:
              'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
          }}></CarrierZoneGlossMaintainer>
      </TabPanel>
      <TabPanel value='2'>
        <FreightGlossCRUD />
      </TabPanel>
    </TabContext>
  );
};

const ContractorTariffCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadContractorTariff: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
}> = ({ auth, entities, massiveLoadContractorTariff }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ContractorTariffCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadContractorTariff(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `NS Emprendedores ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.contractorTariff.list.length);
  }, [auth, entities]);

  return (
    <div className='contractor-tariff-container'>
      <GenericModal
        title={'CARGA MASIVA NS EMPRENDEDORES'}
        buttonName={'CARGA MASIVA NS EMPRENDEDORES'}
        buttonIcon={<FileUploadIcon />}
        buttonVariant='contained'
        buttonColor='success'
        openHook={() => setCloseModal(false)}
        closeModal={closeModal}>
        <div className='contractor-tariff-massive-load-filters-modal-container'>
          <GenericInfoTag
            alert={
              'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
            }
            alertTitle='Importante!'
          />
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                label='Mes'
                views={['year', 'month']}
                openTo='year'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                maxDate={moment(today)}
              />
            </LocalizationProvider>
          </div>

          <FileReaderCSV columns={CONTRACTOR_TARIFF_COLUMNS} callback={uploadFile} />
        </div>
      </GenericModal>
      <div className='contractor-tariff-crud-container'>
        <GenericTopTableCGFCCU
          titleTable={title}
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
        <div className='contractor-tariff-filter-container'>
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.ContractorTariffEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const ContractorTariffCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadContractorTariff
  }
)(ContractorTariffCRUDMaintainer);

export const CarrierRouteCarriageCRUDMaintainer: FunctionComponent = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={handleChange}
          textColor='inherit'
          TabIndicatorProps={{
            style: {
              backgroundColor: '#64a70b'
            }
          }}>
          <Tab label='Tarifas Transportistas' value='1' />
          <Tab label='Detalles Tarifas Transportistas' value='2' />
          <Tab label='Tipo Transportista' value='3' />
        </TabList>
      </Box>
      <TabPanel value='1'>
        <CarrierRouteCarriageMaintainer
          {...{
            fileReaderColumns: CARRIER_ROUTE_CARRIAGE_COLUMNS,
            componentToRender: <CarrierRouteCarriageCRUD />,
            buttonName: 'CARGA MASIVA Tarifas transportistas',
            title:
              'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
          }}></CarrierRouteCarriageMaintainer>
      </TabPanel>
      <TabPanel value='2'>
        <TariffCarriageCRUD />
      </TabPanel>
      <TabPanel value='3'>
        <CarrierTypeCarriageCRUD />
      </TabPanel>
    </TabContext>
  );
};

const ExtraCarriageCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadExtraCarriage: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
}> = ({ auth, entities, massiveLoadExtraCarriage }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.extraCarriage.list.length);
  }, [auth, entities]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ExtraCarriageCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadExtraCarriage(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Extras Acarreo ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  return (
    <div className='extra-carriage-container'>
      <div>
        <BackButton route='/acarreo' />
      </div>
      <GenericModal
        title={'CARGA MASIVA EXTRAS ACARREO'}
        buttonName={'CARGA MASIVA EXTRAS ACARREO'}
        buttonIcon={<FileUploadIcon />}
        buttonVariant='contained'
        buttonColor='success'
        openHook={() => setCloseModal(false)}
        closeModal={closeModal}>
        <div className='extra-carriage-massive-load-filters-modal-container'>
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                label='Mes'
                views={['year', 'month']}
                openTo='year'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                maxDate={moment(today)}
              />
            </LocalizationProvider>
          </div>

          <FileReaderCSV columns={EXTRA_CARRIAGE_COLUMNS} callback={uploadFile} />
        </div>
      </GenericModal>
      <div className='extra-carriage-crud-container'>
        <GenericTopTableCGFCCU
          titleTable={title}
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
        <div className='extra-carriage-filter-container'>
          <DateAndGlobalZoneFiltersConnected
            webEntity={E.ExtraCarriageEntity}
            viewsDate={['year', 'month']}
            omitZoneFilter={true}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const ExtraCarriageCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadExtraCarriage
  }
)(ExtraCarriageCRUDMaintainer);

const ShipmentCarriageCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadShipmentCarriage: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    selectedFortnight: string
  ) => any;
}> = ({ auth, entities, massiveLoadShipmentCarriage }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [selectedFortnight, setSelectedFortnight] = useState<string>(
    FortnightNumber.FirstFortnight
  );
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    q1: number;
    q2: number;
  }>({
    q1: 0,
    q2: 0
  });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentCarriageCRUD(E.ShipmentCarriageQ1Entity, 'Primera Quincena', {
      fortnight: FortnightNumber.FirstFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentCarriageCRUD(E.ShipmentCarriageQ2Entity, 'Segunda Quincena', {
      fortnight: FortnightNumber.SecondFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    } else if (!selectedFortnight) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar una Quincena`
      });
      throw Error;
    }

    const res = await massiveLoadShipmentCarriage(data, selectedDate, selectedFortnight);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Flete Acarreo ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity({
      q1: entities.shipmentCarriageQ1.list.length,
      q2: entities.shipmentCarriageQ2.list.length
    });
  }, [auth, entities]);

  return (
    <div className='shipment-carriage-container'>
      <div className='shipment-carriage-body'>
        <h1 className='shipment-carriage-title'>{title}</h1>
        <div className='shipment-carriage-actions'>
          <GenericModal
            title={'CARGA MASIVA FLETE ACARREO'}
            buttonName={'CARGA MASIVA FLETE ACARREO'}
            buttonIcon={<FileUploadIcon />}
            buttonVariant='contained'
            buttonColor='success'
            openHook={() => setCloseModal(false)}
            closeModal={closeModal}>
            <div className='shipment-carriage-massive-load-filters-modal-container'>
              <GenericInfoTag
                alert={
                  'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
                }
                alertTitle='Importante!'
              />
              <div className='shipment-carriage-massive-load-filters-container'>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                  <DatePicker
                    label='Mes'
                    views={['year', 'month']}
                    openTo='year'
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    maxDate={moment(today)}
                  />
                </LocalizationProvider>
                <Select
                  id='freight-select'
                  value={selectedFortnight}
                  onChange={(e: SelectChangeEvent) => setSelectedFortnight(e.target.value)}>
                  <MenuItem value={FortnightNumber.FirstFortnight}>
                    {FortnightNames.firstFortnight}
                  </MenuItem>
                  <MenuItem value={FortnightNumber.SecondFortnight}>
                    {FortnightNames.secondFortnight}
                  </MenuItem>
                </Select>
              </div>

              <FileReaderCSV columns={SHIPMENT_CARRIAGE_COLUMNS} callback={uploadFile} />
            </div>
          </GenericModal>
          <DateFilterWithAction
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            action={shipmentCarriageDateFilter}
          />
        </div>
        <div className='shipment-carriage-crud-container'>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q1.toString())
                  }
                ]}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q2.toString())
                  }
                ]}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShipmentCarriageCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadShipmentCarriage
  }
)(ShipmentCarriageCRUDMaintainer);

const ShipmentAuditingCarriageCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    q1: number;
    q2: number;
  }>({
    q1: 0,
    q2: 0
  });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentAuditingCarriageCRUD(E.ShipmentAuditingCarriageQ1Entity, 'Primera Quincena', {
      fortnight: FortnightNumber.FirstFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentAuditingCarriageCRUD(E.ShipmentAuditingCarriageQ2Entity, 'Segunda Quincena', {
      fortnight: FortnightNumber.SecondFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Inconsistencias Flete Acarreo ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity({
      q1: entities.shipmentAuditingCarriageQ1.list.length,
      q2: entities.shipmentAuditingCarriageQ2.list.length
    });
  }, [auth, entities]);

  return (
    <div className='shipment-carriage-container'>
      <div className='shipment-carriage-body'>
        <h1 className='shipment-carriage-title'>{title}</h1>
        <div className='shipment-carriage-filter-container'>
          <DateFilterWithAction
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            action={shipmentAuditingCarriageDateFilter}
          />
        </div>
        <div className='shipment-carriage-crud-container'>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q1.toString())
                  }
                ]}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q2.toString())
                  }
                ]}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShipmentAuditingCarriageCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities })
)(ShipmentAuditingCarriageCRUDMaintainer);

const ShipmentCarriagePXQCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  carrierTypeCarriage: string;
}> = ({ auth, entities, carrierTypeCarriage }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    q1: number;
    q2: number;
  }>({
    q1: 0,
    q2: 0
  });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentCarriagePXQCRUD(E.ShipmentCarriagePXQQ1Entity, 'Primera Quincena', {
      fortnight: FortnightNumber.FirstFortnight,
      date: dateFiltered.format('YYYY-MM-DD'),
      carrierTypeCarriage
    });
  }, [dateFiltered, carrierTypeCarriage]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentCarriagePXQCRUD(E.ShipmentCarriagePXQQ2Entity, 'Segunda Quincena', {
      fortnight: FortnightNumber.SecondFortnight,
      date: dateFiltered.format('YYYY-MM-DD'),
      carrierTypeCarriage
    });
  }, [dateFiltered, carrierTypeCarriage]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `PXQ ${carrierTypeCarriage} ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered, carrierTypeCarriage]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity({
      q1: entities.shipmentCarriagePXQQ1.list.length,
      q2: entities.shipmentCarriagePXQQ2.list.length
    });
  }, [auth, entities]);

  return (
    <div className='shipment-carriage-container'>
      <div className='shipment-carriage-body'>
        <h1 className='shipment-carriage-title'>{title}</h1>
        <div className='shipment-carriage-filter-container'>
          <DateFilterWithAction
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            action={shipmentCarriagePXQDateFilter}
            additionalParams={{ carrierTypeCarriage }}
          />
        </div>
        <div className='shipment-carriage-crud-container'>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q1.toString())
                  }
                ]}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q2.toString())
                  }
                ]}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShipmentCarriagePXQCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities })
)(ShipmentCarriagePXQCRUDMaintainer);

const ResumeShipmentCarriageCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>(PaymentType.all);
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    q1: number;
    q2: number;
  }>({
    q1: 0,
    q2: 0
  });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ResumeShipmentCarriageCRUD(E.ResumeShipmentCarriageQ1Entity, 'Primera Quincena', {
      fortnight: FortnightNumber.FirstFortnight,
      date: dateFiltered.format('YYYY-MM-DD'),
      paymentType: selectedPaymentType
    });
  }, [dateFiltered]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ResumeShipmentCarriageCRUD(E.ResumeShipmentCarriageQ2Entity, 'Segunda Quincena', {
      fortnight: FortnightNumber.SecondFortnight,
      date: dateFiltered.format('YYYY-MM-DD'),
      paymentType: selectedPaymentType
    });
  }, [dateFiltered]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Resumen General ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity({
      q1: entities.resumeShipmentCarriageQ1.list.length,
      q2: entities.resumeShipmentCarriageQ2.list.length
    });
  }, [auth, entities]);

  return (
    <div className='shipment-carriage-container'>
      <div className='shipment-carriage-body'>
        <h1 className='shipment-carriage-title'>{title}</h1>
        <div className='shipment-carriage-filter-container'>
          <ResumeShipmentCarriageFilter
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            paymentTypeFiltered={selectedPaymentType}
            setPaymentTypeFiltered={setSelectedPaymentType}
          />
        </div>
        <div className='shipment-carriage-crud-container'>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q1.toString())
                  }
                ]}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q2.toString())
                  }
                ]}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ResumeShipmentCarriageCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities })
)(ResumeShipmentCarriageCRUDMaintainer);

const ShipmentCarriageFrom4UsersCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    q1: number;
    q2: number;
  }>({
    q1: 0,
    q2: 0
  });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentCarriageFrom4UsersCRUD(
      E.ShipmentCarriageFrom4UsersQ1Entity,
      'Primera Quincena',
      {
        fortnight: FortnightNumber.FirstFortnight,
        date: dateFiltered.format('YYYY-MM-DD')
      }
    );
  }, [dateFiltered]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentCarriageFrom4UsersCRUD(
      E.ShipmentCarriageFrom4UsersQ2Entity,
      'Segunda Quincena',
      {
        fortnight: FortnightNumber.SecondFortnight,
        date: dateFiltered.format('YYYY-MM-DD')
      }
    );
  }, [dateFiltered]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Auditoria ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity({
      q1: entities.shipmentCarriageFrom4UsersQ1.list.length,
      q2: entities.shipmentCarriageFrom4UsersQ2.list.length
    });
  }, [auth, entities]);

  return (
    <div className='shipment-carriage-container'>
      <div className='shipment-carriage-body'>
        <h1 className='shipment-carriage-title'>{title}</h1>
        <div className='shipment-carriage-filter-container'>
          <DateFilterWithAction
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            action={shipmentCarriageFrom4UsersDateFilter}
          />
        </div>
        <div className='shipment-carriage-crud-container'>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q1.toString())
                  }
                ]}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q2.toString())
                  }
                ]}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShipmentCarriageFrom4UsersCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities })
)(ShipmentCarriageFrom4UsersCRUDMaintainer);

const ProvisionCarriageCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadProvisionCarriage: (data: Array<SimpleObject>, selectedDate: moment.Moment) => any;
}> = ({ auth, entities, massiveLoadProvisionCarriage }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ProvisionCarriageCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    }

    const res = await massiveLoadProvisionCarriage(data, selectedDate);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Provision ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity(entities.provisionCarriage.list.length);
  }, [auth, entities]);

  return (
    <div className='provision-carriage-container'>
      <div className='provision-carriage-actions'>
        <GenericModal
          title={'CARGA MASIVA PROVISION'}
          buttonName={'CARGA MASIVA PROVISION'}
          buttonIcon={<FileUploadIcon />}
          buttonVariant='contained'
          buttonColor='success'
          openHook={() => setCloseModal(false)}
          closeModal={closeModal}>
          <div className='provision-carriage-massive-load-filters-modal-container'>
            <GenericInfoTag
              alert={
                'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
              }
              alertTitle='Importante!'
            />
            <div>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                <DatePicker
                  label='Mes'
                  views={['year', 'month']}
                  openTo='year'
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  maxDate={moment(today)}
                />
              </LocalizationProvider>
            </div>

            <FileReaderCSV columns={PROVISION_CARRIAGE_COLUMNS} callback={uploadFile} />
          </div>
        </GenericModal>
      </div>
      <div className='provision-carriage-crud-container'>
        <div className='provision-carriage-crud'>
          <div className='provision-carriage-crud-top-table'>
            <GenericTopTableCGFCCU
              titleTable={title}
              arrowName={arrowName}
              cardAlerts={[
                {
                  title: 'Datos Globales',
                  icon: <SignalCellularAltOutlined fontSize='large' />,
                  numberInfo: formatStringNumber(globalDataQuantity.toString())
                }
              ]}
            />
          </div>
          <div className='provision-carriage-crud-filter-container'>
            <DateAndGlobalZoneFiltersConnected
              webEntity={E.ProvisionCarriageEntity}
              viewsDate={['year']}
              omitZoneFilter={true}
              dateFiltered={dateFiltered}
              setDateFiltered={setDateFiltered}
            />
          </div>
          <CRUD />
        </div>
      </div>
    </div>
  );
};

export const ProvisionCarriageCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadProvisionCarriage
  }
)(ProvisionCarriageCRUDMaintainer);

const BudgetCarriageCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadBudgetCarriage: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    entity: WebEntity<BudgetCarriage>,
    budgetTypeCarriage: BudgetTypeCarriageNames
  ) => any;
  entity: WebEntity<BudgetCarriage>;
  budgetTypeCarriage: BudgetTypeCarriageNames;
}> = ({ auth, entities, massiveLoadBudgetCarriage, entity, budgetTypeCarriage }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return BudgetCarriageCRUD(entity, {
      date: dateFiltered.format('YYYY-MM-DD'),
      budgetTypeCarriage
    });
  }, [entity, dateFiltered, budgetTypeCarriage]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Año`
      });
      throw Error;
    }

    const res = await massiveLoadBudgetCarriage(data, selectedDate, entity, budgetTypeCarriage);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    const tableName =
      budgetTypeCarriage === BudgetTypeCarriageNames.pptoGastoAcarreo
        ? 'Presupuesto Gasto Acarreo'
        : budgetTypeCarriage === BudgetTypeCarriageNames.pptoGastoRepro
        ? 'Presupuesto Gasto Repro'
        : budgetTypeCarriage === BudgetTypeCarriageNames.pptoIngresoAcarreo
        ? 'Presupuesto Ingreso Acarreo'
        : budgetTypeCarriage === BudgetTypeCarriageNames.volumenRepro
        ? 'Volumen Repro'
        : '';

    return `${tableName} ${year}`;
  }, [dateFiltered, budgetTypeCarriage]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity(entities[entity.name].list.length);
  }, [auth, entities, entity]);

  return (
    <div className='provision-carriage-container'>
      <div className='provision-carriage-actions'>
        <GenericModal
          title={'CARGA MASIVA'}
          buttonName={'CARGA MASIVA'}
          buttonIcon={<FileUploadIcon />}
          buttonVariant='contained'
          buttonColor='success'
          openHook={() => setCloseModal(false)}
          closeModal={closeModal}>
          <div className='provision-carriage-massive-load-filters-modal-container'>
            <GenericInfoTag
              alert={
                'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
              }
              alertTitle='Importante!'
            />
            <div>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                <DatePicker
                  label='Año'
                  views={['year']}
                  openTo='year'
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  maxDate={moment(today)}
                />
              </LocalizationProvider>
            </div>

            <FileReaderCSV columns={BUDGET_CARRIAGE_COLUMNS} callback={uploadFile} />
          </div>
        </GenericModal>
      </div>
      <div className='provision-carriage-crud-container'>
        <div className='provision-carriage-crud'>
          <div className='provision-carriage-crud-top-table'>
            <GenericTopTableCGFCCU
              titleTable={title}
              arrowName={arrowName}
              cardAlerts={[
                {
                  title: 'Datos Globales',
                  icon: <SignalCellularAltOutlined fontSize='large' />,
                  numberInfo: formatStringNumber(globalDataQuantity.toString())
                }
              ]}
            />
          </div>
          <div className='provision-carriage-crud-filter-container'>
            <DateAndGlobalZoneFiltersConnected
              webEntity={entity}
              viewsDate={['year']}
              omitZoneFilter={true}
              dateFiltered={dateFiltered}
              setDateFiltered={setDateFiltered}
              additionalParams={{ budgetTypeCarriage }}
            />
          </div>
          <CRUD />
        </div>
      </div>
    </div>
  );
};

export const BudgetCarriageCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadBudgetCarriage
  }
)(BudgetCarriageCRUDMaintainer);

const ShipmentZT08CarriageCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    q1: number;
    q2: number;
  }>({
    q1: 0,
    q2: 0
  });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentZT08CarriageCRUD(E.ShipmentZT08CarriageQ1Entity, 'Primera Quincena', {
      fortnight: FortnightNumber.FirstFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentZT08CarriageCRUD(E.ShipmentZT08CarriageQ2Entity, 'Segunda Quincena', {
      fortnight: FortnightNumber.SecondFortnight,
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `ZT08 ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity({
      q1: entities.shipmentZT08CarriageQ1.list.length,
      q2: entities.shipmentZT08CarriageQ2.list.length
    });
  }, [auth, entities]);

  return (
    <div className='shipment-carriage-container'>
      <div className='shipment-carriage-body'>
        <h1 className='shipment-carriage-title'>{title}</h1>
        <div className='shipment-carriage-filter-container'>
          <DateFilterWithAction
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            action={shipmentZT08CarriageDateFilter}
          />
        </div>
        <div className='shipment-carriage-crud-container'>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q1.toString())
                  }
                ]}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q2.toString())
                  }
                ]}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShipmentZT08CarriageCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities })
)(ShipmentZT08CarriageCRUDMaintainer);

const ShipmentCarriageEmptyFieldsCRUDMaintainer: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
  massiveLoadShipmentCarriageEmptyFields: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    selectedFortnight: string
  ) => any;
}> = ({ auth, entities, massiveLoadShipmentCarriageEmptyFields }) => {
  const today = new Date();
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(dateFiltered);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [selectedFortnight, setSelectedFortnight] = useState<string>(
    FortnightNumber.FirstFortnight
  );
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<{
    q1: number;
    q2: number;
  }>({
    q1: 0,
    q2: 0
  });

  const Q1CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentCarriageEmptyFieldsCRUD(
      E.shipmentCarriageEmptyFieldsQ1Entity,
      'Primera Quincena',
      {
        fortnight: FortnightNumber.FirstFortnight,
        date: dateFiltered.format('YYYY-MM-DD')
      }
    );
  }, [dateFiltered]);

  const Q2CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ShipmentCarriageEmptyFieldsCRUD(
      E.shipmentCarriageEmptyFieldsQ2Entity,
      'Segunda Quincena',
      {
        fortnight: FortnightNumber.SecondFortnight,
        date: dateFiltered.format('YYYY-MM-DD')
      }
    );
  }, [dateFiltered]);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      throw Error;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      throw Error;
    } else if (!selectedFortnight) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar una Quincena`
      });
      throw Error;
    }

    const res = await massiveLoadShipmentCarriageEmptyFields(data, selectedDate, selectedFortnight);

    if (res) {
      setDateFiltered(selectedDate);
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Campos vacíos ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }

    setGlobalDataQuantity({
      q1: entities.shipmentCarriageEmptyFieldsQ1.list.length,
      q2: entities.shipmentCarriageEmptyFieldsQ2.list.length
    });
  }, [auth, entities]);

  return (
    <div className='shipment-carriage-container'>
      <div className='shipment-carriage-body'>
        <h1 className='shipment-carriage-title'>{title}</h1>
        <div className='shipment-carriage-actions'>
          <GenericModal
            title={'CARGA MASIVA FLETE ACARREO'}
            buttonName={'CARGA MASIVA FLETE ACARREO'}
            buttonIcon={<FileUploadIcon />}
            buttonVariant='contained'
            buttonColor='success'
            openHook={() => setCloseModal(false)}
            closeModal={closeModal}>
            <div className='shipment-carriage-massive-load-filters-modal-container'>
              <GenericInfoTag
                alert={
                  'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
                }
                alertTitle='Importante!'
              />
              <div className='shipment-carriage-massive-load-filters-container'>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
                  <DatePicker
                    label='Mes'
                    views={['year', 'month']}
                    openTo='year'
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    maxDate={moment(today)}
                  />
                </LocalizationProvider>
                <Select
                  id='freight-select'
                  value={selectedFortnight}
                  onChange={(e: SelectChangeEvent) => setSelectedFortnight(e.target.value)}>
                  <MenuItem value={FortnightNumber.FirstFortnight}>
                    {FortnightNames.firstFortnight}
                  </MenuItem>
                  <MenuItem value={FortnightNumber.SecondFortnight}>
                    {FortnightNames.secondFortnight}
                  </MenuItem>
                </Select>
              </div>

              <FileReaderCSV
                columns={SHIPMENT_CARRIAGE_EMPTY_FIELDS_COLUMNS}
                callback={uploadFile}
              />
            </div>
          </GenericModal>
          <DateFilterWithAction
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
            action={shipmentCarriageDateFilter}
          />
        </div>
        <div className='shipment-carriage-crud-container'>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Primera Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q1.toString())
                  }
                ]}
              />
            </div>
            <Q1CRUD />
          </div>
          <div className='shipment-carriage-crud'>
            <div className='shipment-carriage-crud-top-table'>
              <GenericTopTableCGFCCU
                titleTable='Segunda Quincena'
                arrowName={arrowName}
                cardAlerts={[
                  {
                    title: 'Datos Globales',
                    icon: <SignalCellularAltOutlined fontSize='large' />,
                    numberInfo: formatStringNumber(globalDataQuantity.q2.toString())
                  }
                ]}
              />
            </div>
            <Q2CRUD />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShipmentCarriageEmptyFieldsCRUDMaintainerConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {
    massiveLoadShipmentCarriageEmptyFields
  }
)(ShipmentCarriageEmptyFieldsCRUDMaintainer);
