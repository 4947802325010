import { Stack } from '@mui/material';
import { TelephoneExpenses } from '../types';
import { formatStringNumber } from '../utils/utils';
import { ExtendedColumnDef, WebEntity } from './types';
import { CSSProperties } from 'react';
import { FileReaderColumnProps } from '../components/FileReader';

const rightAlignStyle: CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
};

const TelephoneExpensesEntity: WebEntity<TelephoneExpenses> = {
  name: 'telephoneExpenses',
  endpoint: 'telephoneExpenses',
  referenceColumn: 'category',
  tableColumns: []
};

export default TelephoneExpensesEntity;

export const columns: Array<ExtendedColumnDef<TelephoneExpenses> & { groupingColumn: boolean }> = [
  {
    header: 'ZONA',
    accessorKey: 'zoneDescrp',
    columnType: 'text',
    size: 200,
    groupingColumn: true
  },
  {
    header: 'Cuenta',
    accessorKey: 'phoneAccountName',
    columnType: 'text',
    size: 200,
    groupingColumn: true
  },
  {
    header: 'FAMILIA PLAN',
    accessorKey: 'phoneFamilyPlanName',
    columnType: 'text',
    size: 190,
    groupingColumn: true
  },
  {
    header: 'Plan',
    accessorKey: 'phonePlanName',
    columnType: 'text',
    size: 190,
    groupingColumn: true
  },
  {
    header: 'NEW CECO',
    accessorKey: 'costCenterSap',
    columnType: 'text',
    size: 190,
    groupingColumn: true
  },
  {
    header: 'Usuario',
    accessorKey: 'staffFullName',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'RUT',
    accessorKey: 'nationalId',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Movil',
    accessorKey: 'phoneNumber',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Fecha Baja CCU',
    accessorKey: 'dateCancellationCcu',
    columnType: 'dateUTC',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Minutos',
    accessorKey: 'minutes',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'GB',
    accessorKey: 'gigabytes',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Trafico',
    accessorKey: 'phoneTrafficName',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'N. Factura',
    accessorKey: 'invoiceNumber',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'UEN RUT',
    accessorKey: 'uenRut',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Misma UEN',
    accessorKey: 'uenSame',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Equipo',
    accessorKey: 'equipment',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'IMEI',
    accessorKey: 'imei',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: '1er VCTO',
    accessorKey: 'firstVcto',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Cuotas_Pend',
    accessorKey: 'outstandingDues',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Equipo',
    accessorKey: 'phoneEquipmentName',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Accion 1',
    accessorKey: 'phoneActionOne',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Accion 2',
    accessorKey: 'phoneActionTwo',
    columnType: 'text',
    size: 250,
    groupingColumn: true
  },
  {
    header: 'Cantidad',
    accessorKey: 'quantity',
    size: 150,
    columnType: 'numeric',
    groupingColumn: false,
    Cell: ({ row }) => (
      <div style={rightAlignStyle}>{formatStringNumber(row?.original?.quantity)}</div>
    ),
    aggregationFn: 'sum',
    AggregatedCell: ({ cell }) => {
      const sum = cell.getValue() as number;
      return (
        <div style={{ ...rightAlignStyle, fontWeight: 'bold' }}>
          {sum ? formatStringNumber(sum.toString()) : ''}
        </div>
      );
    },
    Footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows.map((r) => r.original);
      const totalFooter = rows.reduce((acc, obj) => acc + Number(obj.quantity), 0);
      return (
        <Stack sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
          {formatStringNumber(totalFooter.toString())}
        </Stack>
      );
    }
  },
  {
    header: 'Gasto Mes',
    accessorKey: 'netMonthlyExpense',
    size: 180,
    columnType: 'numeric',
    groupingColumn: false,
    Cell: ({ row }) => (
      <div style={rightAlignStyle}>$ {formatStringNumber(row?.original?.netMonthlyExpense)}</div>
    ),
    aggregationFn: 'sum',
    AggregatedCell: ({ cell }) => {
      const sum: number = cell.getValue() as number;
      return (
        <div style={{ ...rightAlignStyle, fontWeight: 'bold' }}>
          {sum ? `$ ${formatStringNumber(sum.toString())}` : ''}
        </div>
      );
    },
    Footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
      const total = rows.reduce(
        (acc: number, obj: TelephoneExpenses) =>
          obj.netMonthlyExpense ? acc + Math.ceil(obj.netMonthlyExpense) : acc,
        0
      );
      return (
        <Stack sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
          $ {formatStringNumber(total.toString())}
        </Stack>
      );
    }
  }
];

export const TELEPHONE_EXPENSES_COLUMNS: FileReaderColumnProps = {
  phoneAccount: 'Cuenta',
  phoneNumber: { name: 'Movil', export: true, required: false, upload: true },
  phonePlan: 'Plan',
  phoneFamilyPlan: 'FAMILIA PLAN',
  minutes: 'MINUTOS',
  gigabytes: 'GB',
  phoneTraffic: 'TRAFICO',
  netMonthlyExpense: 'GASTO NETO MES',
  invoiceNumber: 'N° Factura',
  dateIssued: 'Emision',
  uenRut: 'UEN RUT',
  uenSame: 'MISMA UEN',
  rut: 'RUT',
  costCenter: { name: 'NEW CECO', export: true, required: false, upload: true },
  dateCancellationCcu: { name: 'FECHA BAJA CCU', export: true, required: false, upload: true },
  equipment: { name: 'EQUIPO', export: true, required: false, upload: true },
  imei: { name: 'IMEI', export: true, required: false, upload: true },
  firstVcto: { name: '1er VCTO', export: true, required: false, upload: true },
  outstandingDues: { name: 'CUOTAS_PEND', export: true, required: false, upload: true },
  netCost: { name: 'COSTO_NETO', export: true, required: false, upload: true },
  withEquipment: 'CON EQUIPO',
  phoneActionOne: 'ACCION 1',
  phoneActionTwo: 'ACCION 2',
  zone: { name: 'ZONA', export: true, required: false, upload: true }
};
