import { PhoneTraffics } from '../types';
import { WebEntity } from './types';

const PhoneTrafficsEntity: WebEntity<PhoneTraffics> = {
  name: 'phoneTraffics',
  endpoint: 'phoneTraffics',
  referenceColumn: 'phoneTrafficsId',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: false
    },
    {
      header: 'Nombre Tráfico',
      accessorKey: 'phoneTrafficName',
      columnType: 'text'
    }
  ]
};

export default PhoneTrafficsEntity;
