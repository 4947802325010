import { PhoneFamilyPlans } from '../types';
import { FieldTypes, WebEntity } from './types';

const PhoneFamilyPlanEntity: WebEntity<PhoneFamilyPlans> = {
  name: 'phoneFamilyPlans',
  endpoint: 'phoneFamilyPlans',
  referenceColumn: 'phoneFamilyPlanId',
  customButtonNames: {
    addButtonName: 'Agregar Plan Familia'
  },
  customDataActions: {
    editModalTitle: 'Editar Plan Familia'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: false
    },
    {
      header: 'Nombre Plan Familia',
      accessorKey: 'phoneFamilyPlanName',
      columnType: 'text'
    }
  ],
  fields: [
    {
      name: 'Nombre Plan Familia',
      selector: 'phoneFamilyPlanName',
      type: FieldTypes.Text,
      required: true
    }
  ],
  editableFields: [
    {
      name: 'Nombre Plan Familia',
      selector: 'phoneFamilyPlanName',
      type: FieldTypes.Text
    }
  ]
};

export default PhoneFamilyPlanEntity;
