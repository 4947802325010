import { PhoneActions } from '../types';
import { FieldTypes, WebEntity } from './types';

const PhoneActionEntity: WebEntity<PhoneActions> = {
  name: 'phoneActions',
  endpoint: 'phoneActions',
  referenceColumn: 'phoneActionId',
  customButtonNames: {
    addButtonName: 'Agregar Acción'
  },
  customDataActions: {
    editModalTitle: 'Editar Acción'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: false
    },
    {
      header: 'Nombre Acción',
      accessorKey: 'phoneActionName',
      columnType: 'text'
    }
  ],
  fields: [
    {
      name: 'Nombre Acción',
      selector: 'phoneActionName',
      type: FieldTypes.Text,
      required: true
    }
  ],
  editableFields: [
    {
      name: 'Nombre Acción',
      selector: 'phoneActionName',
      type: FieldTypes.Text
    }
  ]
};

export default PhoneActionEntity;
