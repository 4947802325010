import { PhonePlans } from '../types';
import { FieldTypes, WebEntity } from './types';

const PhonePlanEntity: WebEntity<PhonePlans> = {
  name: 'phonePlans',
  endpoint: 'phonePlans',
  referenceColumn: 'phonePlanId',
  customButtonNames: {
    addButtonName: 'Agregar Plan'
  },
  customDataActions: {
    editModalTitle: 'Editar Plan'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: false
    },
    {
      header: 'Nombre Plan',
      accessorKey: 'phonePlanName',
      columnType: 'text'
    }
  ],
  fields: [
    {
      name: 'Nombre Plan',
      selector: 'phonePlanName',
      type: FieldTypes.Text,
      required: true
    }
  ],
  editableFields: [
    {
      name: 'Nombre Plan',
      selector: 'phonePlanName',
      type: FieldTypes.Text
    }
  ]
};

export default PhonePlanEntity;
