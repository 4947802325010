import LandingPage from './LandingPage';
import NotFoundPage from './NotFoundPage';
import AdminPanelPage from './AdminPanelPage';
import WelcomePage from './WelcomePage';
import GlobalZoneMapPage from './GlobalZoneMapPage';
import OperationsPage from './OperationsPage';
import ModulesPage from './ModulesPage';
import CenterZoneMapPage from './CenterZoneMapPage';
import FreightPage from './FreightPage';
import FareDifferencePage from './FareDifferencePage';
import HaulingPage from './HaulingPage';
import { IfacPageConnected } from './IfacPage';
import { MajorPortingPageConnected } from './MajorPortingPage';
import ForeignExchangePage from './ForeignExchangePage';
import ShipmentCarriagePage from './ShipmentCarriagePage';
import CarriageResumePage from './CarriageResumePage';
import ReporterPage from './ReporterPage';
import { ReporteGastos } from './ReporteGastos';
import { ReporteVolumen } from './ReporteVolumen';
import GeneralExpense from './GeneralExpense';
import { ExpenseReport } from './ExpenseReport';
import TelephoneExpensesPage from './TelephoneExpensesPage';
import {
  UsuarioCRUDMaintainer,
  MapCRUDMaintainer,
  NotificationMaintainer,
  CostCenterCRUDMaintainer,
  ZoneCRUDMaintainer,
  SDBudgetCRUDMaintainer,
  SDBudgetTypeCRUDMaintainer,
  CategoriasCRUDMaintainer,
  DictionaryCcCRUDMaintainer,
  AreaManagerCRUDMaintainer,
  ZoneGlobalCRUDMaintainer,
  SubCostCentersCRUDMaintainer,
  ExpensesCRUDMaintainerConnected,
  EstimatedClosingCRUDMaintainer,
  EstimatedClosingPendingMaintainer,
  EstimatedClosingRejectedMaintainer,
  EstimatedGlobalClosingMaintainer,
  GeneralZoneReportMaintainer,
  GeneralReportMaintainer,
  ProvisionCRUDMaintainer,
  PendingProvisionCRUDMaintainer,
  RefusedProvisionMaintainer,
  ApprovedProvisionMaintainer,
  ReclassificationCRUDMaintainer,
  PendingReclassificationCRUDMaintainer,
  RefusedReclassificationCRUDMaintainer,
  ApprovedReclassificationCRUDMaintainer,
  HFMCRUDMaintainerConnected,
  CarrierCRUDMaintainer,
  CarrierTypeCRUDMaintainer,
  GlossCRUDMaintainerConnected,
  ZonePortingCRUDMaintainer,
  ConceptCRUDMaintainer,
  SubConceptCRUDMaintainer,
  TariffCRUDMaintainerConnected,
  VolumenCRUDMaintainerConnected,
  PorteoBudgetTypeCRUDMaintainer,
  PorteoBudgetCRUDMaintainer,
  FreightChargesCRUDMaintainerConnected,
  EquivalentBoxCRUDMaintainerConnected,
  TimeRouteCRUDMaintainer,
  IfacCRUDMaintainer,
  ForeignExchangeCRUDMaintainer,
  MajorPortingCRUDMaintainer,
  ForeignExchangeEstimatedCRUDMaintainerConnected,
  ForeignExchangeResumeCRUDMaintainer,
  PermanentTruckCRUDMaintainerConnected,
  CarrierZoneGlossCRUDMaintainer,
  ContractorTariffCRUDMaintainerConnected,
  FreightExtraCRUDMaintainerConnected,
  CarrierRouteCarriageCRUDMaintainer,
  IfacSimulatedCRUDMaintainerConnected,
  FreightTimeMasterCRUDMaintainerConnected,
  ExtraCarriageCRUDMaintainerConnected,
  ProvisionCarriageCRUDMaintainerConnected,
  BudgetCarriageCRUDMaintainerConnected
} from '../pages/Maintainers';

import {
  ContingencyWithHoldingReportConnected,
  FareDifferenceReportConnected,
  FareDifferenceSummaryReport,
  EstimatedClosingPortingReport,
  ReportUENCRUDComponentConnected,
  PolynomialCRUDComponentConnected
} from '../pages/Reports';

import {
  ContingencyWithholdingInconsistenciesReport,
  FreightExtraInconsistenciesReport
} from './Inconsistencies';

const Pages = {
  LandingPage,
  NotFoundPage,
  AdminPanelPage,
  WelcomePage,
  ModulesPage,
  GlobalZoneMapPage,
  OperationsPage,
  CenterZoneMapPage,
  FreightPage,
  FareDifferencePage,
  HaulingPage,
  IfacPageConnected,
  MajorPortingPageConnected,
  ForeignExchangePage,
  ShipmentCarriagePage,
  CarriageResumePage,
  ReporterPage,
  GeneralExpense,
  ExpenseReport,
  TelephoneExpensesPage
};

export const Maintainers = {
  UsuarioCRUDMaintainer,
  MapCRUDMaintainer,
  NotificationMaintainer,
  CostCenterCRUDMaintainer,
  ZoneCRUDMaintainer,
  SDBudgetCRUDMaintainer,
  SDBudgetTypeCRUDMaintainer,
  CategoriasCRUDMaintainer,
  DictionaryCcCRUDMaintainer,
  AreaManagerCRUDMaintainer,
  ZoneGlobalCRUDMaintainer,
  SubCostCentersCRUDMaintainer,
  ExpensesCRUDMaintainerConnected,
  EstimatedClosingCRUDMaintainer,
  EstimatedClosingPendingMaintainer,
  EstimatedClosingRejectedMaintainer,
  EstimatedGlobalClosingMaintainer,
  GeneralZoneReportMaintainer,
  GeneralReportMaintainer,
  ProvisionCRUDMaintainer,
  PendingProvisionCRUDMaintainer,
  RefusedProvisionMaintainer,
  ApprovedProvisionMaintainer,
  ReclassificationCRUDMaintainer,
  PendingReclassificationCRUDMaintainer,
  RefusedReclassificationCRUDMaintainer,
  ApprovedReclassificationCRUDMaintainer,
  HFMCRUDMaintainerConnected,
  CarrierCRUDMaintainer,
  CarrierTypeCRUDMaintainer,
  GlossCRUDMaintainerConnected,
  ZonePortingCRUDMaintainer,
  ConceptCRUDMaintainer,
  SubConceptCRUDMaintainer,
  TariffCRUDMaintainerConnected,
  VolumenCRUDMaintainerConnected,
  PorteoBudgetTypeCRUDMaintainer,
  PorteoBudgetCRUDMaintainer,
  FreightChargesCRUDMaintainerConnected,
  EquivalentBoxCRUDMaintainerConnected,
  TimeRouteCRUDMaintainer,
  IfacCRUDMaintainer,
  ForeignExchangeCRUDMaintainer,
  MajorPortingCRUDMaintainer,
  ForeignExchangeEstimatedCRUDMaintainerConnected,
  ForeignExchangeResumeCRUDMaintainer,
  PermanentTruckCRUDMaintainerConnected,
  CarrierZoneGlossCRUDMaintainer,
  ContractorTariffCRUDMaintainerConnected,
  FreightExtraCRUDMaintainerConnected,
  CarrierRouteCarriageCRUDMaintainer,
  IfacSimulatedCRUDMaintainerConnected,
  FreightTimeMasterCRUDMaintainerConnected,
  ExtraCarriageCRUDMaintainerConnected,
  ProvisionCarriageCRUDMaintainerConnected,
  BudgetCarriageCRUDMaintainerConnected
};

export const Reports = {
  ContingencyWithHoldingReportConnected,
  FareDifferenceReportConnected,
  FareDifferenceSummaryReport,
  EstimatedClosingPortingReport,
  ReportUENCRUDComponentConnected,
  PolynomialCRUDComponentConnected,
  ReporteGastos,
  ReporteVolumen
};

export const Inconsistencies = {
  ContingencyWithholdingInconsistenciesReport,
  FreightExtraInconsistenciesReport
};
export default Pages;
