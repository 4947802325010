import { WebEntityName } from './types';
import UserTypeEntity from './UserTypeEntity';
import NotificationEntity from './NotificationEntity';
import NotificationTypeEntity from './NotificationTypeEntity';
import UserEntity from './UserEntity';
import MapEntity from './MapEntity';
import CostCenterEntity from './CostCenterEntity';
import ZoneEntity from './Zone';
import BudgetEntity from './BudgetEntity';
import BudgetTypeEntity from './BudgetType';
import CategoriesEntity from './CategoriesEntity';
import DictionaryCcEntity from './DictionaryCc';
import AreaManagerEntity from './AreaManager';
import ZoneGlobalEntity from './ZoneGlobal';
import SubCostCenterEntity from './SubCostCenterEntity';
import ExpensesEntity from './ExpensesEntity';
import EstimatedClosingEntity from './EstimatedClosingEntity';
import EstimatedClosingPendingEntity from './EstimatedClosingPendingEntity';
import EstimatedClosingRejectedEntity from './EstimatedClosingRejectedEntity';
import EstimatedGlobalClosingEntity from './EstimatedGlobalClosingEntity';
import GeneralZoneReportEntity from './GeneralZoneReportEntity';
import ProvisionEntity from './ProvisionEntity';
import PendingProvisionEntity from './PendingProvisionEntity';
import RefusedProvisionEntity from './RefusedProvisionEntity';
import ApprovedProvisionEntity from './ApprovedProvisionEntity';
import ReclassificationEntity from './ReclassificationEntity';
import PendingReclassificationEntity from './PendingReclassificationEntity';
import RefusedReclassificationEntity from './RefusedReclassificationEntity';
import ApprovedReclassificationEntity from './ApprovedReclassificationEntity';
import HFMEntity from './hfm';
import CarrierEntity from './Carrier';
import ManagementTypeEntity from './ManagementTypeEntity';
import CarrierTypeEntity from './CarrierType';
import GlossEntity from './glossEntity';
import ZonePortingEntity from './zonePortingEntity';
import ConceptEntity from './ConceptEntity';
import SubConceptEntity from './SubConcept';
import TariffEntity from './TariffEntity';
import VolumenEntity from './VolumenEntity';
import PresupuestoVolumenEntity from './PresupuestoVolumenEntity';
import FreightChargesEntity from './FreightChargesEntity';
import EquivalentBoxEntity from './EquivalentBoxEntity';
import ContingencyWithholdingEntity from './ContingencyWithholdingEntity';
import ContingencyWithholdingInconsistenciesEntity from './ContingencyWithholdingInconsistenciesEntity';
import FareDifferenceEntity from './FareDifferenceEntity';
import FareDifferenceSummaryEntity from './FareDifferenceSummaryEntity';
import EstimatedClosingPortingEntity from './EstimatedClosingPortingEntity';
import TimeRouteEntity from './TimeRouteEntity';
import IfacEntity from './IfacEntity';
import ForeignExchangeEntity from './ForeignExchangeEntity';
import MajorPortingEntity from './MajorPortingEntity';
import ForeignExchangeEstimatedEntity from './ForeignExchangeEstimatedEntity';
import ForeignExchangeResumeEntity from './ForeignExchangeResumeEntity';
import PermanentTruckEntity from './PermanentTruckEntity';
import CarrierZoneGlossEntity from './CarrierZoneGlossEntity';
import FreightGlossEntity from './FreightGlossEntity';
import ContractorTariffEntity from './ContractorTariffEntity';
import FreightExtraEntity from './FreightExtraEntity';
import FreightExtraResumeEntity from './FreightExtraResumeEntity';
import CarrierRouteCarriageEntity from './CarrierRouteCarriageEntity';
import CarrierTypeCarriageEntity from './CarrierTypeCarriageEntity';
import TariffCarriageEntity from './TariffCarriageEntity';
import FreightProvisionEntity from './FreightProvisionEntity';
import FreightExtraGlSapEntity from './FreightExtraGlSapEntity';
import FreightExtraInconsistenciesEntity from './FreightExtraInconsistenciesEntity';
import IfacSimulatedEntity from './IfacSimulatedEntity';
import FreightTimeMasterEntity from './FreightTimeMasterEntity';
import ExtraCarriageEntity from './ExtraCarriageEntity';
import ReportUENEntity from './ReportUENEntity';
import ShipmentCarriageEntity from './ShipmentCarriageEntity';
import ShipmentCarriageEmptyFieldsEntity from './ShipmentCarriageEmptyFieldsEntity';
import ShipmentCarriagePXQEntity from './ShipmentCarriagePXQEntity';
import ShipmentAuditingCarriageEntity from './ShipmentAuditingCarriageEntity';
import ShipmenZT08CarriageEntity from './ShipmentZT08CarriageEntity';
import ShipmentCarriageFrom4UsersEntity from './ShipmentCarriageFrom4UsersEntity';
import ResumeShipmentCarriageEntity from './ResumeShipmentCarriageEntity';
import PolynomialEntity from './PolynomialEntity';
import ProvisionCarriageEntity from './ProvisionCarriageEntity';
import BudgetCarriageEntity from './BudgetCarriageEntity';
import CarriageResumeEntity from './CarriageResumeEntity';
import ReporteGastosEntity from './ReporteGastosEntity';
import ReportePieChartEntity from './ReportePieChartEntity';
import ZoneGlobalBudgetVolumeEntity from './ZoneGlobalBudgetVolumeEntity';
import ZoneBudgetVolumeEntity from './ZoneBudgetVolumeEntity';
import DistributionCenterBudgetVolumeEntity from './DistributionCenterBudgetVolumeEntity';
import GroupBudgetVolumeEntity from './GroupBudgetVolumeEntity';
import ReporteVolumenEntity from './ReporteVolumenEntity';
import ExpenseReportEntity from './ExpenseReportEntity';
import TelephoneExpensesEntity from './TelephoneExpensesEntity';
import PhoneAccountEntity from './PhoneAccountEntity';
import PhonePlanEntity from './PhonePlanEntity';
import PhoneFamilyPlanEntity from './PhoneFamilyPlanEntity';
import PhoneActionEntity from './PhoneActionEntity';
import PhoneUENEntity from './PhoneUENEntity';
import PhoneTrafficsEntity from './PhoneTrafficEntity';
import PhoneEquipmentEntity from './PhoneEquipmentEntity';

const FareDifferenceEntrepreneursEntity = {
  ...FareDifferenceEntity,
  name: 'fareDifferenceEntrepreneurs' as WebEntityName,
  endpoint: 'fareDifference/entrepreneurs'
};

const GeneralReportEntity = {
  ...GeneralZoneReportEntity,
  name: 'generalReport' as WebEntityName,
  endpoint: 'expenses/generalReport'
};

const FreightChargesQ1Entity = {
  ...FreightChargesEntity,
  name: 'freightChargesQ1' as WebEntityName
};

const FreightChargesQ2Entity = {
  ...FreightChargesEntity,
  name: 'freightChargesQ2' as WebEntityName
};

const PermanentTruckQ1Entity = {
  ...PermanentTruckEntity,
  name: 'permanentTruckQ1' as WebEntityName
};

const PermanentTruckQ2Entity = {
  ...PermanentTruckEntity,
  name: 'permanentTruckQ2' as WebEntityName
};

const ShipmentCarriageQ1Entity = {
  ...ShipmentCarriageEntity,
  name: 'shipmentCarriageQ1' as WebEntityName
};

const ShipmentCarriageQ2Entity = {
  ...ShipmentCarriageEntity,
  name: 'shipmentCarriageQ2' as WebEntityName
};

const ShipmentAuditingCarriageQ1Entity = {
  ...ShipmentAuditingCarriageEntity,
  name: 'shipmentAuditingCarriageQ1' as WebEntityName
};

const ShipmentAuditingCarriageQ2Entity = {
  ...ShipmentAuditingCarriageEntity,
  name: 'shipmentAuditingCarriageQ2' as WebEntityName
};

const ShipmentCarriagePXQQ1Entity = {
  ...ShipmentCarriagePXQEntity,
  name: 'shipmentCarriagePXQQ1' as WebEntityName
};

const ShipmentCarriagePXQQ2Entity = {
  ...ShipmentCarriagePXQEntity,
  name: 'shipmentCarriagePXQQ2' as WebEntityName
};

const ResumeShipmentCarriageQ1Entity = {
  ...ResumeShipmentCarriageEntity,
  name: 'resumeShipmentCarriageQ1' as WebEntityName
};

const ResumeShipmentCarriageQ2Entity = {
  ...ResumeShipmentCarriageEntity,
  name: 'resumeShipmentCarriageQ2' as WebEntityName
};

const ShipmentCarriageFrom4UsersQ1Entity = {
  ...ShipmentCarriageFrom4UsersEntity,
  name: 'shipmentCarriageFrom4UsersQ1' as WebEntityName
};

const ShipmentCarriageFrom4UsersQ2Entity = {
  ...ShipmentCarriageFrom4UsersEntity,
  name: 'shipmentCarriageFrom4UsersQ2' as WebEntityName
};

const ShipmentZT08CarriageQ1Entity = {
  ...ShipmenZT08CarriageEntity,
  name: 'shipmentZT08CarriageQ1' as WebEntityName
};

const ShipmentZT08CarriageQ2Entity = {
  ...ShipmenZT08CarriageEntity,
  name: 'shipmentZT08CarriageQ2' as WebEntityName
};

const shipmentCarriageEmptyFieldsQ1Entity = {
  ...ShipmentCarriageEmptyFieldsEntity,
  name: 'shipmentCarriageEmptyFieldsQ1' as WebEntityName
};

const shipmentCarriageEmptyFieldsQ2Entity = {
  ...ShipmentCarriageEmptyFieldsEntity,
  name: 'shipmentCarriageEmptyFieldsQ2' as WebEntityName
};

const BudgetCarriageIngresoAcarreoEntity = {
  ...BudgetCarriageEntity,
  name: 'budgetCarriageIngresoAcarreo' as WebEntityName
};

const BudgetCarriageGastoReproEntity = {
  ...BudgetCarriageEntity,
  name: 'budgetCarriageGastoRepro' as WebEntityName
};

const BudgetCarriageGastoAcarreoEntity = {
  ...BudgetCarriageEntity,
  name: 'budgetCarriageGastoAcarreo' as WebEntityName
};

const BudgetCarriageVolumenReproEntity = {
  ...BudgetCarriageEntity,
  name: 'budgetCarriageVolumenRepro' as WebEntityName
};

const GastosPieChart1Entity = {
  ...ReportePieChartEntity,
  name: 'gastosPieChart1' as WebEntityName,
  endpoint: 'reportes/gastos/pieChart'
};

const GastosPieChart2Entity = {
  ...ReportePieChartEntity,
  name: 'gastosPieChart2' as WebEntityName,
  endpoint: 'reportes/gastos/pieChart'
};

const GastosPieCharComparisonEntity = {
  ...ReportePieChartEntity,
  name: 'gastosPieChartComparison' as WebEntityName,
  endpoint: ''
};

const VolumenPieChart1Entity = {
  ...ReportePieChartEntity,
  name: 'volumenPieChart1' as WebEntityName,
  endpoint: 'reportes/volumen/pieChart'
};

const VolumenPieChart2Entity = {
  ...ReportePieChartEntity,
  name: 'volumenPieChart2' as WebEntityName,
  endpoint: 'reportes/volumen/pieChart'
};

const VolumenPieCharComparisonEntity = {
  ...ReportePieChartEntity,
  name: 'volumenPieChartComparison' as WebEntityName,
  endpoint: ''
};

export {
  UserEntity,
  UserTypeEntity,
  NotificationEntity,
  NotificationTypeEntity,
  MapEntity,
  CostCenterEntity,
  ZoneEntity,
  BudgetEntity,
  BudgetTypeEntity,
  CategoriesEntity,
  DictionaryCcEntity,
  AreaManagerEntity,
  ZoneGlobalEntity,
  SubCostCenterEntity,
  ExpensesEntity,
  EstimatedClosingEntity,
  EstimatedClosingPendingEntity,
  EstimatedClosingRejectedEntity,
  EstimatedGlobalClosingEntity,
  GeneralZoneReportEntity,
  GeneralReportEntity,
  ProvisionEntity,
  PendingProvisionEntity,
  RefusedProvisionEntity,
  ApprovedProvisionEntity,
  ReclassificationEntity,
  PendingReclassificationEntity,
  RefusedReclassificationEntity,
  ApprovedReclassificationEntity,
  HFMEntity,
  CarrierEntity,
  ManagementTypeEntity,
  CarrierTypeEntity,
  GlossEntity,
  ZonePortingEntity,
  ConceptEntity,
  SubConceptEntity,
  TariffEntity,
  VolumenEntity,
  PresupuestoVolumenEntity,
  FreightChargesQ1Entity,
  FreightChargesQ2Entity,
  EquivalentBoxEntity,
  ContingencyWithholdingEntity,
  ContingencyWithholdingInconsistenciesEntity,
  FareDifferenceEntity,
  FareDifferenceEntrepreneursEntity,
  FareDifferenceSummaryEntity,
  EstimatedClosingPortingEntity,
  TimeRouteEntity,
  IfacEntity,
  ForeignExchangeEntity,
  MajorPortingEntity,
  ForeignExchangeEstimatedEntity,
  ForeignExchangeResumeEntity,
  PermanentTruckQ1Entity,
  PermanentTruckQ2Entity,
  CarrierZoneGlossEntity,
  FreightGlossEntity,
  ContractorTariffEntity,
  FreightExtraEntity,
  FreightExtraResumeEntity,
  CarrierRouteCarriageEntity,
  CarrierTypeCarriageEntity,
  TariffCarriageEntity,
  FreightProvisionEntity,
  FreightExtraGlSapEntity,
  FreightExtraInconsistenciesEntity,
  IfacSimulatedEntity,
  FreightTimeMasterEntity,
  ExtraCarriageEntity,
  ReportUENEntity,
  ShipmentCarriageQ1Entity,
  ShipmentCarriageQ2Entity,
  ShipmentAuditingCarriageQ1Entity,
  ShipmentAuditingCarriageQ2Entity,
  ShipmentCarriagePXQQ1Entity,
  ShipmentCarriagePXQQ2Entity,
  ShipmentZT08CarriageQ1Entity,
  ShipmentZT08CarriageQ2Entity,
  ShipmentCarriageFrom4UsersQ1Entity,
  ShipmentCarriageFrom4UsersQ2Entity,
  ResumeShipmentCarriageQ1Entity,
  ResumeShipmentCarriageQ2Entity,
  shipmentCarriageEmptyFieldsQ1Entity,
  shipmentCarriageEmptyFieldsQ2Entity,
  PolynomialEntity,
  ProvisionCarriageEntity,
  BudgetCarriageIngresoAcarreoEntity,
  BudgetCarriageGastoReproEntity,
  BudgetCarriageGastoAcarreoEntity,
  BudgetCarriageVolumenReproEntity,
  CarriageResumeEntity,
  ReporteGastosEntity,
  GastosPieChart1Entity,
  GastosPieChart2Entity,
  GastosPieCharComparisonEntity,
  ZoneGlobalBudgetVolumeEntity,
  ZoneBudgetVolumeEntity,
  DistributionCenterBudgetVolumeEntity,
  GroupBudgetVolumeEntity,
  VolumenPieChart1Entity,
  VolumenPieChart2Entity,
  VolumenPieCharComparisonEntity,
  ReporteVolumenEntity,
  ExpenseReportEntity,
  TelephoneExpensesEntity,
  PhoneAccountEntity,
  PhonePlanEntity,
  PhoneFamilyPlanEntity,
  PhoneActionEntity,
  PhoneUENEntity,
  PhoneTrafficsEntity,
  PhoneEquipmentEntity
};
